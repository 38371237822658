.block--product-carousel {

    .block__container {
        display: grid;
        grid-template-areas:
            "heading heading controls"
            "carousel carousel carousel";
        grid-template-columns: 40% 40% 20%;

        @include breakpoint(phablet) {
            grid-template-areas:
                "heading"
                "carousel";
                grid-template-columns: 100%;
        }
    }

    .block__carousel {
        grid-area: carousel;

        @include breakpoint(phablet) {
            display: flex;
            gap: 20px;
            overflow-x: auto;
            scroll-snap-type: x proximity;
            margin: 0 calc($site-gutter-mobile * -1);
            padding: 0 $site-gutter-mobile;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            &::-webkit-scrollbar {
                display: none;
            }

            & > * {
                flex-basis: 240px;
                flex-shrink: 0;
            }
        }
    }

    .block__title {
        @include font(40px, 48px);
        text-align: left;
        grid-area: heading;
        margin-top: 0;
        margin-bottom: 15px;

        @include breakpoint(tablet) {
            @include font(25px, 30px);
        }
    }

    .block__controls {
        grid-area: controls;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        place-self: end;
        margin-bottom: 25px;

        @include breakpoint(phablet) {
            display: none;
        }
    }

    .slick-arrow {
        position: static;
    }

    .product-listing__content__bottom {
        padding-right: 10px;
    }

        .product-listing__title {
            @include font(16px, 16px);
            font-weight: 500;
            color: $c-brand-black;

            @include breakpoint(tablet) {
                @include font(14px, 22px);
            }
        }

        .product-listing__price {
            margin: 0;
            @include font(14px, 14px);
            font-weight: 400;
            color: $c-brand-black;
        }

        .product-listing__colourways {
            display: none;
        }
}

.block--with-tall-padding.block--product-carousel {
    @include breakpoint(tablet) {
        padding-bottom: 25px;
    }
}
