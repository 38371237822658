@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes reveal {
    0%   {
        opacity: 1;
    }
    50%  {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
}
