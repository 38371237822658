.filterable-toggle {
    display: none;

    @include breakpoint(phablet) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        color: $c-dark-grey;
        position: relative;
        cursor: pointer;

        .filterable--active & {
            flex-direction: row-reverse;
        }
    }
}

    .filterable-toggle__text {
        @include font(14px, 26px);

        display: block;
        text-align: left;
        font-weight: 500;
        text-transform: capitalize;

        .filterable--active & {
            @include font(16px, 26px);
    
            text-transform: uppercase;
            letter-spacing: 3px;
            padding: 15px 0;
        }
    }

    .filterable-toggle__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-close {
            display: none;
            fill: $c-dark-grey;

            .filterable--active & {
                display: block;
            }
        }
        
        .icon-filters {
            fill: $c-brand;
            margin-top: 2px;
            margin-right: 10px;

            .filterable--active & {
                display: none;
            }
        }
    }

    .filterable-toggle__count {
        &::before {
            content: '(';
        }

        &::after {
            content: ')';
        }

        .filterable--active & {
            display: none;
        } 
    }
