.quarter-grid {}

    .quarter-grid__title {
        @include apply-map($f-title);
        @include font(40px, 40px);

        margin-bottom: 30px;
        text-align: center;

        @include breakpoint(tablet) {
            @include font(25px, 25px);

            text-align: left;
            margin-bottom: 20px;
        }
    }

    .quarter-grid__grid {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 18px;

        @include breakpoint(tablet) {
            display: flex;
            overflow-x: scroll;
            gap: 10px;
            scrollbar-width: none;
            margin-right: calc(((100vw - $tab-max-width) / -2) - $site-gutter-mobile);
            margin-left: calc(((100vw - $tab-max-width) / -2) - $site-gutter-mobile);
            margin-bottom: 15px;

            & > * {
                flex: 0 0 40%;
                scroll-snap-align: start; 
            }

            li:first-child {
                margin-left: calc(((100vw - $tab-max-width) / 2) + $site-gutter-mobile);
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @include breakpoint(phablet) {
            margin-right: -$site-gutter-mobile;
            margin-left: -$site-gutter-mobile;

            & > * {
                flex: 0 0 80%;
            }

            li:first-child {
                margin-left: $site-gutter-mobile;
            }
        }
    }
