.inline-intro {}

.inline-intro--indent {}

    .inline-intro__title {
        @include large-title;

        @include breakpoint(phablet) {

            @include rem(40px);

            line-height: calc(48 / 40);
        }

        .inline-intro--indent & {
            margin-left: 100px;

            @include breakpoint(tablet) {
                margin-left: 0;
            }
        }
    }

    .inline-intro__intro {
        @include introcopy;
    }
