.orders-form {
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(tablet) {
        display: block;
        text-align: center;
    }
}

.orders-form__label {
    @include apply-map($f-title);
    @include rem(20px);
    line-height: calc(24 / 20);
}

.orders-form__field-wrapper {
    margin: 0 0 20px 0;

    input {
        max-width:320px;
    }

    @include breakpoint(tablet) {
        width: 100%;
        max-width: 320px;
        margin: 17px auto 0;
    }
}

.orders-form__button {
    @include rem(18px);

    @include breakpoint(tablet) {
        width: 100%;
        max-width: 320px;
    }
}


.invoice__grid {

    &.grid {
        margin-right: -15px;

        & > * {
            padding-right: 15px;
            margin-bottom: 15px;
        }
    }
}
