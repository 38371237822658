.block.block--how-it-works {
    .block__steps {
        counter-reset: steps;
        margin: 20px 0 50px;
    
        @include breakpoint(laptop) {
            padding: 20px;
        }
    
        @include breakpoint(phablet) {
            padding: 0;
            border: none;
        }
    }
    
        .block__steps__title {
            @include apply-map($f-title);
            @include font(36px, 43px);
    
            text-align: center;
            color: $c-dark;
            margin-bottom: 30px;
        }
    
        .block__steps__content {
            display: flex;
            justify-content: space-between;
    
            @include breakpoint(tablet) {
                flex-direction: column;
            }
    
            .slick-dots {
                position: static;
                right: auto;
                bottom: auto;
                margin-top: 30px;
                justify-content: center;
                font-size: 0;
    
                li {
                    display: inline-block;
                    vertical-align: top;
                    margin: 0 0 0 6px;
    
                    &:first-child {
                        margin-left: 0;
                    }
                }
    
                .slick-active button {
                    background: none;
                }
    
                button {
                    width: 10px;
                    height: 10px;
                    background: $c-black;
                    border: 1px solid $c-black;
                    border-radius: 100%;
                    transition: opacity .4s ease;
    
                    &:after {
                        display: none;
                    }
                }
            }
        }
    
        .block__step {
            position: relative;
            display: flex;
            flex: 1 0;
            flex-direction: column;
            align-items: center;
    
            @include breakpoint(tablet) {
                margin-bottom: 20px;
            }
    
            &:not(:last-child)::after {
                content: '';
                position: absolute;
                top: 35px;
                left: calc(50% + 35px);
                height: 1px;
                background: $c-filter-grey;
                width: 100%;
    
                @include breakpoint(tablet) {
                    display: none;
                }
            }
        }
    
            .block__step__image {
                position: relative;
                margin-bottom: 30px;
                z-index: 5;
                width: 70px;
                height: 70px;
    
                @include breakpoint(tablet) {
                    margin-bottom: 15px;
                }
    
                &::after {
                    @include apply-map($f-title);
                    @include font(28px, 34px);
    
                    color: $c-white;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    counter-increment: steps;
                    content: counter(steps);
                }
    
                svg {
                    fill: $c-block-copy;
                }
            }
    
            .block__step__title {
                @include apply-map($f-title);
                @include font(28px, 34px);
    
                color: $c-dark;
                text-align: center;
                margin-bottom: 15px;
            }
    
            .block__step__copy {
                @include apply-map($f-primary);
                @include font(14px, 22px);
    
                padding: 0 10px;
    
                color: $c-block-copy;
                text-align: center;
            }
    
        .block__steps__button {
    
            * + & {
                margin: 40px 0 0;
    
                @include breakpoint(phablet) {
                    display: block;
                    margin: 20px 0 0;
                }
            }
    
            span svg {
                margin-left: -10px;
                transition: fill stroke .5s ease;
                fill: #000;
                &:hover {
                    fill: #fff;
                }
            }
        }
    
        .block__steps__footnote {
            @include rem(14px);
    
            line-height: calc(16 / 14);
            color: rgba($c-footnote, 0.6);
    
            * + & {
                margin: 15px 0 0;
    
                @include breakpoint(phablet) {
                    margin: 10px 0 0;
                }
            }
        } 
}
