.page-intro {
    text-align: center;
    @include max-width-content(580px);
}

.page-intro--ph-left {

    @include breakpoint(phablet) {
        text-align: left;
    }
}

.page-intro--large {
    max-width: 938px;
}

.page-intro--padding-top {
    padding-top: 30px;
}

.page-intro--padding-bottom {
    padding-bottom: 30px;
}

    .page-intro__title {
        @include large-title;
    }

    .page-intro__meta {
        @include smallcaps;
        color: $c-label;
        margin: 12px 0 20px;

        span {
            &:before {
                content: " / ";
            }
            &:first-child:before {
                display: none;
            }
        }
    }

    .page-intro__desc {
        margin-top: 20px;
        line-height: 1.7;
        color: $c-body;

        .page-intro--large & {
            margin-top: 50px;
        }

        p {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            .page-intro--large & {
                @include rem(24px);

                line-height: calc(31 / 24);
            }
        }
    }

    .page-intro__cta {
        margin: 40px 0;
    }
