.social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
    flex-wrap: wrap;
    margin-top: 0;

    @include breakpoint(mobile) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0;
        gap: 15px;
    }

    p {
        color: $c-brand;
        @include apply-map($f-primary);
        @include rem(13px);
        font-style: normal;
        line-height: 21px;
        text-transform: uppercase;
        font-weight: 500;
    }

    .social__links {
        display: flex;
        gap: 25px;
        align-items: center;
    }

        a {
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {

                svg {
                    fill: $c-brand-black;
                }
            }
        }

            svg {
                fill: $c-brand;
                transition: fill 0.3s ease;
            }
}
