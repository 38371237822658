@use "sass:math";

.paint-map {
    @include aspect(math.div(1613, 913));

    position: relative;
    background: url(/images/old-map.jpg) center no-repeat;
    background-size: 100%;
}

    .paint-map__link {
        position: absolute;
        width: 35px;
        height: 35px;
        transform: translate(-50%, -50%);

        @include breakpoint(phablet) {
            pointer-events: none;
            cursor: default;
        }

        @include breakpoint(mobile) {
            width: 15px;
            height: 15px;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            border: 1px solid currentColor;
            opacity: 0;
        }

        &:before {
            top: -3px;
            right: -3px;
            bottom: -3px;
            left: -3px;
        }

        &:after {
            top: -6px;
            right: -6px;
            bottom: -6px;
            left: -6px;
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: currentColor;
        }
    }

    .paint-map__link:hover,
    .paint-map__link--active {
        width: 22px;
        height: 22px;
        background: currentColor;
        transform: translate(-50%, -50%) rotate(45deg);
        box-shadow: 3px -1px 4px rgba($c-black, 0.7);

        @include breakpoint(phablet) {
            display: none;
        }

        &:before,
        &:after {
            transition: .3s;
        }

        &:before {
            opacity: 0.8;
        }

        &:after {
            opacity: 0.3;
        }

        svg {
            display: none;
        }
    }

        .paint-map__shadow {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 21px;
            height: 21px;
            transform: translate(-50%, -50%) rotate(45deg);
            box-shadow: 3px -1px 4px rgba($c-black, 0.7);
            z-index: -1;

            .paint-map__link:hover &,
            .paint-map__link--active & {
                display: none;
            }

            @include breakpoint(phablet) {
                display: none;
            }
        }
