@use 'sass:math';

.customise-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 10001;
    background: $c-white;
    width: 1050px;
    transition: scale 200ms ease-in-out;

    @include breakpoint(tablet) {
        transform: none; // no popup on tablet and down
        width: 100%;
        position: relative;
        top: unset;
        left: unset;
        z-index: unset;
        max-width: 720px;
        margin: 0 auto;
    }
}

.customise-modal--active {
    transform: translate(-50%, -50%) scale(1);
}

    .customise-modal__container {
        position: relative;
        margin: 20px;
        padding: 45px;
        border: 1px solid $c-filter-grey;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 40px;
        align-items: center;

        @include breakpoint(tablet) {
            grid-template-columns: minmax(0, 1fr);
            margin: 0;
            padding: 0;
            border: none;
            align-items: flex-start;
            gap: 25px;
        }
    }

        .customise-modal__media {
            @include breakpoint(tablet) {
                order: 1;
            }
        }

            .customise-modal__gallery {
                margin-bottom: 15px;
            }

                .customise-modal__gallery__item {
                    @include swatch-hover;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .slick-current & {
                        @include swatch-active;
                    }
                }

            .customise-modal__thumbnails {
                @include gallery-fade(40px);

                @include breakpoint(tablet) {
                    display: none;
                }
            }

                .carousel-modal__thumbnail-item {
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

        .customise-modal__content {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;

            @include breakpoint(phablet) {
                padding: 0 $site-gutter-mobile;
            }
        }

            .customise-modal__header {
                @include apply-map($f-title);
                @include font(32px, 38px);

                grid-column: 1 / 2;
                color: $c-dark-grey;
                margin-bottom: 5px;

                @include breakpoint(tablet) {
                    @include font(24px, 29px);

                    padding-top: 20px;
                    border-top: 1px solid $c-filter-grey;
                }
            }

            .customise-modal__subtitle {
                @include font(16px, 26px);

                grid-column: 1 / 2;
                color: $c-dark-grey;
                font-weight: 500;
                margin-bottom: 15px;

                @include breakpoint(tablet) {
                    margin-bottom: 10px;
                }
            }

            .customise-modal__swatch {
                @include aspect(math.div(4, 3));
                @include swatch-active;

                grid-column: 2 / 3;
                grid-row: 1 / 3;
                position: relative;
                background-size: cover;
                align-self: flex-start;
                justify-self: flex-end;
                width: 85px;
            }

            .customise-modal__body {
                @include apply-map($f-primary);
                @include font(14px, 22px);

                color: $c-richcopy;
                margin-bottom: 30px;
                grid-column: 1 / 3;

                @include breakpoint(tablet) {
                    margin-bottom: 0;
                }
            }

            .customise-modal__action {
                @include apply-map($f-primary);
                @include font(16px, 26px);

                color: $c-dark;
                font-weight: 500;
                transition: color 200ms ease;
                text-decoration: underline;
                display: block;
                border-top: 1px solid $c-filter-grey;
                padding-top: 20px;
                grid-column: 1 / 3;

                svg {
                    width: 21px;
                    fill: $c-brand;
                    margin-right: 10px;
                }

                &:hover {
                    color: $c-block-copy;
                }
            }

            .customise-modal__action__sample {
                display: flex;
                align-items: center;
            }

            .customise-modal__close {
                position: absolute;
                width: 40px;
                height: 40px;
                top: -55px;
                right: -55px;

                svg {
                    fill: $c-white;
                }
            }

.customise-modal__overlay {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $c-black;
    pointer-events: none;
    z-index: 10000;

    @include breakpoint(tablet) {
        display: none;
    }
}

.customise-modal__overlay--active {
    pointer-events: all;
    opacity: 0.7;
}
