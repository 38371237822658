@use "sass:math";

.collection {}

.collection-category {}

    .collection-category__content {}

        .collection__title {
            @include large-title;
            @include rem(44px);
            margin: 30px 0;
        }

        .collection-category__title {
            @include large-title;
            @include rem(32px);
            margin: 26px 0;
        }

        .collection-category--split .collection-category__title {
            margin: 30px 0 0;
        }

        .collection__intro,
        .collection-category__intro {
            @include introcopy;

            line-height: calc(31 / 24);
            margin: 0 auto;
            color: $c-collections-intro;
            max-width: 700px;


            .collection__title + & {
                margin: 20px auto 0;

                @include breakpoint(phablet) {
                    margin: 10px auto 0;
                }
            }

            .collection-category__title + & {
                margin: 10px auto 0;
            }

            @include breakpoint(phablet) {
                line-height: calc(24 / 19);
            }
        }

        .collection__body,
        .collection-category__body {
            max-width: 700px;
            margin: 0 auto;
            * + & {
                margin: 20px auto 0;

                @include breakpoint(phablet) {
                    margin: 10px auto 0;
                }
            }

            .collection__title + &,
            .collection-category__title + & {
                margin: 10px auto 0;
            }

            * {
                @include bodycopy;

                line-height: calc(22 / 16);
                color: $c-collections-body;
            }
        }

        .collection-category__image {
            @include aspect(1);
        }

        .collection-category__image--wide {
            @include aspect(math.div(1510, 749));

            * + & {
                margin: 70px 0 0;

                @include breakpoint(phablet) {
                    margin: 20px 0 0;
                }
            }

            @include breakpoint(phablet) {

                @include aspect(math.div(320, 213));
            }
        }

    .collection-category__products {
        margin: 30px 30px 0;
    }
