.projects {}

    .projects__title {
        @include rem(53px);

        line-height: calc(64 / 53);

        @include breakpoint(phablet) {
            @include rem(40px);

            line-height: calc(48 / 40);
        }
    }

    .projects__intro {
        @include rem(24px);

        line-height: calc(31 / 24);
        color: $c-projects-intro;

        * + & {
            margin: 20px 0 0;

            @include breakpoint(phablet) {
                margin: 10px 0 0;
            }
        }

        @include breakpoint(phablet) {
            @include rem(19px);

            line-height: calc(24 / 19);
        }
    }

    .projects__body {
        @include rem(16px);

        line-height: calc(22 / 16);
        color: $c-projects-body;

        * + & {
            margin: 20px 0 0;

            @include breakpoint(phablet) {
                margin: 10px 0 0;
            }
        }
    }

.project-preview {
    position: relative;
    display: block;

    .project-preview + & {
        margin: 50px 0 0;

        @include breakpoint(phablet) {
            margin: 0 -10px;
        }
    }

    @include breakpoint(phablet) {
        margin: 0 -10px;
    }
}
    .project-preview__listing-overlay {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        opacity: .85;
        background: linear-gradient(to bottom, rgba($c-black, 0) 70%, $c-black 100%);
    }
    .project-preview__content {
        position: absolute;
        right: 100px;
        bottom: 30px;
        left: 100px;

        @include breakpoint(phablet) {
            right: 20px;
            bottom: 20px;
            left: 20px;
        }
    }

        .project-preview__title {
            color: $c-white;

            @include breakpoint(phablet) {

                @include rem(28px);

                line-height: calc(34 / 28);
            }
        }

        .project-preview__address {
            @include apply-map($f-smallprint);
            @include rem(12px);

            line-height: calc(15 / 12);
            text-transform: uppercase;
            color: rgba($c-white, 0.5);
            letter-spacing: 1px;
            font-weight: 400;

            * + & {
                @include breakpoint(phablet) {
                    margin: 3px 0 0;
                }
            }
        }

.projects-cta {
    padding: 30px 30px 40px;
    border: 1px solid $c-border;

    @include breakpoint(phablet) {
        padding: 30px 10px;
    }
}

    .projects-cta__label {
        @include apply-map($f-smallprint);
        @include rem(12px);

        line-height: calc(15 / 12);
        text-transform: uppercase;
        color: rgba($c-black, 0.3);
    }

    .projects-cta__title {
        @include rem(40px);

        line-height: calc(48 / 40);

        * + & {
            margin: 30px 0 0;

            @include breakpoint(phablet) {
                margin: 13px 0 0;
            }
        }

        @include breakpoint(phablet) {

            @include rem(37px);

            line-height: calc(37 / 37);
        }
    }

    .projects-cta__body {
        @include rem(16px);

        line-height: calc(22 / 16);
        color: $c-projects-body;

        * + & {
            margin: 8px 0 0;

            @include breakpoint(phablet) {
                margin: 17px 0 0;
            }
        }
    }

    .projects-cta__button {

        * + & {
            margin: 20px 0 0;
        }
    }

.project__title {
    @include large-title;
    @include breakpoint(phablet) {
        @include rem(40px);
        line-height: calc(33 / 40);
    }
}
.project__location {
    @include apply-map($f-smallprint);
    @include rem(12px);

    line-height: calc(15 / 12);
    text-transform: uppercase;
    color: $c-label;
    font-weight: 400;
    letter-spacing: 1px;

    * + & {
        margin: 10px 0 0;
    }
}

.next-project {
    display: block;
    position: relative;
}
    .next-project__content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        text-align: center;
    }
    .next-project__link {
        display: block;
        margin-top: 40px;
        @include smallcaps;
        color: $c-label;
        letter-spacing: 1px;
        @include breakpoint(phablet) {
            margin-top: 30px;
        }
        @include breakpoint(mobile) {
            margin-top: 40px;
        }
        svg {
            margin-left: 10px;
        }
    }
    .next-project__title {
        @include large-title;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
