/// Good old fashioned clearfix. Add to a container.
@mixin clearfix {
    &:after {
        content: ".";
        display: block;
        height: 0;
        overflow: hidden;
        clear: both;
        visibility: hidden;
    }
}
