.designer-listing {
    position: relative;
    display: block;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
        opacity: 0.9;
        background: linear-gradient(0deg, rgba($c-black, 1) 0%, rgba($c-black, 0) 100%);
    }
}

.designer-listing--view-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    background-image: url('/images/previous-winners.jpg');
    background-size: contain;
    text-decoration: none;
    padding: 0 20px;

    &::after {
        display: none;
    }
}

    .designer-listing__content {
        position: absolute;
        right: 20px;
        bottom: 15px;
        left: 20px;
        z-index: 10;
        text-align: center;
    }

        .designer-listing__title {
            @include apply-map($f-title);
            @include lightondark;
            @include font(30px, 30px);

            color: $c-white;

            .designer-listing--view-all & {
                @include font(40px, 40px);

                margin-bottom: 25px;
            }
        }

        .designer-listing__organisation {
            @include font(14px, 20px);
            @include lightondark;
            @include semiboldText;

            color: $c-white;
            margin-top: 5px;
            text-transform: uppercase
        }

        .designer-listing__image {
            aspect-ratio: 1 / 1;
            overflow: hidden;

            img {
                transition: transform 500ms ease-in;

                .designer-listing:hover & {
                    transform: scale(1.075);
                }
            }
        }
