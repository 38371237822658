/// Default named breakpoints
///
$breakpoints: (
    desktop: "min-width: 801px",
    tablet: "max-width: 800px",
    mobile: "max-width: 500px"
) !default;

/// Wrap content in an appropriate media query
///
/// @parameter {list} $points The named breakpoint or breakpoints seperate by spaces
@mixin breakpoint($points) {
    $query: '';
    @each $point in $points {
        @if $query == '' and map-get($breakpoints, $point) {
            $query: $query + map-get($breakpoints, $point);
        } @else if map-get($breakpoints, $point) {
            $query: $query + '), (' + map-get($breakpoints, $point);
        }
    }
    @if $query != '' {
        @media screen and (unquote($query)) {
            @content;
        }
    } @else {
        @content;
    }
}
