.overlay-callout {
    display: flex;
    flex-wrap: nowrap;
    margin-left: calc(((100vw - 100%) / 2) * -1);
    margin-top: 40px;
    margin-bottom: 145px;

    @include breakpoint(tablet) {
        margin-right: calc(((100vw - 100%) / 2) * -1);
        margin-bottom: 100px;
    }

    @include breakpoint(phablet) {
        margin: 40px 0 55px;
    }
}

.overlay-callout--right-side {
    margin-left: 0;
    margin-right: calc(((100vw - 100%) / 2) * -1);
    justify-content: flex-end;
    margin-bottom: 175px;

    @include breakpoint(laptop) {
        margin-left: calc(((100vw - 100%) / 2) * -1);
    }

    @include breakpoint(tablet) {
        margin-bottom: 100px;
    }

    @include breakpoint(phablet) {
        margin: 140px 0 60px;
    }
}

    .overlay-callout__main {
        flex-basis: calc(100% - 80px);
        position: relative;

        @include breakpoint (tablet) {
            flex-basis: 100%;
        }
        
        .overlay-callout--right-side & {
            @include breakpoint(laptop) {
                flex-basis: 100%;
            }
        }
    }

        .overlay-callout__img {
            position: relative;
            height: 340px;
            overflow: hidden;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #2A3134 0%, rgba(28,28,28,0.85) 100%);

                .overlay-callout--right-side & {
                    background: linear-gradient(270deg, #1D1D1D 0%, rgba(28,28,28,0.85) 100%)
                }
            }
            
            img {
                @include objectFitImage;
            }

            @include breakpoint(phablet) {
                height: 360px;
            }
        }

        .overlay-callout__double {
            width: 33%;
            position: absolute;
            right: 80px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;

            @include breakpoint(tablet) {
                right: 15px;
                width: 35%;
            }

            @include breakpoint(phablet) {
                @include unsetAbsolute;

                width: 65%;
                top: -50px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .overlay-callout__triple {
            position: absolute;
            left: $site-gutter-new;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            width: calc(100% - 80px);

            @include breakpoint(tablet) {
                left: $site-gutter-mobile
            }

            @include breakpoint(phablet) {
                @include unsetAbsolute;

                top: -40px;
                width: 70%;
                left: 50%;
                transform: translateX(-50%);
            }

            @include breakpoint(small-mobile) {
                width: 100%;
            }
        }

        .overlay-callout__action {
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 75%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;

            @include breakpoint(phablet) {
                @include unsetAbsolute;

                bottom: 60px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

            .overlay-callout__copy {
                @include apply-map($f-title);
                @include rem(28px);

                line-height: 34px;
                color: $c-block-grey;
                white-space: nowrap;

                span {
                    color: $c-white;
                }

                @include breakpoint(phablet) {
                    @include rem(24px);

                    line-height: 29px;
                }
            }

    .overlay-callout__content {
        left: 90px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 50%;

        @include breakpoint(phablet) {
            left: 15px;
            right: 15px;
            bottom: 20px;
            transform: none;
            max-width: none;
            top: initial;
            z-index: 10;
        }

        .overlay-callout--left-side & {
            left: initial;
            right: 90px;
        }
    }

        .overlay-callout__body p {
            @include font(34px, 46px);
        
            color: $c-white;
            font-weight: 300;

            @include breakpoint(tablet) {
                @include font(24px, 29px);
            }
        }

    .overlay-callout__side {
        flex-basis: 25%;
    }