.footer {
    display: flex;
    flex-flow: column nowrap;
    max-width: 100%;
    border-top: 1px solid $c-filter-grey;
    background-color: $c-background;
}

    .footer__top {
        background: $c-background;
        max-width: calc(100% - 80px);
        display: grid;
        align-items: flex-start;
        gap: 0 20px;
        grid-template-areas:
            "company customer contact externals newsletter";
        grid-template-columns: 13% 13% 13% 20% 41%;
        padding-left: 40px;

        @include breakpoint(tablet) {
            grid-template-areas:
                "newsletter newsletter newsletter"
                "company customer contact"
                "company customer contact"
                "externals externals externals";
            grid-template-columns: 30% 30% 40%;
            max-width: calc(100% - 40px);
            padding-left: 0;
            padding-bottom: 20px;
        }

        @include breakpoint(mobile) {
            grid-template-areas:
                "newsletter newsletter"
                "company customer"
                "contact contact"
                "externals externals";
            grid-template-columns: 50% 50%;
            padding: 0;
            padding-bottom: 20px;
            max-width: 100%;
            gap: 0;
        }
    }

        .footer__company-nav,
        .footer__customer-nav,
        .footer__contact,
        .footer__reviews {
            padding-top: 35px;

            @include breakpoint(mobile) {
                padding-top: 10px;
                padding-left: $site-gutter-mobile;
            }
        }

        .footer__company-nav {
            grid-area: company;

            @include breakpoint(tablet) {
                padding-left: 40px;
            }

            @include breakpoint(mobile) {
                margin-bottom: 25px;
                margin-top: 30px;
                padding-left: $site-gutter-mobile;
            }
        }

        .footer__customer-nav {
            grid-area: customer;

            @include breakpoint(mobile) {
                border-left: 1px solid $c-filter-grey;
                margin-bottom: 25px;
                margin-top: 30px;
            }
        }

        .footer__contact {
            grid-area: contact;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;

            @include breakpoint(tablet) {
                padding-top: 35px;
            }

            @include breakpoint(mobile) {
                flex-direction: row;
                border-top: 1px solid $c-filter-grey;
                padding-top: 20px;
                margin-inline: $site-gutter-mobile;
                padding-left: 0;
                gap: 30px;

                & > * {
                    width: 50%;
                }
            }
        }

            .footer__callus {

                @include breakpoint(tablet) {
                    margin-top: 0;
                    padding-left: 0;
                }
                
                @include breakpoint(mobile) {
                    padding-top: 0;
                    padding-left: 0;
                }

                .footer__heading {
                    padding-bottom: 10px;

                    @include breakpoint(mobile) {
                        padding-bottom: 0;
                    }
                }
            }

            .callus__number {
                @include apply-map($f-primary);
                @include rem(13px);
                line-height: normal;
                letter-spacing: 0.1px;
                color: $c-brand;
                font-weight: 500;
                margin-top: -5px;

                @include breakpoint(mobile) {
                    margin-top: 0;
                    line-height: unset;
                }
            }

        .footer__externals {
            grid-area: externals;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 35px;
            padding-top: 28px;

            @include breakpoint(tablet) {
                flex-direction: row;
                padding-left: 35px;
            }

            @include breakpoint(mobile) {
                padding-top: 10px;
                padding-left: $site-gutter-mobile;
                gap: 5px;
            }
        }

            .footer__reviews {
                padding-top: 0;

                @include breakpoint (tablet) {
                    margin-top: 0;
                    padding-top: 0;
                }

                @include breakpoint (mobile) {
                    margin-top: 0;
                    width: 50%;
                    padding-left: 0;
                    padding-top: 0;
                }

                .footer__heading {
                    @include breakpoint (mobile) {
                        display: none;
                    }
                }
            }

                .footer__trustpilot {
                    display: flex;
                    justify-content: flex-start;
                    width: 33%;

                    iframe {

                        @include breakpoint(mobile) {
                            max-width: 150px;
                        }
                    }
                }

            .footer__socials {
                margin-top: 1px;

                @include breakpoint(tablet) {
                    padding-left: 35px;
                    align-self: center;
                    margin-top: 0;
                }

                @include breakpoint(mobile) {
                    padding-top: 5px;
                    padding-left: $site-gutter-mobile;
                    width: 50%;
                    padding-left: 0;
                    align-self: flex-start;
                    order: -1;
                }
            }

            .footer__newsletter {
                grid-area: newsletter;
                position: relative;
            }

            .footer__newsletter-image {
                z-index: 0;
                filter: brightness(0.6);
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .signup-form {
                position: relative;
                padding: 0 75px 40px 40px;
                z-index: 1;

                @include breakpoint(tablet) {
                    padding: 0 150px 35px;
                }

                @include breakpoint(phablet) {
                    padding: 0 $site-gutter-mobile 35px;
                }

                button {
                    flex-shrink: 0;
                }
            }

                .signup-form__label {
                    color: $c-white;
                    @include apply-map($f-primary);
                    @include rem(16px);
                    font-style: normal;
                    line-height: 21px;
                    letter-spacing: 0.5px;

                    @include breakpoint(mobile) {
                        margin-bottom: 10px;
                    }
                }

                .signup-form__field-wrapper {
                    margin-top: 10px;
                    display: flex;
                    align-items: flex-end;

                    input.signup-form__field {
                        border-bottom: 1px solid $c-white;

                        &::placeholder {
                            color: $c-white;
                            @include apply-map($f-primary);
                            @include rem(12px);
                            font-style: normal;
                            line-height: 26px;
                            letter-spacing: 0.5px;
                        }
                    }

                    .button--border {
                        width: unset;

                        span {
                            padding: 0;
                        }
                    }
                }

                .signup-form__thanks {
                    position: absolute;
                    z-index: 1;
                    bottom: 35px;
                    left: 40px;


                    @include breakpoint(tablet) {
                        left: 150px;
                    }

                    @include breakpoint(phablet) {
                        left: $site-gutter-mobile;
                    }
                }

        .footer__heading {
            @include apply-map($f-primary);
            @include rem(13px);
            line-height: normal;
            color: $c-dark;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        .footer__heading--newsletter {
            @include apply-map($f-title);
            @include rem(30px);
            line-height: 36px;
            letter-spacing: -1%;
            color: $c-white;
            text-transform: unset;
            font-style: italic;
            padding: 40px 75px 0 40px;
            position: relative;
            z-index: 1;

                @include breakpoint(tablet) {
                    padding: 35px 150px 0;
                }

                @include breakpoint(phablet) {
                padding: 35px $site-gutter-mobile 0;
                @include rem(25px);
                }
        }

            .footer__nav-list {
                display: flex;
                flex-direction: column;
            }

                .footer-nav__item {
                    @include apply-map($f-primary);
                    @include font(13px, 22px);

                    display: inline-block;
                    letter-spacing: 0.5px;
                    color: $c-dark;
                    cursor: pointer;
                    border-bottom: 1px solid transparent;
                    transition: border-bottom 0.3s ease;

                    &:hover {
                        border-bottom: 1px solid $c-brand-black;
                    }
                }

                .footer-nav__item--active {
                    font-weight: 500;
                    color: $c-brand;
                }

    .footer__bottom {
        display: flex;
        flex-wrap: wrap;
        background: $c-black;
        padding: 9px 40px;
        max-height: 45px;

        @include breakpoint(phablet) {
            justify-content: center;
            padding: 15px;
            max-height: unset;
        }

        .footer__links {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            @include breakpoint(phablet) {
                flex-direction: column;
                gap: 5px;
                justify-content: center;
                align-items: center;
            }
        }

            .footer__row {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
            }

        a {
            @include apply-map($f-primary);
            @include rem(12px);

            line-height: 26px;
            letter-spacing: 0.25px;
            color: $c-white;
            font-weight: 500;
            text-decoration: none;
            border-bottom: 1px solid transparent;
            transition: border-bottom 0.3s ease;

            &:hover {
                border-bottom: 1px solid $c-white;
            }

            @include breakpoint(phablet) {
                @include rem(11px);
            }
        }

        p {
            @include rem(12px);
            @include apply-map($f-primary);

            line-height: 26px;
            letter-spacing: 0.25px;
            color: $c-white;
            font-weight: 400;
            margin: 0 10px;

            &:last-of-type {
                margin-right: 0;
            }

            @include breakpoint(phablet) {
                @include rem(11px);

                &:last-of-type {
                    margin-right: 10px;
                }
            }
        }

        .footer__copyright {
            margin-left: auto;
            color: $c-footer-grey;

            @include breakpoint(phablet) {
                margin-left: 10px;
            }
        }

        #credit-link {
            color: $c-footer-grey;

            a {
                display: inline-block;
                margin: 0;
            }
        }
    }

// ******* BELOW STYLING FOR OLD FOOTER ON CHECKOUT PAGE ******** //

footer {
    background: $c-white;
    color: $c-white;

    a {
        color: $c-white;
        text-decoration: none;
    }
}


    .footer-section--legal-links {
        padding: 17px 0 18px;
        border-top: 1px solid rgba($c-white, 0.2);

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

        .footer-bottom {
            position: relative;

            @include breakpoint(tablet) {
                display: flex;
                flex-direction: column;
            }
        }

            .footer-bottom__group {}

            .footer-bottom__group--address {

                @include breakpoint(tablet) {
                    order: 2;
                    padding-bottom: 69px;
                }

                @include breakpoint(tablet) {
                    border-top: 1px solid rgba($c-white, 0.2);
                }
            }

            .footer-bottom__group--payment {
                font-size: 0;
                text-align: center;

                @include breakpoint(tablet) {
                    margin: 15px auto;
                    order: 1;
                }

                svg {
                    display: inline-block;
                    vertical-align: middle;
                    width: 25px;
                    height: 16px;
                }

                svg + svg {
                    margin: 0 0 0 5px;
                }

                .icon-sagepay-with-text + svg {
                    margin: 0 0 0 15px;
                }

                .icon-sagepay-with-text {
                    width: 80px;
                    height: 28px;
                    fill: $c-white;
                }
            }

            .footer-bottom__group--trustpilot {
                .trustpilot-widget {
                    display: block;
                    margin: 0 auto;
                    padding-left: 7px;
                }

                @include breakpoint(phablet) {
                    display: inline-block;
                    padding: 0 0 30px 0;
                    margin: 0;
                }
            }

            .footer-bottom_order-1 {
                order: 1;

                @include breakpoint(phablet) {
                    order: 0;
                }
            }

            .footer-bottom_order-2 {
                order: 2;

                @include breakpoint(phablet) {
                    order: 0;
                }
            }

            .footer-bottom_order-3 {
                order: 3;

                @include breakpoint(phablet) {
                    order: 0;
                }
            }

            .footer-bottom__group--legal-links {

                @include breakpoint(tablet) {
                    order: 0;
                }
            }

                .footer-bottom__copyright {

                    @include breakpoint(tablet) {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        border-top: 1px solid rgba($c-white, 0.2);
                    }
                }


        .footer-bottom__address,
        .footer-bottom__copyright {

            @include breakpoint(tablet) {
                display: block;
                margin: 0 auto;
                padding: 16px 0 20px;
                text-align: center;
            }

            p {
                @include rem(12px);

                line-height: calc(14 / 12);
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            span {

                @include breakpoint(tablet) {
                    display: block;
                }
            }

            span + span {

                @include breakpoint(tablet) {
                    margin-top: 5px;
                }
            }

            a {
                line-height: inherit;
            }
        }

        .footer-legal-links {
            font-size: 0;
            margin-bottom: 5px;

            @include breakpoint(tablet) {
                display: block;
                margin: 0 -10px;
                padding: 16px 10px 18px;
                border-bottom: 1px solid rgba($c-white, 0.2);
            }
        }

            .footer-legal-links__item {
                display: inline-block;

                a {
                    @include rem(12px);

                    line-height: calc(14 / 12);
                }

                &:not(:last-child) {

                    a:after {
                        content: '|';
                        margin-right: 5px;
                        margin-left: 5px;
                        color: rgba($c-white, 0.2);
                    }
                }
            }

        .footer-page-links {
            font-size: 0;
            padding: 40px 10px;
            margin: 0;

            @include breakpoint(phablet) {
                display: block;
                text-align: center;
                padding: 10px 10px;
            }

            &:first-of-type {

                @include breakpoint(tablet) {
                    float: none;
                }
            }
        }

        .footer-bottom__group--showrooms {
            @include breakpoint(phablet) {
                border-bottom: 1px solid rgba($c-white, 0.2);
                padding-bottom: 10px;
            }
        }

            .footer-page-links__item {
                display: inline-block;

                a {
                    @include rem(14px);
                    line-height: calc(16 / 14);
                }

                &:not(:first-of-type) {
                    a:before {
                        content: '|';
                        margin-right: 15px;
                        margin-left: 15px;
                        color: rgba($c-white, 0.2);
                    }
                }

                a:before {
                    @include breakpoint(tablet) {
                        content: '';
                    }
                }
            }
