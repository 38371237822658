@use 'sass:math';

.product-thumbnails {
    @include gallery-fade;

    @include breakpoint(tablet) {
        display: none;
    }
}

    .product-thumbnails__image {
        aspect-ratio: math.div(4, 3);
        position: relative;
        background-color: $c-background;

        img {
            @include swatch-hover(2px, false);

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &:not([src*='cylindo']) {
                mix-blend-mode: multiply;
            }

            .slick-current & {
                @include swatch-active(2px);
            }
        }

        .icon-360 {
            position: absolute;
            left: 50%;
            bottom: 12px;
            transform: translateX(-50%);
            z-index: 1;
            width: 50px;
        }
    }
