/// Use the floated psuedo element trick to give an element an aspect ratio
///
/// @param {int} $aspect The desired aspect ratio as width/height
/// @param {string} $gutter the horizontal gutter including units
@mixin aspect($aspect, $gutter: 0px) {
    overflow: hidden;

    &:before {
        float: left;
        content: "";
        padding-bottom: div(100%, $aspect);
        margin-bottom: div(-$gutter, $aspect);
    }

    @include clearfix;
}
