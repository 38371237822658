@use "sass:math";

.block--image-text {

    &.block--image-text--reverse {

        .block__content {
            grid-template-columns: calc(65% - 40px) calc(35% - 40px);

            @include breakpoint(tablet) {
                grid-template-columns: 1fr;
                column-gap: 0;
                row-gap: 30px;
            }
        }

        .block__text-container {
            order: 1;
        }
    }

    .block__content {
        display: grid;
        grid-template-columns: calc(35% - 40px) calc(65% - 40px);
        column-gap: 80px;
        align-items: center;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            column-gap: 0;
            row-gap: 30px;
        }
    }

        .block__text-container {

            @include breakpoint(tablet) {
                order: 1;
            }
        }

        .block__images {

            @include breakpoint(phablet) {
                margin: 0 -15px;
                width: calc(100% + 30px);
            }
        }

        .block__images--narrow {

            .block__image {
                @include aspect(2);
            }
        }

        .block__images--landscape {

            .block__image {
                @include aspect(math.div(7, 4));
            }
        }

        .block__images--square {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .block__image {
                @include aspect(1);
            }
        }
}
