
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

@each $name in map-keys($sizes) {
    @include breakpoint(map-get($sizes, $name)) {
        .#{$name}-text-left {
            text-align: left;
        }

        .#{$name}-text-center {
            text-align: center;
        }

        .#{$name}-text-right {
            text-align: right;
        }
    }
}
