.downloads {
    margin-bottom: 70px;
    padding-bottom: 15px;

    @include breakpoint(phablet) {
        margin-bottom: 40px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

    .downloads__text {
        @include rem(16px);

        line-height: calc(31 / 16);
        margin-bottom: 35px;

        @include breakpoint(phablet) {
            margin-bottom: 30px;
        }
    }

    .downloads__list {
        display: flex;
        flex-wrap: wrap;
        margin: -15px;

        @include breakpoint(phablet) {
            margin-right: -5px;
            margin-left: -5px;
        }
    }

    .downloads__list--m-full {

        @include breakpoint(mobile) {
            margin-right: 0;
            margin-left: 0;
        }
    }

        .downloads__item {
            width: calc(25% - 30px);
            margin: 15px;

            @include breakpoint(phablet) {
                width: calc(33.3333% - 10px);
                margin-right: 5px;
                margin-left: 5px;
            }

            @include breakpoint(mobile) {
                width: calc(50% - 10px);
            }

            .downloads__list--m-full & {

                @include breakpoint(mobile) {
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }

            .downloads__link {
                @include rem(14px);

                line-height: calc(17 / 14);
                font-weight: bold;
                display: flex;
                align-items: center;
                text-decoration: none;
                font-weight: 700;
                text-transform: uppercase;

                &:hover {
                    text-decoration: underline;
                }

                svg {
                    margin-right: 17px;
                }
            }
