@use "sass:math";

.design-service {
    position: relative;
    margin-bottom: 120px;
    padding: 120px 0;

    @include breakpoint(tablet) {
        padding: 0;
        margin-bottom: 60px;
    }

    &::after {
        position: absolute;
        content: '';
        left: calc(((100vw - 100%) / 2) * -1);
        right: 0;
        top: 0;
        bottom: 0;
        background: $c-background;
        z-index: -1;

        @include breakpoint(tablet) {
            right: calc(((100vw - 100%) / 2) * -1);
        }
    }
}

    .design-service__container {
        position: relative;
        padding-right: 80px;
        display: flex;
        flex-direction: column;

        @include breakpoint(tablet) {
            padding: 0;
            margin-bottom: 60px;
        }
    }

        .design-service__title {
            @include apply-map($f-title);
            @include rem(36px);

            line-height: 43px;
            color: $c-dark-grey;
            margin-bottom: 30px;

            @include breakpoint(tablet) {
                @include rem(32px);

                padding: 0;
                line-height: 38px;
                margin: 30px 0 10px;
            }

            @include breakpoint(phablet) {
                padding: 0 15px;
            }
        }

        .design-service__img {
            @include aspect(math.div(4, 3));

            padding-bottom: 115px;

            @include breakpoint(tablet) {
                @include aspect(math.div(75, 56));

                padding: 60px 0 0;
                order: -1;
            }
        }

        .design-service__content {
            position: absolute;
            right: 80px;
            bottom: 0;
            padding: 35px 0 0 35px;
            width: 65%;
            background: $c-background;

            @include breakpoint(tablet) {
                position: relative;
                width: 100%;
                padding: 0;
                right: unset;
            }

            @include breakpoint(phablet) {
                padding: 0 15px;
            }
        }

            .design-service__copy {
                @include apply-map($f-primary);
                @include rem(14px);

                line-height: 22px;
                margin-bottom: 20px;
                color: $c-block-copy;
            }

    .design-service__quotes {
        background: $c-white;
        padding: 90px 0 90px 60px;

        @include breakpoint(tablet) {
            margin-left: 30px;
            padding: 60px 15px 25px 30px;
        }
    }

        .design-service__quote {
            margin-bottom: 60px;

            @include breakpoint(laptop) {
                margin-bottom: 30px;
            }
        }

            .design-service__quote-title {
                @include apply-map($f-primary);
                @include rem(26px);

                line-height: 34px;
                color: $c-dark-grey;
                font-weight: 300;
                margin-bottom: 15px;

                span {
                    font-weight: 500;
                }

                @include breakpoint(tablet) {
                    @include rem(22px);

                    line-height: 30px;
                    margin-bottom: 10px;
                }
            }

            .design-service__quote-subtitle {
                @include apply-map($f-title);
                @include rem(16px);

                line-height: 19px;
                color: $c-dark-grey;

                @include breakpoint(tablet) {
                    @include rem(16px);

                    line-height: 22px;
                }
            }
