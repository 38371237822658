.anchor-container {
    position: relative;
}

    .anchor-container--offset {
        margin-top: -20px;
        padding-top: 20px;
    }

.anchor {
    position: absolute;
    top: 0px - $nav-height;

    .body--debug & {
        top: 0px - $nav-height - $debug-height;
    }

    .body--admin & {
        top: 0px - $nav-height - $admin-height;
    }

    .body--admin.body--debug & {
        top: 0px - $nav-height - $admin-height - $debug-height;
    }

    @include breakpoint(tablet) {
        top: 0px - $nav-height-mobile;

        .body--debug & {
            top: 0px - $nav-height-mobile;
        }

        .body--admin & {
            top: 0px - $nav-height-mobile - $admin-height;
        }

        .body--debug.body--admin & {
            top: 0px - $nav-height-mobile - $admin-height;
        }
    }
}
