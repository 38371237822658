@use 'sass:math';

.block--craftsmanship {
    .block__title {
        @include font(36px, 43px);

        margin-bottom: 50px;
        margin-top: 0;

        @include breakpoint(tablet) {
            @include font(28px, 34px);

            max-width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
        }
    }

    .block__list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 40px;
        margin-bottom: 40px;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            gap: 30px;
            margin-bottom: 25px;
        }
    }

        .block__item {
            text-align: center;

            @include breakpoint(tablet) {
                text-align: left;
                display: grid;
                column-gap: 20px;
            }
        }

            .block__icon {
                margin: 0 auto 25px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                width: 125px;
                height: 85px;

                @include breakpoint(tablet) {
                    width: 80px;
                    height: 54px;
                    grid-column: 1 / 2;
                    grid-row: 1 / 3;
                    margin: 0;
                    align-items: flex-start;
                }

                img {
                    width: 100%;
                    max-height: 85px;
                    object-fit: contain;

                    @include breakpoint(tablet) {
                        max-height: 54px;
                    }
                }
            }

            .block__subtitle {
                @include small-title;

                margin-bottom: 15px;

                @include breakpoint(tablet) {
                    @include font(20px, 24px);

                    grid-column: 2 / 3;
                    grid-row: 1 / 2;
                    margin-bottom: 5px;
                }
            }

            .block__text {
                color: $c-richcopy;

                @include breakpoint(tablet) {
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                }
            }

    .block__action {
        text-align: center;

        @include breakpoint(tablet) {
            text-align: left;
        }
    }
}
