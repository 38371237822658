.select2radio {
    display: block;
}

    .select2radio__option {
        @include rem(14px);

        line-height: calc(18 / 14);
        text-align: center;
        cursor: pointer;

        &:last-child {

            span {
                margin-bottom: 0;
            }
        }

        span {
            display: block;
            margin: 0 0 10px;
            padding: 11px;
            border: 1px solid $c-border;
        }
    }

    .has-select2radio--images .select2radio__option:not(.select2radio__option--active) {

        span {
            border-color: transparent;
        }
    }

    .has-select2radio--images .select2radio__option {

        span:before {
            content: '';
            display: block;
            width: 100%;
            height: 100px;
            margin-bottom: 10px;
            background: $c-border;
        }
    }

    .select2radio__option--active, .select2radio__option:hover {

        span {
            border-color: $c-brand;
        }
    }


// HACK! to be tidied up

.has-select2radio--images {

    .filterable__dimension__label,
    .filterable__dimension--customsofadims {
        display: none;
    }
}

.filterable__clear--single {
    display: none;
}
