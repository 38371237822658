.design-award-winner {
    position: relative;

    @include breakpoint(phablet) {
        position: static;
    }
}

.designer--left-bar {
    display: flex;
    justify-content: space-between;
}

.designer__featured {
    position: relative;
    padding-bottom: 60px;
    padding-right: 80px;

    @include breakpoint(tablet) {
        padding-right: 0;
        padding-bottom: 160px;
        margin-right: -12px;
    }

    @include breakpoint(phablet) {
        margin-right: 0;
    }

    .image-overlay-wrapper {
        aspect-ratio: 9 / 5;

        @include breakpoint(tablet) {
            aspect-ratio: 4 / 3;
        }
    }
}

    .designer__featured-overlay {
        display: inline-flex;
        align-items: flex-end;
        position: absolute;
        left: 100px;
        bottom: 60px;
        text-decoration: none;

        @include breakpoint(tablet) {
            right: 10px;
            left: 10px;
            bottom: 10px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .designer__featured-profile {
        margin-bottom: -60px;
        overflow: hidden;

        @include breakpoint(tablet) {
            width: 100px;
            height: 100px;
            margin-bottom: 0;
        }

        img {
            transition: transform 500ms ease-in;

            .designer__featured-overlay:hover & {
                transform: scale(1.075);
            }
        }
    }

    .designer__featured-content {
        margin-left: 10px;
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            margin: 0;
            margin-top: 10px;
            display: block;
            max-width: 100%;
        }
    }

.designer__title {
    @include apply-map($f-title);
    @include font(50px, 50px);

    @include breakpoint(tablet) {
        @include font(30px, 30px);
    }

    .designer__featured & {
        color: $c-white;

        @include breakpoint(tablet) {
            color: $c-dark;
        }
    }
}

.designer__intro {
    @include introcopy;
}

.designer__organisation {
    @include semiboldText;
    @include font(14px, 24px);

    color: $c-white;
    margin: 10px 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    @include breakpoint(tablet) {
        color: $c-dark;
    }
}

.designer__details {
    @include flush-last-child();
}
.designer__detail {
    margin-bottom: 24px;
}
.designer__detail-header,
.designer__detail-line {
    @include rem(12px);
    line-height: 1.25em;
    display: block;
}
.designer__detail-header {
    font-weight: 700;
    margin-bottom: 0.25em;
}

.designer__badges {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

    .designer__badge {
        @include semiboldText;
        @include font(16px, 24px);
        
        padding: 10px 25px;
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color: $c-white;
        background-color: $c-brand-black;
    }

// Designer standard content

.design-standard-content {}

    .design-standard-content__title {
        @include rem(60px);

        line-height: calc(72 / 60);

        @include breakpoint(phablet) {

            @include rem(40px);

            line-height: calc(48 / 40);
        }
    }

    .design-standard-content__title--awards {
        @include rem(53px);
    }

    .design-standard-content__intro {
        @include introcopy;
    }

// Designer directory

.design-directory {}

// Designer directory filters

.design-directory__filters {
    margin-bottom: 20px;

    .filterable {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .filterable__option {
        @include rem(14px);

        padding: 5px;
    }

    .select-replace {
        margin-left: 20px;
        max-width: 100px;
    }
}

.next-designer {
    display: block;
    position: relative;
}
    .next-designer__content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba($c-black, 0.5);
        text-align: center;
    }
    .next-designer__link {
        display: block;
        margin-top: 40px;
        @include smallcaps;
        color: $c-label;
        letter-spacing: 1px;
        @include breakpoint(phablet) {
            margin-top: 30px;
        }
        @include breakpoint(mobile) {
            margin-top: 40px;
        }
        svg {
            margin-left: 10px;
        }
    }
    .next-designer__title {
        @include large-title;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
