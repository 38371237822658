.header-background {
    display: none;
    position: absolute;
    top: 100%;
    height: 100vh;
    right: 0;
    left: 0;
    background: rgba($c-black, 0.75);

    @include breakpoint(nav) {
        @include allowForBars;

        position: fixed;
    }

    .header-nav--active-a:not([data-is-single="true"]) ~ & {
        display: block;
        opacity: 0;
        transition: opacity .3s;
    }

    .header-nav--active-b:not([data-is-single="true"]) ~ & {
        opacity: 1;
    }
}
