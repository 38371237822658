.basket-empty {
    text-align: center;
}

    .basket-empty__title {
        @include large-title;
        text-align: center;
    }

    .basket-empty__button {
        margin: 30px auto 50px;
    }
