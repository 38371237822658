.thanks__title {
    @include large-title;
    margin: 20px 0 60px;
    text-align: center;

    @include breakpoint(mobile) {
        margin-top: 0;
        margin-bottom: 30px;
    }
}

.thanks__copy-large {
    @include rem(24px);
    strong {
        font-weight: 700;
        color: $c-black;
    }
    color: $c-richcopy;
    margin-bottom: 30px;
    margin-top: 10px;

    @include breakpoint(tablet) {
        @include rem(22px);
    }

    @include breakpoint(mobile) {
        @include rem(18px);
    }
}

.thanks__note {
    max-width: 440px;
    margin: 30px auto 10px;
    color: $c-richcopy;
    a {
        text-decoration: none;

    }
}

    .thanks__panel {
        border: 1px solid $c-border;
        max-width: 980px;
        margin: 0 auto;
        padding: 30px;
    }

    .thanks__panel__title {
        @include smallcaps;
        font-weight: 700;
        color: $c-black;
        opacity: .3;
        margin-bottom: 30px;
    }

.thanks__clearabee {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    color: $c-richcopy;
}

.thanks__clearabee-img {
    img {
        max-width: 250px;
        height: auto;
    }
}
