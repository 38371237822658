.finance-calculator {
    background-color: $c-white;
    border: 2px solid $c-border;

    position: relative;
    max-width: 480px;
    margin: 20px auto;
    padding: 20px 20px;
    text-align: center;

    @include breakpoint(mobile) {
        max-width: none;
        margin: 0 -6px;
        padding: 20px;
    }

    .finance-calculator__title {
        @include rem(24px);
        @include lightondark;
        margin: 0 0 10px 0;
        line-height: calc(29 / 24);
        color: inherit;
    }

    .finance-calculator__logo {
        margin-bottom: 10px;
        width: auto;
    }

    .finance-calculator__table {
        @include rem(14px);
        width: 100%;
        tbody {
            tr {
                th {
                    background-color: #555;
                    border: 1px solid #555;
                    color: $c-white;
                    text-align: left;
                    padding: 5px;
                }
                td {
                    background-color: $c-white;
                    border: 1px solid #555;
                    color: #000;
                    text-align: right;
                    padding: 5px;
                }
            }
        }

        margin-bottom: 10px;
    }

    .finance-calculator__smallprint {
        @include rem(12px);

        line-height: calc(14 / 12);
        margin-bottom: 5px;
    }

    .finance-calculator__close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;

        svg {
            fill: #555;
        }

        @include breakpoint(mobile) {
            top: 10px;
            right: 10px;
        }
    }
}
