.section-intro {
    text-align: center;
    margin-bottom: 35px;

    @include breakpoint(tablet) {
        text-align: left;
    }
}

    .section-intro__title {
        @include apply-map($f-title);
        @include rem(36px);

        line-height: 43px;
        color: $c-dark-grey;
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            @include rem(32px);

            line-height: 38px;
            margin-bottom: 10px
        }
    }

    .section-intro__subtitle {
        @include apply-map($f-primary);
        @include rem(16px);

        line-height: 24px;
        color: $c-block-copy;

        @include breakpoint(tablet) {
            @include rem(14px);

            line-height: 22px;
        }
    }

    .section-intro__subtitle--uppercase {
        @include font(12px, 18px);

        letter-spacing: 2.5px;
        text-transform: uppercase;
        margin: 15px 0;
    }