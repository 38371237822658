@use 'sass:math';

.hero {
    position: relative;
    height: 100vw * calc(3 / 4);
    max-height: calc(100vh - 45px);
    overflow: hidden;

    @include breakpoint(full) {
        height: 100vw * calc(9 / 16);
    }

    @include breakpoint(phablet) {
        height: auto;
        max-height: none;
    }
}

.hero--categories {
    @include aspect(math.div(7, 2));

    height: auto;

    @include breakpoint(tablet) {
        @include aspect(math.div(7, 2));
        background-color: transparent;
    }
}

.hero__inner {
    position: relative;
}

.hero__item {
    display: none;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 200px;
        opacity: 0.4;
    }

    .hero--categories & {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 50%;
            opacity: 0.4;
        }
    }
}

.hero__item:first-of-type {
    display: block;
}
    .hero__img {
        .hero--categories & {
            @include breakpoint(tablet) {
                position: relative;
            }
        }
    }

    .hero__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: linear-gradient(180deg, rgba($c-black, 0) 0%, $c-black 100%);

        @include breakpoint(phablet) {
            height: 150px;
            background: linear-gradient(0deg, rgba($c-black, 0) 0%, $c-black 100%);
        }

        .hero--categories & {
            top: unset;
            bottom: 0;
            background: linear-gradient(0deg, rgba($c-black, 1) 0%, rgba($c-black, 0) 100%);
            height: 60%;
            opacity: 0.5;

            @include breakpoint(tablet) {
                top: 0;
                height: 100%;
            }
        }
    }

    .hero__content {
        position: absolute;
        width: 45%;
        left: $site-gutter-new;
        bottom: 80px;

        @include breakpoint(tablet) {
            width: 75%;
            left: calc($site-gutter-new / 2);
            bottom: calc($site-gutter-new / 2);
            width: 100%;
            position: relative;
            padding: 25px $site-gutter-mobile 40px;
            left: initial;
            bottom: initial;
            background-color: $c-dark;
        }

        .hero--categories & {
            width: 75%;
            bottom: 30px;

            @include breakpoint(tablet) {
                background-color: transparent;
                bottom: initial;
                padding-bottom: 0;
                margin-top: -80px;
                width: auto;
            }
        }
    }

        .hero__title {
            @include lightondark;
            @include apply-map($f-title);
            @include font(50px, 60px);

            margin-bottom: 15px;
            color: $c-white;

            @include breakpoint(phablet) {
                @include font(32px, 38px);

                margin-bottom: 20px;
            }

            .hero--categories & {
                @include font(44px, 53px);

                margin-bottom: 10px;

                @include breakpoint(tablet) {
                    margin-bottom: 0;
                }

                @include breakpoint(phablet) {
                    @include font(32px, 38px);
                }
            }
        }

        .hero__text {
            @include lightondark;
            @include font(18px, 30px);

            color: $c-white;
            letter-spacing: 0.25px;

            @include breakpoint(phablet) {
                @include font(14px, 22px);

                margin-bottom: 25px;
            }

            a {
                color: $c-white;
            }

            .hero--categories & {
                font-weight: 500;
                margin-bottom: 0;

                @include breakpoint(tablet) {
                    @include font(14px, 24px);

                    color: $c-richcopy;
                    font-weight: 400;
                    margin-top: 40px;
                    margin-bottom: 10px;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .hero__button {
            margin-top: 30px;

            .hero--categories & {
                margin-top: 20px;
            }
        }

.hero--home {
    --hero-height: calc(90vh - #{$nav-height});
    --hero-min-height: 650px;
    --hero-mobile-max-height: 700px;
    height: var(--hero-height);
    max-height: var(--hero-height);
    min-height: var(--hero-min-height);

    @include breakpoint(tablet) {
        --hero-height: calc(100vh - #{$nav-height-mobile});
        min-height: 0;
        max-height: var(--hero-mobile-max-height);
    }

    .has-notice-bar & {
        --hero-height: calc(90vh - #{$top-elements-height});

        @include breakpoint(tablet) {
            --hero-height: calc(100vh - #{$nav-height-mobile} - #{$notice-height});
        }
    }

    .hero__inner {
        height: var(--hero-height);
        min-height: var(--hero-min-height);

        @include breakpoint(tablet) {
            max-height: var(--hero-mobile-max-height);
            min-height: 0;
        }
    }

    .hero__item {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;
        height: 100%;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            align-items: flex-start;
        }

        &::after {
            display: none;
        }

        &::before {
            background: url('/images/texture.png') repeat 90%;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.2;
            z-index: 0;
        }
    }

    .hero__item--medium {
        grid-template-columns: minmax(0, 65%) minmax(0, 35%);

        &.hero__item--right {
            grid-template-columns: minmax(0, 35%) minmax(0, 65%);
        }
    }

    .hero__item--large {
        grid-template-columns: minmax(0, 72%) minmax(0, 28%);

        &.hero__item--right {
            grid-template-columns: minmax(0, 28%) minmax(0, 72%);
        }
    }

    .hero__item--large,
    .hero__item--medium {
        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
        }

        &.hero__item--right {
            @include breakpoint(tablet) {
                grid-template-columns: 1fr;

                .hero__title {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
    }

    .hero__item--small-mobile {
        @include breakpoint(tablet) {
            grid-template-rows: calc(var(--hero-height) * 0.35) 1fr;
        }
    }

    .hero__item--medium-mobile {
        @include breakpoint(tablet) {
            grid-template-rows: calc(var(--hero-height) * 0.43) 1fr;
        }
    }

    .hero__item--large-mobile {
        @include breakpoint(tablet) {
            grid-template-rows: calc(var(--hero-height) * 0.48) 1fr;
        }
    }

    .hero__item--right {
        .hero__content {
            order: -1;

            @include breakpoint(tablet) {
                order: unset;
            }
        }

        .hero__title {
            margin-left: 0;
        }
    }

    .hero__overlay {
        background: linear-gradient(0deg, rgba($c-black, 0.6) 0%, rgba($c-black, 0.1) 15%, rgba($c-black, 0.1) 100%);

        @include breakpoint(tablet) {
            height: 100%;
        }
    }

    .hero__img {
        aspect-ratio: unset;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            animation: slowZoom linear 23s 1 forwards;
            object-fit: cover;

            @keyframes slowZoom {
                from {
                    transform: scale(1);
                }

                to {
                    transform: scale(1.1);
                }
            }
        }
    }

    .hero__content {
        position: relative;
        width: auto;
        left: unset;
        bottom: unset;
        // margin-bottom: 40px;
        padding-left: $site-gutter-new;
        padding-right: $site-gutter-new;
        z-index: 1;

        @include breakpoint(tablet) {
            padding: $site-gutter-mobile;
            background-color: transparent;
            margin-bottom: 0;
        }
    }

    .hero__content--text-light {
        color: $c-white;
    }

    .hero__content--text-dark {
        color: $c-dark;
    }

        .hero__title {
            @include lightondark;
            @include blackFont;
            @include font(100px, 100px);

            position: relative;
            z-index: 1;
            letter-spacing: -1px;
            color: var(--text-colour);

            @include breakpoint(tablet) {
                @include font(50px, 50px);

                width: auto;
                text-indent: 0;
                padding: 0;
                margin-top: 10px;
            }
        }

        .hero__title--mobile ~ .hero__title {
            @include breakpoint(mobile) {
                display: none;
            }
        }

        .hero__title--mobile {
            display: none;

            @include breakpoint(mobile) {
                display: block;
            }
        }

        .hero__subtitle {
            @include lightondark;
            @include font(18px, 28px);

            color: var(--text-colour);
            letter-spacing: 0.25px;
            font-weight: 500;
            max-width: 545px;
            margin-top: 30px;

            @include breakpoint(tablet) {
                @include font(16px, 26px);

                margin-top: 20px;
            }
        }

        .hero__subtitle--mobile ~ .hero__subtitle {
            @include breakpoint(mobile) {
                display: none;
            }
        }

        .hero__subtitle--mobile {
            display: none;

            @include breakpoint(mobile) {
                display: block;
            }
        }

        .hero__text {
            @include lightondark;
            @include font(16px, 26px);

            color: var(--text-colour);
            letter-spacing: 0.25px;
            max-width: 545px;
            margin-top: 20px;

            @include breakpoint(tablet) {
                @include font(14px, 24px);

                margin-top: 10px;
            }

            a {
                color: var(--text-colour);
            }
        }

        .hero__text--mobile ~ .hero__text {
            @include breakpoint(mobile) {
                display: none;
            }
        }

        .hero__text--mobile {
            display: none;

            @include breakpoint(mobile) {
                display: block;
            }
        }

        .hero__button {
            margin-top: 30px;

            @include breakpoint(tablet) {
                margin-top: 20px;
            }
        }
}
