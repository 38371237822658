.block--collection-inspiration {
    padding: 0 $site-gutter;
    max-width: 1280px;

    @include breakpoint(tablet) {
        padding: 0;
    }

    @include breakpoint(phablet) {
        margin-bottom: 30px;
    }

    .product-listing__colourways {
        display: none;
    }
}
