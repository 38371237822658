@use "sass:math";

.header__nav {
    height: 57px;

    @include breakpoint(nav) {
        height: unset;
    }
}

.header-nav {
    height: 100%;
}

.header-nav--active-a {
    @include breakpoint(nav) {
        @include allowForBars;

        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 428px;
        transform: translateX(-100%);
        z-index: 1;

        .has-notice-bar & {
            margin-top: 0;
        }

        .has-notice-bar.body--admin & {
            margin-top: $admin-height;
        }
    }
}

.header-nav--active-b {
    @include breakpoint(nav) {
        transition: transform .1s;
    }
}

.header-nav--active-c {
    @include breakpoint(nav) {
        transform: none;
    }
}

.header-nav__toggle {
    display: none;

    @include breakpoint(nav) {
        display: block;
        position: absolute;
        top: calc($nav-height-mobile / 2);
        left: 15px;
        transform: translateY(-50%);
        z-index: 5;
    }

    .header-nav--active-a & {
        @include breakpoint(nav) {
            left: calc(100% + 15px);
        }
    }

    .header-nav--active-c & {
        @include breakpoint(nav) {
            left: initial;
            right: 15px;
        }
    }

    svg {
        width: 20px;
        fill: $c-brand-black;

        .header--fixed & {
            fill: $c-brand-black;
        }

        .header--clear:not(:hover):not(.header__nav-item--active) & {
            fill: $c-white;
        }

        &.icon-close {
            display: none;

            .header-nav--active-c & {

                @include breakpoint(nav) {
                    display: block;
                    fill: $c-brand-black;
                }
            }
        }

        &.icon-hamburger {
            display: block;

            .header-nav--active-c & {
                display: none;
            }
        }
    }
}

    .header-nav__items {
        padding: 0;
        overflow: auto;
        height: 100%;

        @include breakpoint(nav) {
            display: none;
        }

        .header-nav--active-a & {

            @include breakpoint(nav) {
                position: relative;
                display: flex;
                flex-direction: column;
                height: 100%;
                background-color: $c-white;
                padding: 0 15px;
            }
        }
    }

    .header-nav__top-bar {
        display: none;

        @include breakpoint(nav) {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 22px 20px;
            background-color: $c-white;
        }

        > svg {
            display: block;
            width: 182px;
            height: 16px;
            fill: $c-black;

            .header-nav__items[data-nav-item] & {

                @include breakpoint(nav) {
                    opacity: 0;
                }
            }
        }
    }

        .header-nav__list-wrapper {
            overflow: auto;
            display: flex;
            justify-content: center;
            height: 100%;

            @include breakpoint(nav) {
                display: block;
            }
        }

        .header-nav__list {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: 100%;
            gap: 34px;
            transition: gap 0.3s ease;

            @include breakpoint(nav) {
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                height: unset;
                gap: 0;
            }

            .header--fixed & {
                @include breakpoint('nav-squeeze') {
                    gap: 22px;
                }
            }
        }


        .header-nav__list--secondary {
            display: none;

            @include breakpoint(nav) {
                display: block;
                margin-bottom: 20px;
            }
        }

        .header-nav__item {
            border-bottom: 1px solid transparent;
            transition: border-bottom 0.3s ease;

            a {
                text-decoration: none;
                
                &:hover,
                &:active {
                    color: $c-brand;

                    svg {
                        fill: $c-brand;
                    }
                }
            }

            &:hover,
            &:active {
                border-bottom: 1px solid $c-brand;
                color: $c-brand;
            }

            &:first-child {
                margin-left: 0;
            }

            &.header-nav__item--sale,
            &.header-nav__item--awards,
            &.header-nav__item--new-in {
                margin-right: 0;

                a, span {
                    color: $c-brand;
                    text-decoration: none;

                    .header-nav__list--fixed & {
                        color: $c-brand;
                    }
                }

                &:hover,
                &.header-nav__item--current {
                    border-bottom: 1px solid $c-brand;

                    @include breakpoint(nav) {
                        border-bottom: 1px solid $c-nav-border;
                        color: $c-brand;
                    }
                }
            }

            @include breakpoint('nav-squeeze') {
                margin-right: 5px;
            }

            @include breakpoint(nav) {
                width: 100%;
                border-bottom: 1px solid $c-nav-border;
                margin: 0;
                padding: 9px 0;

                &:hover {
                    border-bottom: 1px solid $c-nav-border;
                }

                &:first-of-type {
                    border-top: 1px solid $c-nav-border;
                }
            }

            .header-nav__list--fixed & {
                span {
                    color: $c-dark;
                }
            }

            .header-nav__list--secondary & {
                &:first-child {
                    border-top: none;

                    a {
                        font-weight: 500;
                    }
                }
            }

            &.header-nav__item--active-a {
                border-bottom: 1px solid $c-dark;

                @include breakpoint(nav) {
                    border-bottom: none;
                }
            }
        }

        .header-nav__item--current {
            border-bottom: 1px solid currentColor;
        }

        .header-nav__item {
            @for $i from 0 to $nav-items {

                .header-nav__items[data-nav-item="#{$i}"] &:not([data-nav-item="#{$i}"]) {
                    display: none;
                }
            }
        }

                .header-nav__link {
                    @include lightondark;
                    @include rem(13px);
                    @include apply-map($f-primary);

                    color: $c-brand-black;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    line-height: 24px;
                    text-align: center;
                    transition: color .3s ease;

                    @include breakpoint(nav) {
                        color: $c-brand-black;
                        width: 100%;
                    }

                    .header-nav__item--active-a & {

                        @include breakpoint(nav) {
                            @include rem(13px);

                            line-height: 11px;
                            position: absolute;
                            top: 30px;
                            left: 0;
                            right: 0;
                            width: 100%;
                            letter-spacing: 0.5px;
                            text-align: center;
                            transform: translateY(-50%);
                            opacity: 0.3;
                            font-weight: 500;
                        }
                    }

                    .header-nav__item--active-b & {
                        transition: opacity .2s ease;
                    }

                    .header-nav__item--active-c & {
                        opacity: 1;
                    }

                    .header-nav__list--secondary & {
                        font-weight: 400;
                        text-decoration: none;
                    }

                    .header--clear:not(:hover):not(.header__nav-item--active) & {
                        color: $c-white;
                    }
                }

                .header-nav__link--fixed {
                    color: $c-dark;
                }

                .header-nav__link-content {
                    display: flex;
                    align-items: center;
                    gap: 7px;

                    @include breakpoint(nav) {
                        justify-content: space-between;
                        gap: 0;

                        .header-nav__item--active-a & {
                            position: relative;
                            justify-content: center;
                            flex-direction: row-reverse;
                        }
                    }
                }

                    .header-nav__icon {
                        display: none;

                        @include breakpoint(nav) {
                            display: block;
                        }

                        svg {
                            fill: $c-brand-black;;
                            transition: fill 300ms ease;

                            @include breakpoint(nav) {
                                width: 8px;
                                height: 14px;
                            }
                        }

                        .header-nav__link--is-menu & {
                            display: flex;
                            transform: rotate(90deg);
                            transition: transform 150ms ease-in;

                            @include breakpoint(nav) {
                                transform: none;
                            }
                        }

                        .header-nav__item--active-a & {
                            transform: rotate(270deg);

                            svg {
                                fill: $c-brand;
                            }

                            @include breakpoint(nav) {
                                position: absolute;
                                left: $site-gutter-mobile;
                                top: 50%;
                                transform: translateY(-50%) rotate(180deg);

                                svg {
                                    fill: $c-dark;
                                }
                            }
                        }

                        .header--clear:not(:hover) .header-nav__link--is-menu & {
                            svg {
                                fill: $c-white;
                            }
                        }
                    }


        .header-nav__content {
            display: none;

            .header-nav__item--active-a & {
                display: flex;
                gap: 40px;
                position: absolute;
                padding: 15px $site-gutter-new 40px;
                top: 100%;
                right: 0;
                left: 0;
                background-color: $c-white;
                z-index: 1;
                opacity: 0;

                @include breakpoint(nav) {
                    position: static;
                    background-color: transparent;
                    padding: 0;
                    opacity: 0.3;
                    flex-wrap: wrap;
                }
            }

            .header-nav__item--active-b & {
                transition: opacity .3s;
            }

            .header-nav__item--active-c & {
                opacity: 1;
            }
        }

            .header-nav__sub-list-container {
                flex-basis: 25%;
                flex-grow: 1;

                @include breakpoint(nav) {
                    flex-basis: 100%;
                }
            }

            .header-nav__sub-list-container--compact {
                flex-grow: 0;
            }

                .header-nav__sub-list {
                    column-count: 2;

                    .header-nav__sub-list-container--compact & {
                        column-count: 1;
                    }

                    @include breakpoint(nav) {
                        padding-bottom: 10px;
                        column-count: 1;

                        &:not(:nth-of-type(1)) {
                            margin-left: 0;
                            padding-top: 10px;
                        }
                    }
                }

                    .header-nav__subtitle {
                        @include font(18px, 26px);

                        padding-bottom: 17px;
                        border-bottom: 1px solid $c-border;
                        color: $c-dark;
                        margin-bottom: 18px;
                        font-weight: 500;
                    }

                    .header-nav__sub-item {
                        &:not(:last-child) {
                            margin-bottom: 8px;
                        }

                        a {
                            @include font(16px, 22px);

                            color: $c-dark;
                            font-weight: 400;
                            text-decoration: none;
                            border-bottom: 1px solid transparent;
                            transition: border-bottom .3s ease;

                            &:hover {
                                border-bottom: 1px solid $c-dark;
                            }
                        }

                        &.header-nav__sub-item__view-all {
                            a {
                                @include font(14px, 22px);

                                font-weight: 500;
                            }
                        }

                        @include breakpoint(nav) {
                            padding: 3px 0 3px 20px;
                            border-left: 1px solid $c-nav-border;
                        }
                    }

            .header-nav__categories {
                position: relative;
                flex-basis: calc((1 / 3) * 100%);
                flex-shrink: 0;
                display: block;
                overflow: hidden;

                @include breakpoint(nav) {
                    display: none;
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 100px;
                    width: 100%;
                    opacity: 0.7;
                    z-index: 1;
                    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                }
            }

                .header-nav__img {
                    @include aspect(math.div(4, 3));

                    width: 100%;

                    img {
                        transition: transform 1s ease;

                        .header-nav__categories:hover & {
                            transform: scale(1.075);
                        }
                    }
                }

                .header-nav__img-button {
                    @include font(16px, 26px);

                    text-transform: uppercase;
                    letter-spacing: 2px;
                    color: $c-white;
                    font-weight: 500;
                    position: absolute;
                    left: 30px;
                    right: 30px;
                    bottom: 25px;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    svg {
                        fill: currentColor;
                        transition: fill 300ms ease-in;

                        .header-nav__categories:hover & {
                            color: $c-brand;
                        }
                    }
                }

                .quick-links__update {
                    @include rem(11px);

                    position: absolute;
                    top: 50%;
                    right: 100%;
                    margin-right: 22px;
                    padding: 5px 10px;
                    color: $c-white;
                    background-color: $c-brand;
                    border-radius: 5px;
                    white-space: nowrap;
                    transform: translateY(-150%);
                    line-height: calc(15 / 11);
                    font-weight: 400;
                    opacity: 0;
                    transition: all .3s ease;
                    z-index: 2;
                    pointer-events: none;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        border: 5px solid transparent;
                        border-left-color: $c-brand;
                        transform: translateY(-50%);
                    }
                }

                .quick-links__update--active {
                    opacity: 1;
                    transform: translateY(-50%);
                }
