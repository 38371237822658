.page-intro-new {
    text-align: center;
    margin: 35px 0 50px;

    @include breakpoint(tablet) {
        text-align: left;
        margin: 35px 0;
    }
}

.page-intro-new--left {
    text-align: left;
}

.page-intro-new--centre {
    @include breakpoint(tablet) {
        text-align: center
    }
}

.page-intro-new--max {
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(tablet) {
        max-width: none;
    }
}

.page-intro-new--plp {
    margin-bottom: 10px;

    p {
        max-width: 75%;

        @include breakpoint(tablet) {
            max-width: none;
        }
    }
}

.page-intro-new--small-margin {
    margin-bottom: 10px;
}

.page-intro-new__title {
    @include apply-map($f-title);
    @include rem(44px);

    line-height: 53px;
    color: $c-dark-grey;

    @include breakpoint(tablet) {
        @include rem(32px);

        line-height: 38px;
    }
}

.page-intro-new__intro {
    @include breakpoint(phablet) {
        display: none;
    }
}
