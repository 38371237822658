.spotlight {  

    &:after {
        content: none;
    }
}
    
    .spotlight__contents {
        padding: 50px 55px 55px;

        @include breakpoint(tablet) {
            padding: 40px 15px 20px;
        }
    }

        .spotlight__title {
            @include font(30px, 36px);
            @include apply-map($f-title);

            color: var(--block-text-colour);
            margin-bottom: 25px;

            @include breakpoint(tablet) {
                @include font(25px, 30px);
                margin-bottom: 15px;
            }
        }

        .spotlight__intro {
            color: var(--block-text-colour);
            margin-bottom: 20px;
            @include font(18px, 26px);
            padding-left: 45px;
            position: relative;

            @include breakpoint(tablet) {
                @include font(14px, 24px);
                margin-bottom: 10px;
                padding-left: 25px;
            }

            &:before {
                content: '';
                height: 100%;
                width: 1px;
                background-color: var(--block-text-colour);
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.5;
            }
        }

        .spotlight__cta a {
            border-bottom: 2px solid var(--block-text-colour);
            transition: all 0.3s ease;
            margin-bottom: 35px;
            margin-left: 45px;

            span {
                color: var(--block-text-colour);
            }

            @include breakpoint(tablet) {
                margin-bottom: 20px;
                margin-left: 25px;
            }
        }
