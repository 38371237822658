@use "sass:math";

.collection-landing--wrap {
    padding: 0 10px;
}

.collection-landing__title {
    margin-bottom: 50px !important;
}

.collection-landing__large-item {
    margin-top: 50px;

    @include breakpoint(laptop) {
        margin-top: 20px;

    }
}
.collection-landing__image {
    @include aspect(math.div(680, 565));
    mix-blend-mode: multiply;
    position: relative;
    background-size: contain;
    background-blend-mode: multiply;
    background-color: $c-background;

    img {
        mix-blend-mode: multiply;
        position: absolute; // can be removed when using correct crop
        left: 0; // can be removed when using correct crop
        top : 0; // can be removed when using correct crop
    }

    @include breakpoint(phablet) {
        @include aspect(1.2);
    }
}

.collection-landing__image--small {
    @include aspect(math.div(560, 275));

    @include breakpoint(mobile) {
        @include aspect(1);
    }
}

.collection-landing__image--chair {
    position: relative;
    @include aspect(1);
    background-size: contain;
    background-blend-mode: multiply;
    background-color: $c-background;
}

.collection-landing__overlay {
    position: absolute;
    color: $c-richcopy;
}

.collection-landing__inner {
    @include tiny-title;
    font-style: italic;
    color: $c-white;
    position: absolute;
    top: 8px;
    left: 2px;
    padding: 20px;

    @include breakpoint(phablet) {
        @include rem(18px);
        top: 9px;
        left: 0px;
        text-align: center;
    }

    @include breakpoint(mobile) {
        top: -2px;
        left: -2px;
        @include rem(12px);
    }
}

.collection-landing__inner--signature {
    @include rem(16px);
    top: 22px;

    @include breakpoint(mobile) {
        top: 7px;
        left: -6px;
        @include rem(10px);
    }
}

.collection-landing__overlay--top-left {
    top: 20px;
    left: 20px;
    height: 50px;

    @include breakpoint(mobile) {
        top: 10px;
        left: 10px;

        svg {
            width: 70px;
            height: 70px;
        }
    }
}

.collection-landing__overlay--top-right {
    top: 0;
    right: 0;
    text-align: right;
    margin: 10px;

    @include rem(12px);
    td {
        &:nth-child(even) {
            font-weight: 700;
            color: black;
            padding-left: 10px;
        }
    }
}

.collection-landing__overlay--bottom {
    bottom: 0;
    left: 0;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    @include rem(14px);

    .collection-landing__image--chair & {
        bottom: -10px;
    }

    span {
        @include tiny-title;
        @include rem(14px);
        font-weight: 700;
        color: black;
        font-style: italic;

        @include breakpoint(mobile) {
            @include rem(14px);
        }
    }

}

.collection-landing__details {
    text-align: left;

    color: $c-richcopy;

    table {
        width: 100%;

        @include breakpoint(mobile) {
            @include rem(14px);
        }
    }

    p {
        margin-bottom: 20px;
        border-bottom: 1px solid $c-border;
        padding-bottom: 20px;
    }

    td {
        font-weight: 700;
        color: black;
        padding-right: 10px;
    }

    tr {
        height: 30px;
    }
}

.collection-landing__details--small {
    padding-top: 20px;

    td {
        padding-right: 20px;
    }
}

.collection-landing__details .collection-landing__title--signature {
    font-weight: 700;
    color: black;
    font-style: italic;
    @include tiny-title;
    @include rem(16px);
    padding-bottom: 0;
    border-bottom: none;
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }
}

.collection-landing__details--signature {
    tr {
        height: auto;
    }

    th {
        width: auto;
        font-weight: 700;
        color: #000;
    }

    td {
        font-weight: inherit;
        color: inherit;
    }
}

.collection-landing__overlay--signature {
    td {
        span {
            font-weight: 700;
        }
    }
}

// CHAIR STUFF
.collection-landing__chair-wrap {
    padding: 0 5px;

    display: flex;
    flex-direction: column;
    position: relative;
}

.collection-landing__product-anchor {
    position: absolute;
    left: 0;
    top: -175px;

    @include breakpoint(tablet) {
        top: -65px;
    }
}

.collection-landing__details--chair {
    padding-top: 20px;
    border-top: 1px solid $c-border;

    tr {
        height: auto;
    }

    td {
        font-weight: 400;

        span {
            font-weight: 700;
        }
    }

    p {
        min-height: 100px;

        @include breakpoint(phablet) {
            min-height: 120px;
        }
    }
}

.collection-landing__description {
    padding: 20px 0;

    &--chair {
        flex: 1 0 auto;
    }
}
