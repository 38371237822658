.field {
    margin-bottom: 20px;

    label, dt {
        @include apply-map($f-label);
        @include rem(16px);

        display: block;
        color: $c-black;
        margin-bottom: 10px;
        line-height: calc(19 / 16);

        span {
            color: $c-brand;
        }
    }

    .sub-label {
        @include rem(14px);

        line-height: calc(16 / 14);
        font-weight: 100;
    }

    &.field--invert label {
        color: $c-white;
    }

    &.field--invert .sub-label {
        color: rgba($c-white, 0.65);
    }
}

.field--flush {
    margin-bottom: 0;
}

.field--short {
    max-width: 105px;
    margin-right: 20px;

    @include breakpoint(phablet) {
        max-width: 85px;
    }

    label {
        @include font(14px, 17px);
        @include semiboldText;

        margin-bottom: 6px;
        color: $c-dark-grey;
    }
}

    .field input.check_box {
        margin-right: 15px;
        display: inline-block;
        vertical-align: top;
    }

    .field label.check_box {
        @include apply-map($f-field);
        @include rem(13px);
        text-transform: none;
        letter-spacing: normal;
        color: $c-body;

        .check-replace, input.check_box {
            + .label {
                display: inline-block;
                width: calc(100% - 40px);
            }
        }

        .check-replace {
            margin-right: -40px;
        }

        .check_box__text {
            @include rem(16px);
            display: inline-block;
            vertical-align: top;
            margin-top: 4px;
            padding-left: 50px;
        }
    }

.input {
    @include apply-map($f-field);
    @include rem(16px);

    line-height: calc(22 / 16);
    margin: 0;
    color: $f-field-color;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;

    .field--invert & {
        color: $c-white;
        background: $c-black;
        border-color: rgba($c-white, 0.35);
    }
}

.field__describe {
    margin-top: -4px;
    margin-bottom: 8px;
    display: block;
}

input.input,
textarea.input {
    width: 100%;
    // border: 1px solid $c-border;
    border: 1px solid $c-filter-grey;

    transition: box-shadow .3s;
    -webkit-appearance: none;

    &:focus {
        box-shadow: $field-outline;
    }

    .signup-form__field-wrapper--footer & {
        background: transparent;
        color: $c-footer-grey;
        padding: 0 0 7px 0;
        border: none;
        border-bottom: 1px solid $c-border-footer;
        transition: border-bottom 0.3s ease, color 0.3s ease;
        margin-right: 20px;

        &:focus {
            border-bottom: 1px solid $c-white;
            color: $c-white;
            box-shadow: none;
        }
    }

    .search__fieldset & {

        &:focus {
            box-shadow: none;
        }
    }
}

input.input {
    height: 44px;
    padding: 4px 15px;
}
textarea.input {
    padding: 8px 15px;
    height: 200px;
    max-width: 100%;
}

select {
    width: 100%;
    border: 1px solid $c-border;
    padding: 4px 15px;
    height: 44px;
    background-color: $c-white;
    border-radius: 0;
    -webkit-appearance: none;
    text-overflow: ellipsis;
    &:focus {
        box-shadow: $field-outline;
    }
}

.select-replace {
    display: block;
    position: relative;
    background: $c-white;

    select {
        @include opacity(0);
        position: relative;
        width: 100%;
        z-index: 2;
        -webkit-appearance: menulist-button;
        -moz-appearance: none;
    }

    &--focused {
        outline: none;
        box-shadow: $field-outline;
    }

    &__value {
        @include apply-map($f-field);
        @include rem(14px);

        line-height: calc(34 / 16);
        border: 1px solid $c-border;
        text-align: left;
        color: $c-black;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        z-index: 1;
        vertical-align: middle;
        display: block;
        margin-bottom: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        padding: 4px 35px 4px 15px;
    }

    svg {
        display: block;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -2px;
        fill: $c-dark;
    }
}

.field-inline {
    font-size: 0;
}

.field.error,
.field--error,
.field-error {
    .input,
    .select-replace__value,
    select {
        border-color: $c-error;
    }
    p.error {
        color: $c-error;
        margin-top: 5px;
        @include rem(14px);
    }
}

.check-replace {
    display: inline-block;
    width: 26px;
    height: 26px;
    border: 2px solid #000;
    vertical-align: top;
    text-align: center;
    margin-right: 10px;
    svg {
        opacity: 0;
        width: 18px;
        height: 22px;
        display: inline-block;
    }
    &--active {
        svg {
            opacity: 1;
        }
    }
}

.radio-replace {

    input {
        display: none;
    }
}

.radio-replace__display {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    border: 1px solid $c-border;
    border-radius: 100%;
    cursor: pointer;

    :checked + &:after {
        content: '';
        display: block;
        background: $c-black;
        width: 10px;
        height: 10px;
        margin: 6px;
        border-radius: 100%;
    }
}

#quote_painting_ref {
    max-width: 50%;
    @include breakpoint(mobile) {
        max-width: 100%;
    }
}

.field__with-inline-button {
    display: flex;

    button span {
        white-space: pre;
    }
}

.field__optional {
    @include font(14px, 16px);
    color: $c-block-copy;
    font-weight: 400;
    &:before {
        content: '(';
    }

    &:after {
        content: ')';
    }
}
