@use "sass:math";

.get-inspired {
    display: grid;
    grid-template-columns: 8.33%  8.33%  8.33%  8.33%  8.33%  8.33%  8.33%  8.33%  8.33%  8.33%  8.33%  8.33%;
    grid-template-rows: 1fr 1fr 1fr 1fr 45px;
    margin-bottom: 120px;
    position: relative;

    @include breakpoint(tablet) {
        display: block;
        margin-bottom: 60px;
    }
}

    .get-inspired__carousel {
        grid-column: 1/8;
        grid-row: 1/5;
        padding-bottom: 40px;
        margin-bottom: 20px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background: $c-nav-border;

            @include breakpoint(tablet) {
                right: 0;
            }
        }

        @include breakpoint(tablet) {
            padding-bottom: 0;
        }
    }

        .get-inspired__container {
            height: 100%;
        }
            .get-inspired__item {
                position: relative;
                display: flex;
                flex-direction: column;
                height: 100%;
            }

                .get-inspired__img {
                    @include aspect(math.div(5, 3));

                    margin-bottom: 40px;
                    padding-right: 40px;

                    img {
                        @include coverImage;
                    }

                    @include breakpoint(tablet) {
                        margin-bottom: 20px;
                        padding-right: 20px;
                    }
                }

                .get-inspired__img--small {
                    @include aspect(1);
                    width: 36%;

                    position: absolute;
                    padding: 0;
                    margin: 0;
                    bottom: 0;
                    right: 0;

                    img {
                        max-width: 100%;
                    }

                    @include breakpoint(tablet) {
                        bottom: initial;
                        top: 170px;
                    }

                    @include breakpoint(phablet) {
                        top: 120px;
                    }

                    @include breakpoint(small-mobile) {
                        top: 90px;
                    }
                }

                .get-inspired__content {
                    display: block;
                    width: 62%;
                    text-decoration: none;

                    @include breakpoint(tablet) {
                        width: 100%;
                        margin-bottom: 40px;
                    }
                }

                    .get-inspired__title {
                        @include apply-map($f-title);
                        @include rem(28px);

                        line-height: 34px;
                        padding-right: 60px;
                        margin-bottom: 10px;
                        color: $c-dark-grey;
                        transition: color .3s ease;

                        .get-inspired__content:hover & {
                            color: $c-block-copy;
                        }

                        @include breakpoint(tablet) {
                            @include rem(24px);

                            line-height: 29px;
                            max-width: none;
                        }
                    }

                    .get-inspired__copy {
                        @include apply-map($f-primary);
                        @include rem(14px);

                        line-height: 22px;
                        color: $c-block-copy;
                    }

        .get-inspired__article-container {
            grid-column: 8/13;
            grid-row: 1/5;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 40px;
            margin: 0 0 20px 40px;
            border-bottom: 1px solid $c-nav-border;

            @include breakpoint(laptop) {
                margin: 0 0 20px 20px;
            }

            @include breakpoint(tablet) {
                margin: 0 0 20px 0;
            }
        }

            .get-inspired__article {

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }

                .get-inspired__article-link {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                }

                    .get-inspired__article-thumbnail {
                        @include aspect(math.div(13, 8));

                        flex-basis: calc(40% + 20px);

                        @include breakpoint(tablet) {
                            flex-basis: calc((2/6) * 100%);
                        }

                        img {
                            max-width: 100%;
                            transition: transform 3s linear;
                            transform: scale(1.01);

                            .get-inspired__article-link:hover & {
                                transform: scale(1.1);
                            }
                        }
                    }

                    .get-inspired__article-content {
                        flex-basis: calc(60% - 20px);
                        display: flex;
                        flex-direction: column;
                        padding-left: 20px;

                        @include breakpoint(tablet) {
                            flex-basis: calc((4/6) * 100%);
                            padding-left: 15px;
                        }
                    }

                        .get-inspired__article-title {
                            @include apply-map($f-title);
                            @include rem(20px);

                            line-height: 22px;
                            color: $c-dark-grey;
                            transition: color .3s ease;
                            margin-bottom: 10px;

                            .get-inspired__article-link:hover & {
                                color: $c-block-copy
                            }

                            @include breakpoint(tablet) {
                                @include rem(16px);

                                margin-bottom: 5px;
                            }
                        }

                        .get-inspired__article-subtitle {
                            @include apply-map($f-primary);
                            @include rem(12px);

                            line-height: 18px;
                            letter-spacing: 2.5px;
                            color: $c-block-copy;
                            text-transform: uppercase;
                        }

    .get-inspired__carousel-cta {
        grid-row: 5/6;
        grid-column: 1/2;

        @include breakpoint(tablet) {
            margin-bottom: 60px;
        }
    }

    .get-inspired__blog-cta {
        grid-row: 5/6;
        grid-column: 8/10;
        margin-left: 40px;

        @include breakpoint(laptop) {
            margin-left: 20px;
        }

        @include breakpoint(tablet) {
            margin-left: 0;
        }
    }
