.block--quad-callout {
    padding: 0;
    margin: 60px 0;

    @include breakpoint(tablet) {
        margin: 20px 0;
    }

    .block__title {
        @include font(40px, 40px);

        margin-top: 0;
        margin-bottom: 10px;

        @include breakpoint(tablet) {
            @include font(25px, 25px);

            text-align: left;
        }
    }

    .block__intro {
        @include font(16px, 26px);

        text-align: center;
        max-width: $tab-max-width;
        margin: 0 auto 25px;

        @include breakpoint(tablet) {
            @include font(14px, 24px);

            text-align: left;
            margin-bottom: 20px;
        } 
    }

    .block__grid {
        padding: 0 40px;
        display: flex;
        column-gap: 80px;

        & > * {
            flex: 1;
        }

        @include breakpoint(tablet) {
            padding: 0;
            display: flex;
            overflow-x: scroll;
            scrollbar-width: none;
            gap: 20px;
            margin-right: calc(((100vw - $tab-max-width) / -2) - $site-gutter-mobile);
            margin-left: calc(((100vw - $tab-max-width) / -2) - $site-gutter-mobile);

            & > * {
                flex: 0 0 40%;
                scroll-snap-align: start; 
            }

            &::-webkit-scrollbar {
                display: none;
            }

            li:first-child {
                margin-left: calc(((100vw - $tab-max-width) / 2) + $site-gutter-mobile);
            }
        }

        @include breakpoint(phablet) {
            margin-right: -$site-gutter-mobile;
            margin-left: -$site-gutter-mobile;

            & > * {
                flex: 0 0 60%;
            }

            li:first-child {
                margin-left: $site-gutter-mobile;
            }
        }
    }

        .block__image {
            aspect-ratio: 15 / 16;
            margin-bottom: 10px;
            width: 100%;
        }

        .block__item-title {
            @include apply-map($f-title);
            @include font(30px, 36px);

            text-align: center;
        }
}
