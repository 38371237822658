.no-route {
    margin-top: 70px;
    padding-bottom: 30px;
}

    .no-route__title {
        @include large-title;
        text-align: center;
    }

    .no-route__intro {
        @include rem(24px);
        line-height: calc(31 / 24);
        color: $c-introcopy;
        text-align: center;
        max-width: 750px;
        margin: 30px auto;
    }

    .no-route__search {
        max-width: 580px;
        margin: 0 auto;
        background-color: $c-background;
        padding: 0 20px;

        .input.search-form__field {
            height: 72px;
        }

        .search-form__field-wrapper svg {
            top: 50%;
            transform: translateY(-50%);
        }
    }
