.paint-item {
    display: none;
    position: absolute;
    width: 100%;
    max-width: 300px;
    padding: 20px;
    background: $c-white;
    border: 1px solid $c-black;

    &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: $c-white;
    }
}

.paint-item--active {
    display: block;
}

.paint-item--right {
    margin-left: -15px;

    &:after {
        right: -1px;
        border-right: 1px solid $c-black;
        border-bottom: 1px solid $c-black;
        transform: rotate(-45deg);
        transform-origin: 100% 0;
    }
}

.paint-item--top.paint-item--right {
    transform: translate(-100%, -35px);

    &:after {
        top: 20px;
    }
}

.paint-item--bottom.paint-item--right {
    margin-top: 27px;
    transform: translate(-100%, -100%);

    &:after {
        bottom: 20px;
    }
}

.paint-item--left {

    &:after {
        left: -1px;
        border-bottom: 1px solid $c-black;
        border-left: 1px solid $c-black;
        transform: rotate(45deg);
        transform-origin: 0 0;
    }
}

.paint-item--top.paint-item--left {
    transform: translate(15px, -35px);

    &:after {
        top: 20px;
    }
}

.paint-item--bottom.paint-item--left {
    margin-top: 27px;
    transform: translate(15px, -100%);

    &:after {
        bottom: 20px;
    }
}

    .paint-item__title {
        @include apply-map($f-title);
        @include rem(18px);

        line-height: calc(22 / 18);
    }

    .paint-item__location {
        @include rem(14px);

        line-height: calc(17 / 14);
        margin-bottom: 13px;
        color: $c-brand;
        text-transform: uppercase;
    }

    .paint-item__text {
        @include rem(14px);

        line-height: calc(20 / 14);
        margin-bottom: 16px;
    }

    .paint-item__url {
        @include rem(14px);

        line-height: calc(17 / 14);
        font-weight: 700;
    }
