////
/// Responsive grid
///
/// @author Dom Morgan <dom@d3r.com>

// This is done in the sizings partial now.
// @import '../../../../core/resources/sass/grid';

/// Should the gutter defaults switch by breakpoint or
/// it be done here?
/// @author Dom Morgan <dom@d3r.com>
@mixin grid-wrapper {
    @include grid-gutter;
    @include remove-white-space;
    display: block;
}

@mixin grid-item {
    display: inline-block;
    vertical-align: top;
    // & > :last-child {
    //     &:not(.grid) {
    //         margin-bottom: 0;
    //     }
    // }

}

.grid {
    @include grid-wrapper;

    > * {
        @include grid-item;
    }
}

// Flow items right to left
.grid--reverse {
    direction: rtl;

    > * {
        direction: ltr;
    }
}

.grid--ph-reverse {
    @include breakpoint(phablet) {
        direction: rtl;

        > * {
            direction: ltr;
        }
    }
}

.grid--left {
    text-align: left;
}
.grid--center {
    text-align: center;
}
.grid--right {
    text-align: right;
}

// Vertically top
.grid--top {
    > * {
        vertical-align: top;
    }
}
// Vertically centered
.grid--middle {
    > * {
        vertical-align: middle;
    }
}
// Vertically bottom
.grid--bottom {
    > * {
        vertical-align: bottom;
    }
}

.grid--float {
    @include clearfix;

    > * {
        float: left;
    }
}

.grid--tight {
    @include grid-gutter($grid-gutter-h--tight, $grid-gutter-v--tight);
}
.grid--semi-tight {
    @include grid-gutter($grid-gutter-h--semi-tight, $grid-gutter-v--semi-tight);
}
.grid--semi-tight--tight {
    @include grid-gutter($grid-gutter-h--semi-tight, $grid-gutter-v--semi-tight);

    @include breakpoint(phablet) {
        @include grid-gutter($grid-gutter-h--tight, $grid-gutter-v--tight);
    }
}
.grid--tightish {
    @include grid-gutter($grid-gutter-h--tightish, $grid-gutter-v--tightish);

    @include breakpoint(tablet) {
        @include grid-gutter($grid-gutter-h--15, $grid-gutter-v--15);
    }
}
.grid--loose {
    @include grid-gutter($grid-gutter-h--loose, $grid-gutter-v--loose);
}
.grid--semi-loose {
    @include grid-gutter($grid-gutter-h--semi-loose, $grid-gutter-v--semi-loose);
}
.grid--looser {
    @include grid-gutter($grid-gutter-h--looser, $grid-gutter-v--looser);
}
.grid--looser-squeeze {
    @include breakpoint(nav-squeeze) {
        @include grid-gutter($grid-gutter-h--looser-squeeze, $grid-gutter-v--looser-squeeze);
    }
}
.grid--extra-loose {
    @include grid-gutter($grid-gutter-h--extra-loose, $grid-gutter-v--extra-loose);
}
.grid--v-med {
    @include grid-gutter($grid-gutter-h, 20px);
}
.grid--h-med {
    @include grid-gutter(20px, $grid-gutter-v);
}
.grid--med {
    @include grid-gutter(20px, 20px);
}
.grid--flush {
    @include grid-gutter(0, 0);
}
.grid--vflush {
    @include grid-gutter($grid-gutter-h, 0);
}
.grid--m-vflush {
    @include grid-gutter($grid-gutter-h, 0);
}
.grid--hflush {
    @include grid-gutter(0, $grid-gutter-v);
}
.grid--flush-t {
    @include breakpoint(tablet) {
        @include grid-gutter(0, 0);
    }
}

.grid--l-overflow-right-one-third {
    @media screen and (min-width: 1100px) {
        margin-right: calc(-1 * ((100% + #{$grid-gutter-h}) / 3) - #{$grid-gutter-h});
    }
}

.grid--l-overflow-left-one-third {
    @media screen and (min-width: 1100px) {
        margin-left: calc(-1 * 100% / 3);
    }
}

.grid--flush-bottom {
    // Appropriate negative margins applied via grid-gutter mixin
    overflow: hidden;
}

.grid--no-margin-bottom {
    // We need to actually remove the margins and not apply overflow:hidden (like .grid--flush-bottom). Applying overflow:hidden disables position:sticky on child elements

    > * {
        margin-bottom: 0;
    }
}

@each $name in map-keys($sizes) {
    @include breakpoint(map-get($sizes, $name)) {
        .#{$name}-one-eigth {
            width: calc(100% * 1 / 8);
        }
        .#{$name}-three-eighths {
            width: calc(100% * 3 / 8);
        }

        .#{$name}-shift-full,
        .#{$name}-shift-three-thirds,
        .#{$name}-shift-four-quarters,
        .#{$name}-shift-five-fifths,
        .#{$name}-shift-six-sixths,
        .#{$name}-shift-ten-tenths,
        .#{$name}-shift-twelve-twelfths {
            margin-left: 100%;
        }
        .#{$name}-shift-eleven-twelfths {
            margin-left: calc(100% * 11 / 12);
        }
        .#{$name}-shift-nine-tenths {
            margin-left: 90%;
        }
        .#{$name}-shift-five-sixths,
        .#{$name}-shift-ten-twelfths {
            margin-left: calc(100% * 5 / 6);
        }
        .#{$name}-shift-four-fifths,
        .#{$name}-shift-eight-tenths {
            margin-left: calc(100% * 4 / 5);
        }
        .#{$name}-shift-three-quarters,
        .#{$name}-shift-nine-twelfths {
            margin-left: calc(100% * 3 / 4);
        }
        .#{$name}-shift-seven-tenths {
            margin-left: calc(100% * 7 / 10);
        }
        .#{$name}-shift-two-thirds,
        .#{$name}-shift-four-sixths,
        .#{$name}-shift-eight-twelfths {
            margin-left: calc(100% * 2 / 3);
        }
        .#{$name}-shift-three-fifths,
        .#{$name}-shift-six-tenths {
            margin-left: calc(100% * 3 / 5);
        }
        .#{$name}-shift-seven-twelfths {
            margin-left: calc(100% * 7 / 12);
        }
        .#{$name}-shift-one-half,
        .#{$name}-shift-two-quarters,
        .#{$name}-shift-three-sixths,
        .#{$name}-shift-five-tenths {
            margin-left: 50%;
        }
        .#{$name}-shift-five-twelfths {
            margin-left: calc(100% * 5 / 12);
        }
        .#{$name}-shift-two-fifths,
        .#{$name}-shift-four-tenths {
            margin-left: calc(100% * 2 / 5);
        }
        .#{$name}-shift-one-third,
        .#{$name}-shift-two-sixths,
        .#{$name}-shift-four-twelfths {
            margin-left: calc(100% * 1 / 3);
        }
        .#{$name}-shift-three-tenths {
            margin-left: calc(100% * 3 / 10);
        }
        .#{$name}-shift-one-quarter,
        .#{$name}-shift-three-twelfths {
            margin-left: calc(100% * 1 / 4);
        }
        .#{$name}-shift-one-fifth,
        .#{$name}-shift-two-tenths {
            margin-left: calc(100% * 1 / 5);
        }
        .#{$name}-shift-one-sixth,
        .#{$name}-shift-two-twelfths {
            margin-left: calc(100% * 1 / 6);
        }
        .#{$name}-shift-one-tenth {
            margin-left: calc(100% * 1 / 10);
        }
        .#{$name}-shift-one-twelfth {
            margin-left: calc(100% * 1 / 12);
        }
        .#{$name}-shift-none {
            margin-left: 0;
        }
    }
}

.grid--flex {
    align-items: stretch; // too presumptuous
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    direction: ltr; // let flex-direction deal with this.

    &.grid--flex-nowrap {
        flex-wrap: nowrap;
    }

    &.grid--left {
        justify-content: flex-start;
    }
    &.grid--center {
        justify-content: center;
    }
    &.grid--right {
        justify-content: flex-end;
    }
    &.grid--reverse {
        flex-direction: row-reverse;
    }

    @include breakpoint(phablet) {
        &.grid--ph-reverse {
            flex-direction: row-reverse;

            > * {
                flex-direction: column;
            }
        }
    }
    &.grid--looser-squeeze {
        flex-wrap: nowrap;

        @include breakpoint(tablet) {
            flex-wrap: wrap;
        }

        .l-two-thirds {
            flex-shrink: 1;
        }

        .l-one-third {
            flex-basis: 480px;

            @include breakpoint(laptop) {
                flex-basis: 420px;
            }

            @include breakpoint(tablet) {
                flex-basis: initial;
            }
        }
    }

    // These might be superfluous.
    // Maybe flex should always stretch?
    &.grid--top {
        align-items: flex-start;
    }
    &.grid--middle {
        align-items: center;
    }
    &.grid--bottom {
        align-items: flex-end;
    }

    > * {
        display: flex;
        flex: none;
        flex-direction: column;

        // When background styling is on a child element,
        // stretch it up to fill the parent.
        > *:only-child:not(.product-grid):not(.product__content):not(.customise__confirmation) {
            flex: 1 0 auto;
        }
        // Images should never stretch
        > img:only-child {
            flex: none;
        }
    }
}


.grid--product-oneline {
    @include breakpoint (laptop) {
        > div:nth-child(6) {
            display: none;
        }
    }
    @include breakpoint (tablet) {
        > div:nth-child(5) {
            display: none;
        }
    }

    @include breakpoint (phablet) {
        > div:nth-child(5),
        > div:nth-child(6) {
            display: inline-block;
        }
    }
}

.grid--options {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 47px; // dependant on text line-height and vertical margin
        left: 50%;
        bottom: $grid-gutter-h;
        width: 1px;
        background: $c-border;
        transform: translateX(calc(0px - $grid-gutter-h / 2));

        @include breakpoint(mobile) {
            display: none;
        }
    }

    &.grid--tight {

        &:after {
            bottom: $grid-gutter-h--tight;
            transform: translateX(calc(0px - $grid-gutter-h--tight / 2));
        }

        > :first-child {

            &:after {
                margin-left: calc(0px - $grid-gutter-h--tight / 2);
            }
        }
    }

    &.grid--loose {

        &:after {
            bottom: $grid-gutter-h--loose;
            transform: translateX(calc(0px - $grid-gutter-h--loose / 2));
        }

        > :first-child {

            &:after {
                margin-left: calc(0px - $grid-gutter-h--loose / 2);
            }
        }
    }

    &.grid--extra-loose {

        &:after {
            bottom: $grid-gutter-h--extra-loose;
            transform: translateX(calc(0px - $grid-gutter-h--extra-loose / 2));
        }

        > :first-child {

            &:after {
                margin-left: calc(0px - $grid-gutter-h--extra-loose / 2);
            }
        }
    }

    &.grid--med {

        &:after {
            bottom: 20px;
        }
    }

    &.grid--h-med,
    &.grid--med {

        &:after {
            transform: translateX(calc(-20 / 2) * 1px);
        }

        > :first-child {

            &:after {
                margin-left: calc(-20 / 2) * 1px;
            }
        }
    }

    &.grid--flush,
    &.grid--hflush {

        &:after {
            bottom: 0;
            transform: none;
        }
    }

    > :first-child {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 22px; // dependant on text line-height
            left: 100%;
            width: 100px;
            height: 1px;
            background: $c-border;
            transform: translate(-100%, -11px); // dependant on half of the text line-height

            @include breakpoint(mobile) {
                display: none;
            }
        }

        &:after {
            @include rem(14px);

            line-height: calc(22 / 14);
            content: 'or';
            position: absolute;
            top: 0;
            left: 100%;
            margin-left: calc(0px - $grid-gutter-h / 2);
            padding: 0 10px;
            transform: translateX(-50%);
            text-transform: uppercase;
            background: $c-background;

            @include breakpoint(mobile) {
                display: none;
            }
        }
    }
}
