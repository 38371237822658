.block--twin-image-text {
    padding: 0;
    margin: 60px 0;

    @include breakpoint(tablet) {
        margin: 20px 0;
    }

    &.block--reverse {
        .block__grid {
            grid-template-columns: repeat(2, minmax(0, 1fr)) minmax(0, 40%);
            
            @include breakpoint(tablet) {
                grid-template-columns: 1fr;
            }
        }

        .block__content {
            padding-left: 30px;
            order: 1;

            @include breakpoint(tablet) {
                padding: 0;
            }
        }

        .block__image--underlap {
            margin-top: 0;
            margin-right: 0;

            & ~ .block__image {
                margin-top: 80px;
                margin-left: -30px;
            }
        }
    }

    .block__grid {
        display: grid;
        grid-template-columns: minmax(0, 40%) repeat(2, minmax(0, 1fr));

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
        }
    }

        .block__title {
            @include font(80px, 80px);

            margin-bottom: 45px;
            margin-top: 0;
            text-align: left;

            @include breakpoint(tablet) {
                @include font(40px, 40px);

                margin-bottom: 10px;
            }
        }

        .block__intro {
            @include font(20px, 32px);

            padding-right: 30px;
            color: $c-dark;
            margin: 0;

            @include breakpoint(tablet) {
                @include font(14px, 24px);

                padding: 0;
                margin-bottom: 15px;
            }
        }

    .block__image {
        aspect-ratio: 8 / 5;

        @include breakpoint(tablet) {
            &:last-child {
                display: none;
            }
        }
    }

    .block__image--underlap {
        margin-top: 80px;
        margin-right: -30px;

        @include breakpoint(tablet) {
            margin: 0;
        }
    }
}
