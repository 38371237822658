.product__sizes {
    margin-top: 10px;
}

.sizes__option {
    display: inline-block;
    @include rem(14px);
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    width: 33.33%;

    span {
        display: block;
        margin-right: 10px;
        padding: 6px;
        border: 1px solid $c-border;
    }
}

.sizes__option--active {
    span {
        border: 3px solid $c-brand;
    }
}

.sizes__title {
    @include smallcaps;
    @include rem(14px);

    margin: 0 0 5px;
    line-height: calc(19 / 16);
    color: $c-black;
}
