.wishlist-popup {
    background: $c-white;
    padding: 20px;

    @include breakpoint(tablet) {
        padding: $site-gutter-mobile;
    }
}

.wishlist-popup__content {
    padding: 40px;
    border: 1px solid $c-filter-grey;
    text-align: center;
}

.wishlist-popup__title {
    @include apply-map($f-title);
    @include font(32px, 38px);

    color: $c-dark;
    margin-bottom: 20px
}

.wishlist-popup__copy {
    @include font(14px, 22px);

    color: $c-block-copy;
    margin-bottom: 20px;
}

.wishlist-popup__action {
    display: flex;
    justify-content: center;
}

.wishlist-popup__url {
    border: 1px solid $c-filter-grey;
    padding: 15px;
    margin-right: 10px;
    overflow: scroll;
    max-width: 400px;

    p {
        @include font(14px, 16px);
        color: $c-dark;
        text-align: left;
        white-space: nowrap;
    }
}
