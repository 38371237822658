.block.block--walkthrough-steps {
    &.block--grey {
        padding: 80px 0 100px;
        background-color: $c-background;

        @include breakpoint(tablet) {
            padding: 60px 0;
        }
    }
  
    .block__title {
        @include font(36px, 43px);

        margin-top: 0;
        margin-bottom: 10px;

        @include breakpoint(tablet) {
            @include font(28px, 34px);
        }
    }

    .block__subtitle {
        @include font(16px, 22px);

        color: $c-richcopy;
        margin-bottom: 30px;
        text-align: center;

        @include breakpoint(tablet) {
            margin-bottom: 20px;
        }
    }

    .block__list {
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 40px;

        @include breakpoint(tablet) {
            text-align: left;
            grid-template-columns: 1fr;
            column-gap: 20px;
        }
    }

    .block__list--has-footer {
        margin-bottom: 40px;
        padding-bottom: 45px;

        @include breakpoint(tablet) {
            margin-bottom: 25px;
            padding-bottom: 40px;
        }

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 60px;
            height: 1px;
            background-color: $c-filter-grey;
        }
    }

        .block__item {
            text-align: center;

            @include breakpoint(tablet) {
                text-align: left;
                display: grid;
                grid-template-columns: auto 1fr;
                column-gap: 20px;
            }
        }

            .block__icon {
                position: relative;
                margin: 0 auto 25px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                width: 125px;
                height: 85px;

                @include breakpoint(tablet) {
                    width: 80px;
                    height: 54px;
                    grid-column: 1 / 2;
                    grid-row: 1 / 3;
                    margin: 0;
                    align-items: flex-start;
                }

                img {
                    width: 100%;
                    max-height: 85px;
                    object-fit: contain;

                    @include breakpoint(tablet) {
                        max-height: 54px;
                    }
                }
            }

            .block__arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: calc(-100% - 12.5px);

                @include breakpoint(tablet) {
                    display: none;
                }

                svg {
                    fill: $c-brand;
                }
            }

            .block__item-title {
                @include small-title;

                margin-bottom: 15px;

                @include breakpoint(tablet) {
                    @include font(20px, 24px);

                    grid-column: 2 / 3;
                    grid-row: 1 / 2;
                    margin-bottom: 5px;
                }
            }

            .block__body {
                @include font(14px, 22px);

                color: $c-richcopy;

                @include breakpoint(tablet) {
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                }
            }

    .block__footnote {
        @include font(14px, 22px);

        text-align: center;

        strong {
            @include font(16px, 24px);

            color: $c-dark;

            @include breakpoint(tablet) {
                display: block;
            }
        }
    }
}
