@use 'sass:math';

.customise {
    margin-top: 10px;

    .product__pieces {
        margin-top: 0;
    }
}

.customise__popup {
    .mfp-wrap--lock-scroll {
        @include breakpoint(tablet) {
            overflow: initial !important;
        }

        .mfp-content {
            @include breakpoint(tablet) {
                overflow: initial;
            }
        }
    }
    .mfp-container {
        padding: 0;
    }

    .mfp-content {
        height: 100%;
        overflow: auto;
        background: $c-white;
    }

    .mfp-preloader {
        z-index: 1050;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
    }

    .mfp-close {
        right: 55px;
        top: 30px;

        @include breakpoint(mobile) {
            top: 10px;
            right: 0;
        }

        svg {
            pointer-events: none;
        }
    }

    .customise__stage {
        height: 100%;

        &.customise__stage__overlay--active {
            .customise__sticky--filters {
                z-index: 0;
            }
        }
    }

    .customise__form {
        height: 100%;
        display: flex;
    }

    .customise__close-wrap {
        width: 100%;
        height: $custom-header;
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 20;
        margin-left: 0;
        transform: translateX(-50%);

        @include breakpoint(tablet) {
            height: 59px;
        }
    }

    .customise__close {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 15px;

        @include breakpoint(phablet) {
            right: 1px;
            top: 50%;
            transform: translateY(-50%);
        }

        svg {
            pointer-events: none;
            height: 15px;
            width: 15px;
        }
    }
}

body[data-scroll-lock-locked="true"] {

    .customise__close-wrap {
        display: none;
    }
}

.customise__sticky--filters {
    top: $custom-header;
    padding-top: 5px;
    background: $c-white;

    @include breakpoint(tablet) {
        top: 0;
    }

    .body--admin &{
        top: $custom-header;

        @include breakpoint(tablet) {
            top: 0;
        }
    }

    .body--debug.body--admin & {
        top: $custom-header;

        @include breakpoint(tablet) {
            top: 0;
        }
    }

    .body--debug.body--admin & {
        top: $custom-header;

        @include breakpoint(tablet) {
            top: 0;
        }
    }
}

.customise__grid {
    display: grid;
    grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
    margin-top: $custom-header;
    height: 100%;

    @include breakpoint(nav) {
        grid-template-columns: 1fr;
        height: auto;
    }

    @include breakpoint(tablet) {
        margin-top: 0;
    }
}

.customise__2-col {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 33px;
    row-gap: 20px;

    @include breakpoint(tablet) {
        column-gap: 17px;
    }
}

    .customise__2-col-text {
        display: flex;
        flex-direction: column;
    }

.customise__options {
    position: relative;
    padding: 49px 80px 40px 0;
    background: $c-white;

    @include breakpoint(nav) {
        padding: 70px 0 20px;
    }
}

.customise__logo {
    z-index: 9999;
    position: fixed;
    min-height: $custom-header;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $c-white;
    padding: 25px 0;
    border-bottom: 1px solid $c-nav-border;

    @include breakpoint(tablet) {
        padding: 17px 0;
        min-height: 0;
        border: none;
    }

    svg {
        fill: $c-dark;

        @include breakpoint(nav) {
            width: 240px;
        }

        @include breakpoint(mobile) {
            width: 180px;
        }
    }
}

.customise__title {
    @include apply-map($f-title);
    @include font(32px, 38px);

    color: $c-dark;

    @include breakpoint(tablet) {
        @include font(24px, 29px);

        display: block;
    }
}

.customise__no-fabrics-available {
    display: none;
}

.customise__viewing {
    @include font(12px, 26px);

    color: $c-block-copy;
    margin: 10px 0 20px;

    @include breakpoint(tablet) {
        margin-bottom: 5px;
    }
}

.customise__sub-title {
    @include apply-map($f-title);
    @include font(24px, 29px);

    display: flex;
    gap: 20px;
    align-items: baseline;

    @include breakpoint(tablet) {
        gap: 15px;
    }

    span {
        @include apply-map($f-primary);
        @include font(18px, 21px);

        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.25px;

        @include breakpoint(tablet) {
            @include font(14px, 16px);
        }
    }
}

.customise__blurb {
    @include apply-map($f-primary);
    @include font(14px, 22px);

    color: $c-block-copy;
    margin-top: 3px;
}

.customise__filters {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $c-filter-grey;

    &.grid--tight {

        .filterable__dimension {
            @include breakpoint(mobile) {
                margin: 0;
            }
        }
    }
    .filterable__dimension {
        width: 50%;
        padding: 10px 15px 15px 0;
        display: inline-block;
        margin-left: 0;

        @include breakpoint(tablet) {
            padding-bottom: 10px;
        }

        &.filterable__dimension--custom-fabric-type,
        &.filterable__dimension--fabric-colour {
            max-width: 90px;
            margin: 0;
            padding-bottom: 10px;
            z-index: 100;
            top: 100%;

            @include breakpoint(phablet) {
                position: static;
            }
        }

        &.filterable__dimension--custom-fabric-type::after {
            left: 10px;
        }

        &.filterable__dimension--fabric-colour::after {
            right: 25px;
        }
    }

    label {
        @include apply-map($f-primary);
        @include font(14px, 26px);

        color: $c-dark;
        text-transform: capitalize;
    }

    .customise__clear-all {
        @include font(12px, 13px);

        margin-left: auto;
        display: none;
        text-decoration: underline;
        cursor: pointer;
        transition: color .2s ease;

        @include breakpoint(nav) {
            margin-right: 10px;
        }

        &:hover {
            color: $c-block-copy;
        }
    }
}

.customise__category {
    margin: 15px 0 30px;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 15px;

    @include breakpoint(phablet) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.customise__text {
    @include apply-map($f-primary);
    @include font(14px, 16px);

    font-weight: 500;
}

.customise__text--cover {
    position: absolute;
    top: 10px;
    left: 15px;
    z-index: 10;
    text-align: left;
}

.customise__text--size {
}

.customise__text--border {
    padding-bottom: 15px;
    border-bottom: 1px solid $c-filter-grey;
}

.customise__choice-panel {
    position: relative;
}

    .customise__choice-panel-title {
        @include apply-map($f-title);
        @include font(24px, 29px);
    }

.customise__img-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 10px;
    z-index: 10;
    margin-right: 150px; // 150px is the width of the 360 icon and text

    @include breakpoint(tablet) {
        position: relative;
        left: unset;
        top: unset;
        margin-bottom: 20px;
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint(phablet) {
        padding-left: $site-gutter-mobile;
    }
}

.customise__thumbnail {
    @include swatch-hover;

    position: relative;
    margin-bottom: 5px;

    .js-customise__option:checked + label & {
        @include swatch-active;
    }
}

.customise__thumbnail--noborder {
    border: 1px solid transparent;
}

    .customise__360 {
        position: absolute;
        top: 4px;
        right: 7px;

        svg {
            width: 30px;
        }

        .js-customise__option:checked + label & {
            display: none;
        }
    }

.customise__name {
    @include apply-map($f-title);
    @include font(18px, 22px);
    color: $c-block-copy;
    text-align: center;
    display: block;
    margin-top: 10px;

    .customise__option:hover &,
    .js-customise__option:checked + label & {
        color: $c-dark;
    }
}

.customise__name--cylindo {
    padding: 0 15px;
}

.customise__name {
    @include apply-map($f-title);
    @include font(20px, 24px);

    color: $c-dark;
}

.customise__name--small {
    @include font(16px, 16px);

    margin-bottom: 5px;
}

.customise__desc {
    @include font(18px, 26px);

    color: $c-dark;
    font-weight: 500;
    letter-spacing: 0.25px;
    margin-bottom: 3px;
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    @include breakpoint(tablet) {
        @include font(16px, 22px);
    }
}

.customise__lower-desc {
    display: none;

    @include breakpoint(tablet) {
        @include font(14px, 22px);

        display: block;
        color: $c-block-copy
    }
}

// buttons for the confirm selection stage of the overlay on mobile

.customise__buttons {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    align-self: center;

    @include breakpoint(tablet) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $c-white;
        padding: 0 15px;
        min-height: 65px;
        margin: 0;
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow:  0px -5px 10px 0px rgba($c-black, 0.1);

        &:nth-child(2) {
            flex: 1 0 auto;
        }
    }
}

.customise__price {
    @include apply-map($f-primary);
    @include font(14px, 26px);

    color: $c-block-copy;
    letter-spacing:0.5px;
}

.customise__option {
    position: relative;

    input {
        display: none;
    }

    label {
        display: block;
        text-align: center;
        cursor: pointer;

        .check-replace {
            display: none
        }
    }
}

    .customise__option-title {
        @include font(16px, 26px);

        font-weight: 500;

        @include breakpoint(tablet) {
            @include font(14px, 20px);

            letter-spacing: 0.25px;
        }
    }

    .customise__option-price {
        letter-spacing: 0.5;

        @include breakpoint(tablet) {
            @include font(12px, 26px);

            letter-spacing: 0.25px;
        }
    }

    .customise__dimension {
        @include font(12px, 26px);

        position: absolute;
        right: 20px;
        bottom: 10px;
        color: $c-richcopy;

        @include breakpoint(tablet) {
            right: 15px;
            bottom: 5px;
        }

        span {
            font-weight: 500;
            color: $c-dark;
        }
    }

.customise__option--large {
    margin-bottom: 20px;
}

.customise__confirmation {
    padding: 45px 0 50px 80px;
    align-self: flex-start;
    position: relative;
    height: 100%;

    @include breakpoint(tablet) {
        padding: 0;
        z-index: 10;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: calc((100vw - $site-max-width-new + (2 * $site-gutter-new)) / -2);
        background: $c-product-background;
        z-index: -1;

        @include breakpoint(wrap) {
            width: calc(100% + 80px);
        }

        @include breakpoint(nav) {
            left: calc(((100vw - 100%)/ 2) * -1);
            right: calc(((100vw - 100%)/ 2) * -1);
            width: calc(100% + (100vw - 100%))
        }

        @include breakpoint(phablet) {
            width: 100%;
            left: 0;
            right: 0;
        }
    }

    .customise__thumbnail {
        position: relative;
    }

    .customise__panel-meta {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        margin: 0 15px 0 10px;

        @include breakpoint(nav-squeeze) {
            margin: 0 5px;
        }

        @include breakpoint(nav) {
            margin: 0 15px 0 10px;
            justify-content: flex-start;
        }

        @include breakpoint(tiny) {
            margin: 0 5px;
        }
    }
}
.customise__selected-thumb {
    position: relative;

    @include breakpoint(tablet) {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
    }
}

.customise__top-section {
    margin-bottom: 20px;
    position: sticky;
    top: $custom-header;

    @include breakpoint(nav) {
        margin-bottom: 20px;
    }

    @include breakpoint(tablet) {
        display: none;
        position: fixed;
        z-index: 50;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: scroll;
        background: $c-white;
        padding-top: 66px;
        padding-bottom: 100px;
    }
}

.customise__top-section--active {
    display: block;
}

.slider-wrapper {
    padding: 21px 0;
}

.customise__show-content {
    display: block;
    width: 100%;
    margin: 0 auto 3px;

    &:last-of-type {
        margin: 0 auto 15px;
    }
}

.customise__selection-title {
    @include apply-map($f-primary);
    @include font(12px, 26px);

    color: $c-block-copy;
    letter-spacing: 2.5px;
    text-align: left;
    text-transform: uppercase;
}

.customise__content {
    @include breakpoint(tablet) {
        max-width: 720px;
        margin: 0 auto;
        padding: 15px 0 25px;
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 20px;
        align-items: flex-start;
    }

    @include breakpoint(phablet) {
        padding-left: $site-gutter-mobile;
        padding-right: $site-gutter-mobile;
    }
}

.customise__price-info {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px 0;
    border-top: 1px solid $c-filter-grey;
    border-bottom: 1px solid $c-filter-grey;

    @include breakpoint(tablet) {
        grid-column: 1 / 2;
        grid-template-columns: 1fr;
        border: none;
        padding: 0;
        margin: 0;
    }
}

.customise__price-info--no-border-bottom {
    border-bottom: none;
}

    .customise__selection-text {
        @include font(12px, 24px);

        letter-spacing: 2.5px;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $c-mid-grey;
    }

.customise__swatch-info {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 20px;

    @include breakpoint(tablet) {
        grid-column: 2 / 3;
        gap: 0;
    }
}

.customise__action {
    @include apply-map($f-primary);
    @include font(16px, 26px);

    color: $c-dark;
    font-weight: 500;
    transition: color .2s ease;
    text-decoration: underline;
    display: inline-flex;
    align-items: center;

    svg {
        width: 17px;
        fill: $c-brand;
        margin-right: 10px;

        @include breakpoint(tiny) {
            margin-right: 5px;
        }
    }

    &:hover {
        color: $c-block-copy;
    }
}

.customise__action--swatch-info {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    align-items: center;

    svg {
        margin-right: 15px;
        margin-top: 3px;
        height: 17px;
    }
}

.customise__action--working {
    color: lighten($c-richcopy, 20%);
    &:after {
        background-color: transparent;
    }
}

.customise__action--done {
    &:after {
        background-color: transparent;
    }
}

.customise__action--tooltip {
    grid-column: 2 / 3;
    grid-row: 2 / 3;

    @include breakpoint(tablet) {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }

    svg {
        width: 21px;
        margin-top: 3px;
    }
}

.customise__action--tooltip-desktop {
    @include breakpoint(tablet) {
        display: none
    }
}

.customise__action--tooltip-tablet {
    display: none;

    @include breakpoint(tablet) {
        display: flex;
    }
}

.customise__panel-edit {
    @include rem(14px);
    text-decoration: none;
    color: $c-label;
    font-weight: 100;
    @include lightondark;
    position: absolute;
    right: 0;
    bottom: 0;
    svg {
        max-height: 16px;
    }
}

.customise__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        flex-grow: 1;
    }
}
.customise__selected-price {
    @include apply-map($f-primary);
    @include font(18px, 21px);

    color: $c-dark;
    letter-spacing: 0.25px;
    grid-row: 2 / 3;
    grid-column: 1 / 2;

    @include breakpoint(tablet) {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        margin-bottom: 5px;
    }
}


.customise__selected-frame {
    @include apply-map($f-title);
    @include font(16px, 16px);

    color: $c-dark;
}

.customise__carousel {
    margin: 0 auto;
    width: 100%;

    .grid {
        margin-right: 0;
    }

    .slick-slide {
        padding-right: 10px;
    }

    .slick-track {
        align-items: start;
    }
}

.customise__selected-swatch {
    @include aspect(math.div(4, 3));
    @include swatch-active;

    grid-row: 1 / 3;
    grid-column: 1 / 2;
    position: relative;
    background-size: cover;
    width: 85px;

    @include breakpoint(tablet) {
        width: 105px;
    }
}

.customise__noimage {
    @include font(14px, 26px);

    background: $c-white;
    border: $c-border 1px solid;
    text-align: left;
    padding: 9px 15px;
}

.customise__note {
    color: $c-brand;
    font-weight: 700;
}

.customise__view-button {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 10px;
    background-color: $c-white;
    margin-top: 25px;
    box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.25);
}

.customise__return-links {
    padding: 10px 0;

    .customise__return-links__toswatch {
        text-align: left;
    }

    .customise__return-links__totop {
        text-align: right;

        svg {
            fill: $c-richcopy;
        }
    }
}

.customise__showmobile {
    display: none;

    @include breakpoint(phablet) {
        display: block;
    }
}

.customise__cylindo {
    position: relative;
}
