@use "sass:math";

.pdp-popup {
    padding: 40px;
    height: 100%;
    overflow: auto;

    @include breakpoint(phablet) {
        padding: 20px 15px;
    }

    p:not(.pdp-popup__tag) {
        @include font(14px, 22px);

        color: $c-richcopy;
    }
}

    .pdp-popup__title {
        @include apply-map($f-title);
        @include font(28px, 34px);

        color: $c-dark-grey;
        margin-bottom: 15px;

        @include breakpoint(tablet) {
            @include font(24px, 29px);
        }
    }

    .pdp-popup__title--small {
        @include font(20px, 24px);
    }

    .pdp-popup__title--inline {
        display: inline-block;
    }

    .pdp-popup__subtitle {
        @include font(16px, 24px);

        font-weight: 500;
    }

    .pdp-popup__padding-top {
        padding-top: 20px;
    }

    .pdp-popup__features {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 25px 0 5px;
    }

        .pdp-popup__features-item {
            display: grid;
            column-gap: 30px;
            row-gap: 5px;
            grid-template-columns: auto 1fr;
            align-items: center;
        }

            .pdp-popup__features-icon {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                grid-row: 1 / 3;

                svg {
                    width: 100%;
                    height: 100%
                }
            }

            .pdp-popup__features-subtitle {
                @include font(16px, 24px);

                font-weight: 500;
                align-self: flex-end;
            }

            .pdp-popup__features-body {
                @include font(14px, 22px);

                color: $c-richcopy;
                align-self: flex-start;
            }

    .pdp-popup__tag {
        @include semiboldText;
        @include font(14px, 26px);

        display: inline-block;
        color: $c-block-copy;
        margin-left: 15px;
    }

    .pdp-popup__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3px;
    }

    .pdp-popup__body {
        @include font(14px, 22px);

        color: $c-richcopy;
    }

    .pdp-popup__body--grey {
        color: $c-block-copy;
    }

    .pdp-popup__body--bold {
        @include semiboldText;
    }

    .pdp-popup__image {
        @include aspect(math.div(5, 3));

        margin-bottom: 20px;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .pdp-popup__dimensions {
        display: flex;

        input.invalid {
            border: 1px solid $c-cross-red;
        }
    }

    .pdp-popup__checkbox {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 15px;

        input[type="checkbox"] {
            width: 26px;
            height: 26px;
            display: inline-block;
            border: 1px solid $c-border;
            transition: outline 150ms ease-in;

            &:checked {
                border-color: $c-dark;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 20px 20px;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
            }
        }

        &:has(input:checked) + .pdp-popup__dimensions input {
            background: $c-filter-grey;
            cursor: not-allowed;
            pointer-events: none;
        }
    }

        .pdp-popup__checkbox-label {
            @include font(14px, 26px);

            color: $c-dark-grey;
        }

    .pdp-popup__summary {
        margin-bottom: 20px;
    }

        .pdp-popup__table {
            table-layout: auto;
            border-collapse: collapse;
            width: 100%;
            padding: 15px 20px;
            background: $c-background;

            td {
                @include font(14px, 22px);

                padding: 10px;
                font-weight: 700;
                color: $c-dark-grey;

                a {
                    font-weight: 400;
                }

                span {
                    font-weight: 400;
                    color: $c-block-copy;
                }
            }
        }

    .pdp-popup__results {
        margin-bottom: 20px;
    }

        .pdp-popup__radio {
            border: 1px solid $c-filter-grey;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .pdp-popup__radio--active {
            border-color: $c-black;
        }
