.header-search {
    @include breakpoint(nav) {
        display: none;
    }

    .search__form {
        padding: 0;
        padding-right: 20px;
        background-color: transparent;
    }

        .search__fieldset {
            padding: 0;
            border: none;
            max-width: 210px;
            max-height: 35px;
            width: 100%;
            background-color: $c-light-grey;
            border-radius: 50px;
            padding: 10px 15px;
        }

            .search__input {
                all: unset;
                background-color: $c-light-grey;
                width: 90%;
                padding-right: 5px;

                color: $c-black;

                &::placeholder {
                    @include rem(12px);
                    line-height: normal;
                    color: $c-black;
                }
            }

            .search__submit {
                all: unset;
                width: 10%;
                cursor: pointer;
            }
}
