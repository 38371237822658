@use "sass:math";

.corner-cta {
    position: relative;
    margin-bottom: 80px;

    @include breakpoint(tablet) {
        margin-bottom: 50px
    }

    &:last-child {
        margin-bottom: 120px;

        @include breakpoint(tablet) {
            margin-bottom: 50px;
        }
    }
}

    .corner-cta__img {
        @include aspect(2);

        position: relative;

        img {
            @include objectFitImage;

        }

        @include breakpoint(laptop) {
            @include aspect(math.div(4, 3));
        }

        @include breakpoint(tablet) {
            @include aspect(math.div(5, 3));

            margin-bottom: 30px;
        }

        @include breakpoint(phablet) {
            @include aspect(1);
        }
    }

    .corner-cta__overlay {
        position: absolute;
        width: 200px;
        height: 200px;
        top: 0;
        right: 0;
        border-left: 20px solid $c-white;
        border-bottom: 20px solid $c-white;

        @include breakpoint(phablet) {
            display: none
        }
    }

        .corner-cta__overlay-img {
            @include aspect(1);
            position: relative;

            img {
                @include coverImage;
            }
        }

        .corner-cta__overlay-content {
            position: absolute;
            left: 20px;
            bottom: 10px;
        }

            .corner-cta__overlay-title {
                @include apply-map($f-title);
                @include rem(20px);

                line-height: 24px;
                color: $c-white;
            }

            .corner-cta__overlay-subtitle {
                @include apply-map($f-primary);
                @include rem(12px);

                line-height: 26px;
                letter-spacing: 2.5px;
                color: $c-white;
                text-transform: uppercase;
            }

    .corner-cta__header {
        position: absolute;
        left: 100px;
        top: 125px;

        @include breakpoint(tablet) {
            left: 25px;
            right: 15px;
            top: 70px
        }
    }

        .corner-cta__title {
            @include apply-map($f-primary);
            @include rem(34px);

            font-weight: 300;
            line-height: 56px;
            color: $c-dark-grey;
            margin-bottom: 10px;

            span {
                font-weight: 500;
            }

            @include breakpoint(tablet) {
                @include rem(28px);

                line-height: 36px;
                margin-bottom: 15px;
            }
        }

        .corner-cta__subtitle {
            @include apply-map($f-title);
            @include rem(16px);

            line-height: 19px;
            color: $c-dark-grey;
        }

        .corner-cta__quotes {
            position: absolute;
            top: -40px;
            left: -65px;

            @include breakpoint(tablet) {
                left: -10px;
                top: -90px;
            }

            svg {
                width: 231px;
                fill: $c-dark-grey;
                opacity: 0.05;

                @include breakpoint(tablet) {
                    width: 133px;
                }
            }
        }

    .corner-cta__content {
        position: absolute;
        width: 36%;
        bottom: 0;
        right: 0;
        background: $c-white;
        padding: 60px 0 0 60px;

        @include breakpoint(laptop) {
            width: 40%;
        }

        @include breakpoint(tablet) {
            padding: 0;
            position: relative;
            width: 100%;
        }

        @include breakpoint(phablet) {
            padding: 0 15px;
        }
    }

    .corner-cta__content--left {
        right: initial;
        left: 0;
        padding: 60px 60px 0 0px;

        @include breakpoint(tablet) {
            padding: 0;
        }

        @include breakpoint(phablet) {
            padding: 0 15px;
        }
    }

        .corner-cta__content-title {
            @include apply-map($f-title);
            @include rem(36px);

            line-height: 43px;
            color: $c-dark-grey;
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                @include rem(32px);

                line-height: 38px;
                margin-bottom: 10px;
            }
        }

        .corner-cta__copy {
            @include apply-map($f-primary);
            @include rem(16px);

            line-height: 26px;
            margin-bottom: 25px;
            color: $c-block-copy;

            @include breakpoint(tablet) {
                @include rem(14px);

                line-height: 22px;
                margin-bottom: 15px;
            }
        }
