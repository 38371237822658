@import 'magnific-popup/src/css/main.scss';
$pdp-max-width: 510px;

.body--admin {
    top: 44px;
}

.mfp-bg {

    &.mfp--cover {
        background: $c-white;
        opacity: 1;

        html &.mfp-ready {
            opacity: 1;
        }
    }

    &.mfp--outlet {
        background: $c-black;
        opacity: 1;
        height: 100vh !important;

        html &.mfp-ready {
            opacity: 1;
        }
    }
}

.mfp-container {

    .mfp-flush & {
        padding-right: 0;
        padding-left: 0;
    }

    .mfp-sample &,
    .mfp-wishlist & {
        max-width: 800px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        padding: 0;

        @include breakpoint(phablet) {
            transform: none;
            left: 0;
            bottom: 0;
            top: 0;
            width: 100%;
        }
    }

    .mfp-sample-detail & {
        max-width: 1050px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        padding: 0;

        @include breakpoint(phablet) {
            transform: none;
            left: 0;
            bottom: 0;
            top: 0;
            width: 100%;
        }
    }

    .mfp--cover & {
        padding-right: 0;
        padding-left: 0;
    }

    .mfp--outlet & {
        max-width: 700px;
        max-height: 700px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        padding: 0;
    }

    .mfp-pdp & {
        max-width: $pdp-max-width;
        right: 0;
        top: 0;
        bottom: 0;
        left: initial;
        padding: 0;

        @include breakpoint(phablet) {
            max-width: calc(100% - 40px);
        }

        @include breakpoint(extra-tiny) {
            max-width: none;
            width: 100%;
        }
    }
}

    .mfp-content {

        .mfp-flush & {

            @include breakpoint(phablet) {
                height: 100%;
                vertical-align: top;
            }
        }

        .mfp-sample &,
        .mfp-sample-detail & {
            background: $c-white;

            @include breakpoint(phablet) {
                height: 100%;
            }
        }

        .mfp--outlet & {
            padding: 90px;

            @include breakpoint(phablet) {
                padding: 0 15px;
                height: 100%;
            }
        }

        .mfp-pdp & {
            background: $c-white;
            height: 100%;
        }
    }

        .mfp-close {
            opacity: 1;

            svg {
                display: block;
                margin: 0 auto;
                pointer-events: none;
                color: $c-mid-grey;
            }

            .mfp-sample,
            .mfp-sample-detail & {
                position: absolute;
                top: 25px;
                right: 25px;

                svg {
                    pointer-events: none;
                }
            }

            .mfp--cover & {
                top: 38px;
                right: 38px;
                transform: translate(50%, -50%);

                @include breakpoint(phablet) {
                    top: 30px;
                    right: 30px;
                }
            }

            .mfp--outlet & {
                position: absolute;
                top: 0;
                right: 0;
                width: 40px;
                height: 40px;
                background: rgba($c-white, 0.6);
                border: none;
                border-radius: 4px;
                z-index: 2;
                transition: background 300ms;

                &:hover {
                    background: rgba($c-white, 0.9);
                }

                svg {
                    display: block;
                    pointer-events: none;
                    height: 20px;
                    width: 20px;
                }

                @include breakpoint(tablet) {
                    right: 15px;
                }
            }

            .mfp-pdp & {
                top: 10px;
                right: $pdp-max-width + 20px;
                fill: $c-white;
                opacity: 1;

                @include breakpoint(phablet) {
                    right: calc(100% - 40px);
                }

                @include breakpoint(extra-tiny) {
                    right: 0;
                    fill: $c-black;
                }
            }

            .mfp-wishlist & {
                top: -40px;
                right: -40px;
                fill: $c-white;
                opacity: 1;

                @include breakpoint(tablet) {
                    top: 0;
                    right: 0;
                    fill: $c-dark;
                }
            }
        }

.mfp-fade {

    &.mfp-bg {
        opacity: 0;
        transition: all 0.25s ease-out;

        &.mfp-ready {
            opacity: 0.8;
        }

        &.mfp-removing {
            opacity: 0;
        }
    }

    &.mfp-wrap {

        .mfp-content {
            opacity: 0;
            transition: all 0.25s ease-out;
        }

        &.mfp-ready .mfp-content {
            opacity: 1;
        }

        &.mfp-removing .mfp-content {
            opacity: 0;
        }
    }
}
