.wrap {
    margin: 0 auto;
    width: $site-width;
    max-width: $site-max-width;
    padding: 0 10px;
}

// New standard wrap for refresh

.wrap--new {
    margin: 0 auto;
    width: $site-width;
    max-width: $site-max-width-new;
    padding: 0 $site-gutter-new;

    @include breakpoint(tablet) {
        padding: 0 $site-gutter-mobile;
        max-width: $tab-max-width;
    }
}

.wrap--flush-desktop {
    padding: 0;
    max-width: none;

    @include breakpoint(tablet) {
        padding: 0 $site-gutter-mobile;
        max-width: 700px;
    }
}

.wrap--tight {
    padding: 0;
}

.wrap--relative {
    position: relative;
}

.wrap--extra-narrow {
    max-width: 580px;
}

.wrap--narrow {
    max-width: 680px;
}

.wrap--short {
    max-width: 750px;

    @include breakpoint(tablet) {
        max-width: 700px;
    }
}

.wrap--short-mobile {

    @include breakpoint(tablet) {
        padding: 0;
    }
}

.wrap--med-mobile {
    @include breakpoint(tablet) {
        padding: 0 $site-gutter-mobile;
    }
}

.wrap--1200 {
    max-width: 1200px;
}

.wrap--regular {
    max-width: 980px;
}

.wrap--large {
    max-width: 1510px;
}

.wrap--wide {
    max-width: 2000px;
    padding: 0 80px;

    @include breakpoint(tablet) {
        padding: 0 $site-gutter-mobile;
        max-width: none;
    }
}

.wrap--flush-mobile {
    @include breakpoint(phablet) {
        padding: 0;
    }
}

.wrap--ph-full {
    @include breakpoint(phablet) {
        max-width: none;
    }
}

.wrap--ph-collapse {
    padding: 0;
}

.wrap--grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @include breakpoint(desktop-extra-wide) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    @include breakpoint(desktop-wide) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @include breakpoint(wrap) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include breakpoint(tablet) {
        column-gap: 16px;
        row-gap: 25px;
    }

    @include breakpoint(phablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &.wrap--grid--large {
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @include breakpoint(desktop-extra-wide) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @include breakpoint(desktop-wide) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @include breakpoint(wrap) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        // @include breakpoint(tablet) {
        //     column-gap: 16px;
        //     row-gap: 25px;
        // }

        @include breakpoint(phablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}
