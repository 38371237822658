.header-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header-actions--fixed {
    position: absolute;
    top: -100%;
    opacity: 0;
    transition: opacity .3s ease;

    .header--fixed & {
        top: 50%;
        right: calc($site-gutter-new / 2);
        margin: 0;
        transform: translateY(-50%);
        opacity: 1;

        @include breakpoint(nav) {
            display: none;
        }
    }
}

    .header-actions__item {
        margin-right: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        @include breakpoint(nav) {
            margin-right: 20px;
        }

        &:last-child {
            margin-right: 0;
        }

        svg {
            fill: $c-dark-grey;
            transition: fill .3s ease;

            .header__nav-item--active {
                background: $c-black;
            }

            .header--clear:not(:hover):not(.header__nav-item--active) & {
                fill: $c-white;
            }
        }

        &:hover {

            svg {
                fill: $c-brand;
            }

            p {
                color: $c-brand;
            }

        }

        .header-actions--fixed & {

            svg {
                fill: $c-black;
                transition: fill .3s ease;

                &:hover {
                    fill: $c-brand;
                }
            }
        }
    }

    .header-actions__item--search {
        display: none;

        .header--fixed & {
            display: flex;
            align-items: center;
        }

        @include breakpoint(nav) {
            display: flex;
            align-items: center;
            padding-left: 5px;
            padding-right: 0;
        }



        p {
            @include rem(16px);
            @include apply-map($f-title);

            margin-right: 15px;
            line-height: 19px;
            transition: color .3s ease;
            color: $c-dark-grey;

            @include breakpoint(nav) {
                display: none;
            }

            .header--clear:not(:hover):not(.header__nav-item--active) & {
                color: $c-white;
            }
        }

        .icon-close {
            display: none;
        }

        &.header-actions__item--search-active {

            .icon-close {
                display: block;
                width: 18px;
            }

            .icon-search {
                display: none;
            }
        }
    }

    .header-actions__item--wishlist {

        svg {
            fill: none;
            stroke: $c-dark;
            transition: stroke .3s ease;

            .header--clear:not(:hover):not(.header__nav-item--active) & {
                fill: none;
                stroke: $c-white;
            }
        }

        &:hover {

            svg {
                fill: none;
                stroke: $c-brand;
            }
        }

        .header-actions--fixed & {

            svg {
                fill: none;
                transition: fill .3s ease;

                &:hover {
                    fill: none;
                    stroke: $c-brand;
                }
            }
        }
    }
