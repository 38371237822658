.currency-selector {
    @include lightondark;
    @include smallcaps;
    @include rem(13px);

    line-height: calc(14 / 13);
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(tablet) {
        @include rem(14px);

        line-height: calc(40 / 14);
        border-top: 1px solid $c-border;
    }

    .select-replace {
        height: 14px;

        @include breakpoint(tablet) {
            height: 40px;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            border: 5px solid $c-white;
            border-right-color: transparent;
            border-bottom: none;
            border-left-color: transparent;
            transform: translateY(-50%);
            z-index: 1;
        }
    }

    .select-replace--focused {
        box-shadow: none;
    }

        select {
            height: 14px;
            padding-top: 0;
            padding-bottom: 0;

            @include breakpoint(tablet) {
                height: 40px;
            }
        }

        svg {
            display: none;
        }

        .select-replace__value {
            padding: 0;
            font-size: inherit;
            line-height: inherit;
            font-weight: 700;
            color: $c-white;
            background: $c-black;
            border: none;

            @include breakpoint(tablet) {
                color: $c-black;
                background: $c-white;
            }
        }
}

    .currency-selector__label {
        margin-right: 10px;
        color: $c-white;
        letter-spacing: 0.6px;

        @include breakpoint(tablet) {
            color: $c-black;
        }
    }
