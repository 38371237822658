@use "sass:math";

.stockist-preview {}

    .stockist-preview__image {
        @include aspect(math.div(3, 2));

        background: $c-border;
    }

    .stockist-preview__title {
        @include medium-title;
        @include rem(36px);

        line-height: calc(39 / 36);
        margin-bottom: 25px;
    }

    .stockist-preview__copy {
        margin-bottom: 25px;
    }
