.expandable {
    position: relative;
    cursor: pointer;
    line-height: calc(16 / 13);

    @include rem(13px);

    &:before {
        content: '';
        position: absolute;
        bottom: 7px;
        left: 0;
        width: 100%;
        height: 1px;
        background: $c-filter-border;
    }

    svg {
        position: absolute;
        top: 50%;
        right: 20px;
        margin-top: -3px;
        stroke: $c-label;
    }
}

.expandable--filters {
    margin-top: 15px;
    margin-right: 5px;
    margin-left: 5px;

    @include breakpoint(mobile) {
        margin: 0 5px;
    }
}

.expandable--responsive {

    @include breakpoint(mobile) {
        line-height: calc(18 / 14);

        @include rem(14px);
    }

    &:before {

        @include breakpoint(mobile) {
            display: none;
        }
    }

    svg {

        @include breakpoint(mobile) {
            right: 15px;
            margin-top: -6px;
            fill: none;
            stroke: $c-black;
        }
    }
}

.expandable--centered {
    text-align: center;
}

.expandable--custom {

    @include breakpoint(mobile) {
        margin: 0;
        padding: 10px;
        background: $c-background;
    }

    .expandable__text {
        padding: 18px 20px;
    }
}

    .expandable__text {
        @include apply-map($f-smallcaps);
        @include rem(12px);

        line-height: calc(15 / 12);
        font-weight: 400;
        letter-spacing: 1px;
        position: relative;
        padding: 0 40px 0 20px;
        color: $c-filter-expand;
        background: $c-background;

        @include breakpoint(mobile) {
            @include rem(14px);

            line-height: calc(14 / 14);
        }
    }

    .expandable--responsive .expandable__text {

        @include breakpoint(mobile) {
            padding: 13px 15px;
            color: $c-black;
        }
    }

    .expandable--white .expandable__text {
        background: $c-white;
    }

    .expandable__text--less,
    .expandable--inactive .expandable__text--more {
        display: inline;
    }

    .expandable--responsive .expandable__text--less,
    .expandable--responsive.expandable--inactive .expandable__text--more {

        @include breakpoint(mobile) {
            display: block;
        }
    }

    .expandable__text--more,
    .expandable--inactive .expandable__text--less {
        display: none;
    }

    .expandable__label--tablet,
    .expandable__label--phablet,
    .expandable__label--mobile {
        display: none;
    }

    .expandable__label--desktop {

        @include breakpoint(tablet) {
            display: none;
        }
    }

    .expandable__label--tablet {

        @include breakpoint(tablet) {
            display: inline;
        }

        @include breakpoint(phablet) {
            display: none;
        }
    }

    .expandable__label--phablet {

        @include breakpoint(phablet) {
            display: inline;
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    .expandable__label--mobile {

        @include breakpoint(mobile) {
            display: inline;
        }
    }
