.inspiration {
    margin: 80px 0;

    @include breakpoint(tablet) {
        margin: 0;
    }

    .product-listing__price {
        margin-bottom: 0;
    }
}

    .inspiration__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        a {
            margin-bottom: 40px;
        }

        @include breakpoint(tablet) {
            display: none;
        }
    }

        .inspiration__header {
            margin-bottom: 30px;
            @include font(60px, 72px);
            @include apply-map($f-title);

            @include breakpoint(tablet) {
                margin-bottom: 10px;
                @include font(30px, 36px);
            }
        }
    
            .inspiration__title {
                @include font(50px, 60px);
                @include apply-map($f-title);

                @include breakpoint(tablet) {
                    @include font(35px, 40px);
                    display: none;
                }
            }
    
            .inspiration__copy {
                @include rem(16px);
                @include apply-map($f-primary);
    
                line-height: 24px;
                color: $c-block-copy;
                margin-bottom: 15px;
                text-align: center;

                @include breakpoint(tablet) {
                    @include rem(14px);

                    line-height: 22px;
                    text-align: left;
                }
            }
