@import 'slick-carousel/slick/slick.scss';

@mixin slick-arrow($background: $c-white, $arrowColour: $c-black) {
    width: 45px;
    height: 45px;
    background-color: $background;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 150ms ease-in;

    &:hover {
        background-color: $c-dark;

        svg {
            fill: $c-white;
        }
    }

    &.slick-disabled:hover {
        background-color: $background;
    }

    svg {
        fill: $arrowColour;
        height: 20px;
        width: 10px;
        transition: fill 150ms ease-in;
    }
}

.carousel--hero {
    height: 100%;

    .slick-list {
        position: relative;
    }

    .slick-track {
        align-items: flex-start;
    }

    .slick-dots {
        gap: 5px;
        
        &.slick-dots--left {
            right: 55px;
            bottom: 55px;
            left: unset;
            top: unset;
        }

        &.slick-dots--right {
            left: 55px;
            bottom: 55px;
            right: unset;
            top: unset;
        }

        .hero--small-mobile & {
            @include breakpoint(tablet) {
                bottom: 413px;
                right: 15px;
                left: unset;
                top: unset;
            }
        }

        .hero--medium-mobile & {
            @include breakpoint(tablet) {
                bottom: 345px;
                right: 15px;
                left: unset;
                top: unset;
            }
        }

        .hero--large-mobile & {
            @include breakpoint(tablet) {
                bottom: 300px;
                right: 15px;
                left: unset;
                top: unset;
            }
        }
    }

    .slick-slide {
        display: flex;
        height: auto !important;

        & > div {
            width: 100%;
        }
    }

    button {
        display: flex;
        align-items: flex-end;
    }

    .hero__inner {
        display: none;

        &:first-child {
            display: grid;
        }
    }

    .slick-polygon-button.slick-polygon-button {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}

// Don't show the whole gallery and then hide all the later images in a slider.
@include breakpoint(tablet) {
    .carousel--product-grid {
        .product-grid__photo {
            display: none;

            &:first-child {
                display: block;
            }
        }
    }
}
.carousel--product-grid {
    .slick-slide > div {
        @include remove-white-space();
    }
}

.slick-track,
.slick-list {

    .carousel--hero & {
        height: 100%;
    }
}

.slick-dots {
    display: flex;
    position: absolute;
    bottom: 80px;
    right: 80px;

    .product-grid & {
        position: relative;
        right: unset;
        left: unset;
        bottom: unset;

        @include breakpoint(mobile) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

        .slick-polygon-button {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;

            svg {
                width: 12px;
                height: 12px;
            }
        }

        .carousel--quote & {
            @include unsetAbsolute;

            right: 0;
            bottom: 0px;

            @include breakpoint(phablet) {
                bottom: -10px;
            }
        }

    @include breakpoint(tablet) {
        bottom: 40px;
    }

    @include breakpoint(phablet) {
        right: 20px;
    }

    @include breakpoint(mobile) {
        .carousel--cylindo & {
            width: calc(100vw);
            padding: 0 10px 0 135px;
            justify-content: right;
        }
    }
}

    .slick-polygon-button {
        margin-left: 15px;
        width: 24px;

        svg {
            width: 24px;

            @include breakpoint(tablet) {
                width: 18px;
            }

            @include breakpoint(phablet) {
                width: 12px;
            }
        }

        polygon {
            fill: transparent;
            stroke: $c-white;
            stroke-width: 1px;
            transition: fill 0.5s ease;

            .product-grid & {
                stroke: $c-dark-grey;
                stroke-width: 2px;
            }
        }

        .slick-active & {

            svg {
                transform: scale(1.25);
            }

            polygon {
                fill: $c-white;

                .product-grid & {
                    fill: $c-dark-grey;
                }
            }
        }
    }

.slick-dots--lots {
    gap: 10px;

    .product-grid & {
        bottom: -15px;

        .slick-polygon-button {
            height: 12px;
            width: 12px;
        }
    }
}

.slick-list {

    .carousel--design-review & {
        padding: 0 80px;

        @include breakpoint(tablet) {
            padding: 0;
        }
    }
}

.slick-arrow {
    transition: opacity .3s ease;
    position: absolute;
    z-index: 5;

    svg {
        display: block;
    }

    &.slick-next,
    &.slick-prev {
        transition: fill .3s ease;

        &:hover {

            svg {
                fill: $c-brand;
            }
        }

        &.slick-disabled {
            &:hover {
                fill: $c-black;
            }
        }

        .get-inspired__container & {
            bottom: initial;
            right: initial;
        }

        .product-details__imgwrap & {
            padding: 10px;

            svg {
                fill: $c-white;
                transition: fill .3s ease;
            }

            &:hover {

                svg {
                    fill: $c-brand;
                }
            }
        }
    }

    &.slick-disabled {
        opacity: .15;

        .product-details__imgwrap & {
            &:hover {
                svg {
                    fill: $c-white;
                }
            }
        }
    }

    &.slick-next {
        top: -30px;
        right: 0;

        .overlay-reviews__header &,
        .categories__header &,
        .product__recommended-title & {
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }

        .product__recommended-title & {
            right: 10px;

            @include breakpoint(phablet) {
                right: 0;
            }
        }

        .design-review &,
        .carousel--product-recommended & {
            top: -64px;
            right: $site-gutter-new;

            @include breakpoint(tablet) {
                top: -35px;
                right: $site-gutter-mobile;
            }
        }

        .product-details__imgwrap & {
            top: initial;
            right: 40px;
            bottom: 40px;

            @include breakpoint(tablet) {
                right: 15px;
            }
        }

    }

    &.slick-prev {
        top: 0;
        right: 10px;

        .overlay-reviews__header &,
        .categories__header &,
        .product__recommended-title & {
            top: 50%;
            transform: translateY(-50%);
            right: 30px;
        }

        .product__recommended-title & {
            right: 40px;

            @include breakpoint(phablet) {
                right: 30px;
            }
        }

        .design-review &,
        .carousel--product-recommended & {
            top: -64px;
            right: $site-gutter-new + 30px;

            @include breakpoint(tablet) {
                top: -35px;
                right: $site-gutter-mobile + 30px;
            }
        }

        .product-details__imgwrap & {
            top: initial;
            right: 70px;
            bottom: 40px;

            @include breakpoint(tablet) {
                right: 55px;
            }
        }
    }
 }

 .slick-slide {

    .overlay-reviews__container & {
        opacity: 1;
        transition: opacity 300ms ease-in-out;
    }

    .get-inspired & {
        padding-right: 40px;

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    &.slick-active {

        .overlay-reviews__container & {
            opacity: 1;
        }
    }
 }

 // ¯\_(ツ)_/¯ //

 .slick-list {

    .carousel--inspired & {
        height: 100%;

        .slick-track {
            height: 100%;

            .slick-slide {
                height: 100%;

                    div {
                        &:first-child {
                            height: 100%;
                    }
                }
            }
        }
    }
 }

 .carousel {
    padding-bottom: 30px;
    @include breakpoint(phablet) {
        padding-bottom: 20px;
    }
}

.carousel--exposed {
    padding: 0;

    .slick-list {
        overflow: visible;

        @include breakpoint(phablet) {
            overflow: hidden;
        }
    }

    .slick-slide {
        margin-right: 12px;
        aspect-ratio: 9 / 5;

        @include breakpoint(tablet) {
            aspect-ratio: 4 / 3;
        }

        @include breakpoint(phablet) {
            margin-right: 0;
        }
    }

    .slick-slide:has(.block__image-credit) {
        aspect-ratio: 9 / 6;
    }

    .slick-arrow.slick-arrow {
        width: 45px;
        height: 45px;
        background-color: $c-brand-black;
        border-radius: 0;
        @include slick-arrow($c-brand, $c-white);

        margin-top: -30px;

        @include breakpoint(tablet) {
            opacity: 1;
            pointer-events: unset;
        }
    }

    .slick-next.slick-next {
        right: 12px;

        @include breakpoint(phablet) {
            right: 0;
        }
    }

    .slick-prev.slick-prev {
        left: 0;
    }

    .slick-dots {
        display: none !important;
    }
}

.carousel--at-start {

    .slick-prev {
        opacity: 0;
    }
}

.carousel--at-end {

    .slick-next {
        opacity: 0;
    }
}

.carousel--bordered {
    padding-bottom: 10px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        width: 300px;
        height: 1px;
        background: $c-border;
        transform: translateX(-50%);

        @include breakpoint(phablet) {
            display: none;
        }
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 5px;
    }

    @include breakpoint(phablet) {
        padding-bottom: 30px;
    }
}

.slick-track {
    display: flex !important;
    align-items: center;

    .slick-slider--top & {
        align-items: start;
    }
}

.slick-arrow {

    .carousel--bordered & {
        border: 1px solid $c-border;
    }
}

.slick-slide {
    outline: none;
    vertical-align: middle;
    display: block;
    float: left;
}


.slick-dots {

    .carousel--exposed &,
    .carousel &,
    .slick-dotted
    :not(.carousel--hero)
    :not(.carousel--quote)
    :not(.carousel--product-grid) & {
        @include rem(0px);

        position: absolute;
        display: block;
        right: initial;
        bottom: initial;
        bottom: 10px;
        text-align: center;
        width: 100%;
        opacity: 0;

        @include breakpoint(mobile) {
            opacity: 1;
        }

        li {
            display: inline-block;
            vertical-align: top;
            margin: 0 0 0 6px;

            &:first-child {
                margin-left: 0;
            }
        }

        .slick-active button {
            background: none;
        }

        button {
            width: 6px;
            height: 6px;
            background: $c-black;
            border: 1px solid $c-black;
            border-radius: 100%;
            transition: opacity .4s ease;

            &:after {
                display: none;
            }
        }
    }

    .carousel & {
        opacity: 1;
        bottom: 0;

        li {
            button {
                width: 10px;
                height: 10px;
                border: none;
                position: relative;
            }
            &.slick-active button {
                border: 1px solid $c-border;
            }
        }
    }
    .carousel--bordered & {
        left: 50%;
        width: auto;
        padding: 0 8px;
        transform: translateX(-50%);
        background: $c-white;
        z-index: 1;
    }
}

.slick-arrow {
    .slick-dotted &,
    .carousel--outlet & {
        @include slick-arrow($c-brand, $c-white);

        position: absolute;
        top: 50%;
        margin-top: -20px;
        border: none;
        z-index: 2;
        transition: background 300ms;

        &:after {
            display: none;
        }

        @include breakpoint(mobile) {
            opacity: 0;
            pointer-events: none;
        }
    }

    .carousel--bordered & {
        border: 1px solid $c-border;
    }

    .carousel--outlet & {
        @include breakpoint(phablet) {
            opacity: 0;
        }
    }
}

.slick-prev {

    .carousel--exposed &,
    .slick-dotted & {
        transform: none;
        left: 20px;
    }

    .carousel--outlet & {
        left: -90px;

        &:hover {
            svg {
                fill: $c-black;
            }
        }

        @include breakpoint(tablet) {
            left: -50px;
        }
    }

}

.slick-next {
    .carousel--exposed &,
    .slick-dotted & {
        right: 20px;
    }

    .carousel--outlet & {
        right: -90px;

        &:hover {
            svg {
                fill: $c-black;
            }
        }

        @include breakpoint(tablet) {
            right: -50px;
        }
    }
}

.slick-dots {

    .carousel--outlet & {
        @include rem(0px);

        position: absolute;
        display: block;
        right: initial;
        bottom: initial;
        bottom: -40px;
        text-align: center;
        width: 100%;

        li {
            display: inline-block;
            vertical-align: top;
            margin: 0 0 0 6px;

            &:first-child {
                margin-left: 0;
            }
        }

        .slick-active button {
            background: $c-white;
        }

        button {
            width: 15px;
            height: 15px;
            background: none;
            border: 1px solid $c-white;
            border-radius: 100%;
            transition: opacity .4s ease;

            &:after {
                display: none;
            }
        }
    }
}

.carousel--product-recommended,
.carousel--product-grid {
    .slick-track {
        align-items: flex-start;
    }

    .slick-arrow {
        @include slick-arrow;

        top: 50%;
        transform: translateY(-50%);
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }

    .slick-arrow.slick-disabled {
        opacity: 0.25;

        &:hover {
            svg {
                fill: $c-black;
            }
        }
    }
}

.carousel--product-thumbnails {
    overflow: hidden;

    .slick-track {
        margin: 0;
        align-items: stretch;
    }

    .slick-list {
        margin: 0 calc(9px * -1);
    }

    .slick-slide {
        margin: 0 9px;
        height: auto;

        & > * {
            display: flex;
            height: 100%;
        }
    }
}

.carousel--customise-modal {
    .slick-track {
        align-items: flex-start;
    }

    .slick-arrow {
        width: 34px;
        height: 34px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $c-white;
        opacity: 0.8;

        @include breakpoint(tablet) {
            width: 30px;
            height: 30px;

            svg {
                width: 6px;
                height: 10px;
            }
        }
    }

    .slick-prev {
        left: 10px;

        @include breakpoint(tablet) {
            left: 5px;
        }
    }

    .slick-next {
        right: 10px;

        @include breakpoint(tablet) {
            right: 5px;
        }
    }

    .slick-arrow.slick-disabled {
        opacity: 0.25;

        &:hover {
            svg {
                fill: $c-black;
            }
        }
    }
}

.carousel--modal-thumbnails {
    overflow: hidden;

    .slick-track {
        margin: 0;
        align-items: stretch;
    }

    .slick-list {
        margin: 0 calc(9px * -1);
    }

    .slick-slide {
        margin: 0 9px;
        height: auto;

        & > * {
            display: flex;
            height: 100%;
        }
    }
}

.carousel--product-recommended {
    .slick-slide {
        margin: 0 10px;
    }

    .slick-list {
        margin: 0 -10px;
    }
}
