// Split named breakpoints into groups to be compiled based on true/false
// Repition in output should be dealt with by 'minify-css' gulp task

$use-halfs:                 true !default;
$use-thirds:                true !default;
$use-quarters:              true !default;
$use-fifths:                true !default;
$use-sixths:                true !default;
$use-eighths:               true !default;
$use-tenths:                true !default;
$use-twelfths:              true !default;
$use-twentieths:            true !default;
$use-true-false:            true !default;

// Col sizes
@each $name, $breakpoint in $sizes {
    @include breakpoint($breakpoint) {

        // Full width

        .#{$name}-full {
            width: 100%;
        }

        // Halfs

        @if $use-halfs {

            .#{$name}-one-half {
                width: calc(100% / 2) * 1;
            }

            .#{$name}-two-halfs {
                width: calc(100% / 2) * 2;
            }

        }

        // Thirds
        @if $use-thirds {

            .#{$name}-one-third {
                width: calc(100% / 3) * 1;
            }

            .#{$name}-two-thirds {
                width: calc(100% / 3) * 2;
            }

            .#{$name}-three-thirds {
                width: calc(100% / 3) * 3;
            }

        }

        // Quarters

        @if $use-quarters {

            .#{$name}-one-quarter {
                width: calc(100% / 4) * 1;
            }

            .#{$name}-two-quarters {
                width: calc(100% / 4) * 2;
            }

            .#{$name}-three-quarters {
                width: calc(100% / 4) * 3;
            }

            .#{$name}-four-quarters {
                width: calc(100% / 4) * 4;
            }

        }

        // Fifths

        @if $use-fifths {

            .#{$name}-one-fifth {
                width: calc(100% / 5) * 1;
            }

            .#{$name}-two-fifths {
                width: calc(100% / 5) * 2;
            }

            .#{$name}-three-fifths {
                width: calc(100% / 5) * 3;
            }

            .#{$name}-four-fifths {
                width: calc(100% / 5) * 4;
            }

            .#{$name}-five-fifths {
                width: calc(100% / 5) * 5;
            }

        }

        // Sixths

        @if $use-sixths {

            .#{$name}-one-sixth {
                width: calc(100% / 6) * 1;
            }

            .#{$name}-two-sixths {
                width: calc(100% / 6) * 2;
            }

            .#{$name}-three-sixths {
                width: calc(100% / 6) * 3;
            }

            .#{$name}-four-sixths {
                width: calc(100% / 6) * 4;
            }

            .#{$name}-five-sixths {
                width: calc(100% / 6) * 5;
            }

            .#{$name}-six-sixths {
                width: calc(100% / 6) * 6;
            }

        }

        // Eighths

        @if $use-eighths {

            .#{$name}-one-eighth {
                width: calc(100% / 8) * 1;
            }

            .#{$name}-two-eighths {
                width: calc(100% / 8) * 2;
            }

            .#{$name}-three-eighths {
                width: calc(100% / 8) * 3;
            }

            .#{$name}-four-eighths {
                width: calc(100% / 8) * 4;
            }

            .#{$name}-five-eighths {
                width: calc(100% / 8) * 5;
            }

            .#{$name}-six-eighths {
                width: calc(100% / 8) * 6;
            }

            .#{$name}-seven-eighths {
                width: calc(100% / 8) * 7;
            }

            .#{$name}-eight-eighths {
                width: calc(100% / 8) * 8;
            }

        }

        // Tenths

        @if $use-tenths {

            .#{$name}-one-tenth {
                width: calc(100% / 10) * 1;
            }

            .#{$name}-two-tenths {
                width: calc(100% / 10) * 2;
            }

            .#{$name}-three-tenths {
                width: calc(100% / 10) * 3;
            }

            .#{$name}-four-tenths {
                width: calc(100% / 10) * 4;
            }

            .#{$name}-five-tenths {
                width: calc(100% / 10) * 5;
            }

            .#{$name}-six-tenths {
                width: calc(100% / 10) * 6;
            }

            .#{$name}-seven-tenths {
                width: calc(100% / 10) * 7;
            }

            .#{$name}-eight-tenths {
                width: calc(100% / 10) * 8;
            }

            .#{$name}-nine-tenths {
                width: calc(100% / 10) * 9;
            }

            .#{$name}-ten-tenths {
                width: calc(100% / 10) * 10;
            }

        }

        // Twelfths

        @if $use-twelfths {

            .#{$name}-one-twelfth {
                width: calc(100% / 12) * 1;
            }

            .#{$name}-two-twelfths {
                width: calc(100% / 12) * 2;
            }

            .#{$name}-three-twelfths {
                width: calc(100% / 12) * 3;
            }

            .#{$name}-four-twelfths {
                width: calc(100% / 12) * 4;
            }

            .#{$name}-five-twelfths {
                width: calc(100% / 12) * 5;
            }

            .#{$name}-six-twelfths {
                width: calc(100% / 12) * 6;
            }

            .#{$name}-seven-twelfths {
                width: calc(100% / 12) * 7;
            }

            .#{$name}-eight-twelfths {
                width: calc(100% / 12) * 8;
            }

            .#{$name}-nine-twelfths {
                width: calc(100% / 12) * 9;
            }

            .#{$name}-ten-twelfths {
                width: calc(100% / 12) * 10;
            }

            .#{$name}-eleven-twelfths {
                width: calc(100% / 12) * 11;
            }

            .#{$name}-twelve-twelfths {
                width: calc(100% / 12) * 12;
            }

        }

        // Twentieths

        @if $use-twentieths {

            .#{$name}-one-twentieth {
                width: calc(100% / 20) * 1;
            }

            .#{$name}-two-twentieths {
                width: calc(100% / 20) * 2;
            }

            .#{$name}-three-twentieths {
                width: calc(100% / 20) * 3;
            }

            .#{$name}-four-twentieths {
                width: calc(100% / 20) * 4;
            }

            .#{$name}-five-twentieths {
                width: calc(100% / 20) * 5;
            }

            .#{$name}-six-twentieths {
                width: calc(100% / 20) * 6;
            }

            .#{$name}-seven-twentieths {
                width: calc(100% / 20) * 7;
            }

            .#{$name}-eight-twentieths {
                width: calc(100% / 20) * 8;
            }

            .#{$name}-nine-twentieths {
                width: calc(100% / 20) * 9;
            }

            .#{$name}-ten-twentieths {
                width: calc(100% / 20) * 10;
            }

            .#{$name}-evleven-twentieths {
                width: calc(100% / 20) * 11;
            }

            .#{$name}-twelve-twentieths {
                width: calc(100% / 20) * 12;
            }

            .#{$name}-thirteen-twentieths {
                width: calc(100% / 20) * 13;
            }

            .#{$name}-fourteen-twentieths {
                width: calc(100% / 20) * 14;
            }

            .#{$name}-fifteen-twentieths {
                width: calc(100% / 20) * 15;
            }

            .#{$name}-sixteen-twentieths {
                width: calc(100% / 20) * 16;
            }

            .#{$name}-seventeen-twentieths {
                width: calc(100% / 20) * 17;
            }

            .#{$name}-eighteen-twentieths {
                width: calc(100% / 20) * 18;
            }

            .#{$name}-nineteen-twentieths {
                width: calc(100% / 20) * 19;
            }

            .#{$name}-twenty-twentieths {
                width: calc(100% / 20) * 20;
            }

        }

        // True/false

        @if $use-true-false {

            .#{$name}-false {
                display: none !important;
            }

            .#{$name}-true {
                display: inline-block !important;
            }

            .#{$name}-block {
                display: block !important;
            }

        }

    } // End @include

} // End @each
