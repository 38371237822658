@mixin swatch-hover($border-width: 3px, $initial-border: true) {
    @if ($initial-border) {
        border: 1px solid $c-filter-grey;
    } @else {
        border: 1px solid $c-white;
    }
    transition: border 50ms ease, outline 50ms ease;
    outline: $border-width solid transparent;
    outline-offset: calc(#{$border-width + 1} * -1);

    &:hover {
        border-color: $c-dark;
        outline: $border-width solid $c-white;
        outline-offset: calc(#{$border-width + 1} * -1);
    }
}
