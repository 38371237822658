/*! nouislider - 10.0.0 - 2017-05-28 14:52:48 */
/* Functional styling;
* These styles are required for noUiSlider to function.
* You don't need to change these rules to apply your design.
*/
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-target {
    position: relative;
    direction: ltr;
}

.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    /* Fix 401 */
}

.noUi-connect {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;

    .filter-range & {
        top: -1px;
        bottom: -1px;
    }
}

.noUi-origin {
    position: absolute;
    height: 0;
    width: 0;
}

.noUi-handle {
    position: relative;
    z-index: 1;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
    transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}

/* Painting and performance;
* Browsers can paint handles in their own layer.
*/
.noUi-base,
.noUi-handle {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
*/
.noUi-horizontal {
    height: 2px;

    .filter-range & {
        height: 4px;
    }
}

.noUi-horizontal .noUi-handle {
    width: 12px;
    height: 12px;
    left: -6px;
    top: -5px;

    .filter-range & {
        top: -9px;
        left: -10px;
        width: 18px;
        height: 18px;
    }
}

.noUi-vertical {
    width: 2px;
}

.noUi-vertical .noUi-handle {
    width: 12px;
    height: 12px;
    top: -17px;
}

/* Styling;
*/
.noUi-target {
    background: $c-border;
    border-radius: 2px;

    .filter-range & {
        background: $c-white;
        border: 1px solid $c-filter-grey;
    }
}

.noUi-connect {
    background: $c-brand;
    border-radius: 2px;
    -webkit-transition: background 450ms;
    transition: background 450ms;

    .filter-range & {
        background: $c-black;
    }
}

/* Handles and cursors;
*/
.noUi-draggable {
    cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize;
}

.noUi-handle {
    border-radius: 100%;
    background: $c-white;
    border: 2px solid $c-brand;
    cursor: default;

    &:hover {
        cursor: pointer;
    }

    .filter-range & {
        background: $c-black;
        border: none;
        position: relative;
    }

    &:after {

        .filter-range & {
            content: '';
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 6px;
            height: 6px;
            background: $c-white;
            border-radius: 100%;
        }
    }
}

.noUi-active {
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

/* Disabled state;
*/
[disabled] .noUi-connect {
    background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
    cursor: not-allowed;
}

/* Base;
*
*/
.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-pips {
    position: absolute;
    color: #999;
}

/* Values;
*
*/
.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
}

.noUi-value-sub {
    color: #ccc;
    font-size: 10px;
}

/* Markings;
*
*/
.noUi-marker {
    position: absolute;
    background: #CCC;
}

.noUi-marker-sub {
    background: #AAA;
}

.noUi-marker-large {
    background: #AAA;
}

/* Horizontal layout;
*
*/
.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}

.noUi-value-horizontal {
    -webkit-transform: translate3d(-50%, 50%, 0);
    transform: translate3d(-50%, 50%, 0);
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
}

/* Vertical layout;
*
*/
.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}

.noUi-value-vertical {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
    padding-left: 25px;
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px;
}

.noUi-tooltip {
    @include apply-map($f-pagecopy);
    @include rem(14px);

    line-height: calc(16 / 14);
    display: block;
    position: absolute;
    text-align: center;
    white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}
