.faqs {}

    .faqs__title {

        @include breakpoint(phablet) {
            @include rem(40px);

            line-height: calc(48 / 40);
        }
    }

    .faqs__section {
        position: relative;
        .faqs__section + & {
            margin: 50px 0 0;
        }

        &.faqs__section--active {
            margin-top: 0;
        }
    }

    .faqs__section--hide {
        display: none;
    }

        .faqs__section__title {
            @include large-title;
            @include rem(30px);

            @include breakpoint(laptop) {
                @include rem(26px);
            }

            @include breakpoint(mobile) {
                @include rem(24px);
            }
        }

        .faqs__accordion .accordion__title {
            @include rem(18px);

            line-height: calc(31 / 18);
            padding: 15px 0;

            @include breakpoint(phablet) {
                line-height: calc(25 / 18);
            }

            @include breakpoint(mobile) {
                @include rem(16px);
            }

            .accordion__title-text {
                padding-right: 25px;
            }
        }

        .faqs__accordion .accordion__body {
            @include rem(16px);

            line-height: calc(22 / 16);
            color: rgba($c-black, 0.7);

            * {
                line-height: calc(22 / 16);
            }
        }

        .faqs-nav__container {
            padding-right: 0;
            margin-bottom: 0;

            @include breakpoint(phablet) {
                padding-right: 30px;
            }
        }

        .faqs-nav__list {
            display: flex;
            justify-content: center;
            align-items: end;
            width: max-content;
            gap: 20px;
        }

        .faqs-nav__item {
            border-bottom: 1px solid $c-filter-grey;
            transition: border-bottom 0.3s ease;
            cursor: pointer;
            padding-bottom: 5px;
            text-transform: uppercase;
            color: $c-dark-grey;
            font-weight: 500;
            letter-spacing: 2.5px;
            line-height: 26px;
            @include rem(12px);

            &:hover,
            &:active {
                border-bottom: 1px solid $c-dark;
            }

            &.faqs-nav__item--active {
                border-bottom: 1px solid $c-dark;
            }
        }

        // Makes select-replace box same width as the FAQs below
        .faqs-nav__grid {
            @include breakpoint(laptop) {
                margin-right: 0;
            }

            @include breakpoint(phablet) {
                margin-right: -30px;
            }
        }
