@use "sass:math";

.post-preview {
    position: relative;
    text-decoration: none;

    &.post-preview--secondary {

        @include breakpoint(phablet) {
            margin-bottom: 30px;
        }
    }

    &.post-preview--secondary:nth-child(2n) {
        margin-top: 100px;

        @include breakpoint(phablet) {
            margin-top: 0;
        }
    }

    &.post-preview--secondary:last-child {

        @include breakpoint(phablet) {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: none;
    }
}
    .post-preview__image {
        display: block;
        @include aspect(math.div(300, 200));

        position: relative;
        background: $c-border;

        .post-preview--secondary & {
            @include aspect(math.div(565, 423));

            @include breakpoint(phablet) {

                @include aspect(math.div(300, 200));
            }
        }

        .post-preview--primary & {
            @include aspect(math.div(1610, 675));

            max-height: 675px;
            background: $c-black;

            @include breakpoint(phablet) {

                @include aspect(1);
            }
        }

        img {

            .post-preview--primary & {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0.5;
            }
        }
    }

    .post-preview__content {

        * + & {
            margin-top: 20px;

            .post-preview--primary & {
                margin-top: 0;
            }
        }

        .post-preview--primary & {

            @include breakpoint(phablet) {
                position: absolute;
                right: 0;
                bottom: 20px;
                left: 0;
            }
        }
    }

        .post-preview__title {
            @include rem(26px);

            line-height: calc(28 / 26);

            .post-preview--secondary & {
                @include rem(36px);

                line-height: calc(43 / 36);

                @include breakpoint(phablet) {

                    @include rem(26px);

                    line-height: calc(28 / 26);
                }
            }

            .post-preview--primary & {
                @include rem(60px);

                line-height: calc(72 / 60);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                max-width: $site-max-width;
                margin: 0;
                padding: 0 10px;
                text-align: center;
                color: $c-white;

                @include breakpoint(phablet) {

                    @include rem(24px);

                    line-height: calc(29 / 24);
                    position: relative;
                    top: auto;
                    left: auto;
                    transform: none;
                }
            }
        }

        .post-preview__meta {
            font-size: 0;

            * + & {
                margin-top: 15px;

                .post-preview--secondary & {
                    margin-top: 6px;

                    @include breakpoint(phablet) {
                        margin-top: 15px;
                    }
                }

                .post-preview--primary & {
                    margin-top: 0;

                    @include breakpoint(phablet) {
                        margin-top: 10px;
                    }
                }
            }

            .post-preview--primary & {
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                max-width: $site-max-width;
                padding: 0 10px;
                text-align: center;

                @include breakpoint(phablet) {
                    position: relative;
                    bottom: auto;
                    left: auto;
                    transform: none;
                }
            }
        }

            .post-preview__meta__item {
                @include apply-map($f-smallprint);
                @include rem(12px);

                line-height: calc(15 / 12);
                text-transform: uppercase;
                letter-spacing: 1px;
                color: rgba($c-black, 0.3);
                display: inline-block;
                vertical-align: middle;

                a {
                    color: rgba($c-black, 0.3);
                }

                .post-preview--primary & {
                    color: rgba($c-white, 0.8);
                    a {
                        color: rgba($c-white, 0.8);
                    }
                }
            }

            .post-preview__intro {
                @include rem(16px);

                line-height: calc(22 / 16);
                color: $c-post-intro;
                margin: 15px 0 30px;
                display: block;

                .post-preview--secondary & {
                    margin-top: 10px;

                    @include breakpoint(phablet) {
                        margin-top: 15px;
                    }
                }
            }

.post__title {
    @include large-title;
    line-height: calc(60 / 72);

    @include breakpoint(phablet) {
        @include rem(40px);
        line-height: calc(33 / 40);
    }
}
.post__subtitle,
.post-next__subtitle {
    @include apply-map($f-smallprint);
    @include rem(12px);

    line-height: calc(15 / 12);
    text-transform: uppercase;
    color: $c-label;
    font-weight: 400;
    letter-spacing: 1px;

    * + & {
        margin: 10px 0 0;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}
.post__intro {
    @include introcopy;
}
.post-social {
    text-align: center;
    .post-social__title {
        @include smallcaps;
        color: $c-label;
        letter-spacing: 1px;
    }
    .post-social__link {
        display: inline-block;
        vertical-align: middle;
        font-size: 0;
        padding: 10px;
        svg {
            display: block;
            fill: $c-label;
        }
    }
}
.post-next {
    display: block;
}
.post-next__title {
    @include medium-title;
    color: $c-white;
}
.post-next__subtitle {
    margin-bottom: 20px;
    a {
        color: inherit;
        text-decoration: none;
    }
}
.post-next__intro {
    @include bodycopy;
    color: $c-white;
    text-decoration: none;
}
.post-next__link {
    display: block;
    margin-bottom: 40px;
    text-align: center;
    @include smallcaps;
    color: $c-label;
    letter-spacing: 1px;
    @include breakpoint(phablet) {
        margin-top: 10px;
    }
    @include breakpoint(mobile) {
        margin-top: 0px;
        margin-bottom: 30px;
    }
    svg {
        margin-left: 10px;
    }
}

    .post__blocks {
        .block:first-child {
            padding-top: 20px;
        }
    }
