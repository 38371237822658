.zoom {
    cursor: zoom-in;

    @include breakpoint(tablet) {
        cursor: auto;
    }
}

.zoom--pdp {
    &:hover {
        outline: none;
    }

    &.zoom--enabled {
        z-index: 100 !important;
        opacity: 0 !important;
    }
}

.zoom__trigger {
    @include rem(40px);
    display: none;
    font-weight: 200;
    line-height: 1ex;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;

    svg {
        fill: $c-block-copy;
        width: 18px;
    }
}

.zoom {
    .zoom__trigger {
        display: block;
        z-index: 100;
        @include breakpoint(nav) {
            display: none;
        }
    }

    .zoom__text {
        @include rem(14px);
        z-index: 1;
        display: inline-block;
        vertical-align: middle;
        padding: 3px 0;
        color: $c-label;
    }

    // Pull over the top of scroll arrows
    &.zoom--enabled {
        cursor: move;
        cursor: all-scroll;
        z-index: 600;

        @include breakpoint(tablet) {
            cursor: default;
        }

        // .zoom__trigger {
        //     &:before {
        //         content: "×";
        //         color: $c-grey;
        //     }
        // }
    }
}


.zoom__canvas {
    visibility: hidden;
    opacity: 0;
    @include aspect(1);
    background: $c-grey;;
    position: absolute;
    top: 1px;
    right: calc(100% + 50px);
    width: calc(58.33333vw - 20px);
    max-width: 690px;
    z-index: 550;
    transition: visibility 0s linear 0.25s, opacity 0.25s cubic-bezier(0.24,0.63,0.44,1);;
    @include breakpoint(tablet) {
        // right: calc(100% + 20px);
    }
    @include breakpoint(tablet) {
        display: none;
    }
}
.zoom__canvas--pdp {
    top: auto;
    right: auto;
    max-width: 717px;
    z-index: 99;
}
.zoom__canvas--enabled {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
}
.zoom__backdrop {
    display: none;
}
.zoom__backdrop--enabled {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    background: rgba(0, 0, 0, .3);

    @include breakpoint(tablet) {
        position: static;
        display: none;
    }

    & + .customise__top-section {
        z-index: 999;
    }
}

.zoom__backdrop--pdp.zoom__backdrop--enabled {
    display: none;

    + div .product__gallery {
        position: relative;
        z-index: 95;
    }
}


.slick-track {

    .zoom--enabled & {
        // opacity: 0 !important;
    }
}
