.breadcrumb-container {
    margin: 25px 0;
    z-index: 10;
}

.breadcrumb-container--flush {
    margin: 15px 0;
}

.breadcrumb-container--tight {
    margin: 10px 0;
}

    .breadcrumb {
        font-size: 0;
    }

        .breadcrumb__item {
            @include rem(14px);

            line-height: 1;
            display: inline-block;

            a {
                @include apply-map($f-smallcaps);

                color: $c-breadcrumb-link;
                line-height: calc(16 / 14);
                text-decoration: none;
                text-transform: uppercase;
            }

            &:after {
                content: '/';
                margin: 0 10px;
                color: $c-breadcrumb-link;
            }
        }

        .breadcrumb__item--current {

            &:after {
                display: none;
            }

            a {
                color: $c-black;
            }
        }
