.message {
    @include font(14px, 17px);
    padding-left: 35px;
    margin-bottom: 30px;
    min-height: 24px;
    background-repeat: no-repeat;
    background-position: left center;
    line-height: 24px;
    width: 100%;

    .account-listing-data & {
        width: 50%;
        margin-top: 3px;
    }
}

.message--success {
    background-image: url('/images/icon-success.svg');
}

.message--error {
    background-image: url('/images/icon-error.svg');
}

.message--warning {
    background-image: url('/images/icon-warning.svg');
    color: $c-label;
}
