.sticky-application {
    position: sticky;
    bottom: 0;
    background-color: $c-dark;
    margin-bottom: 55px;
    z-index: 50;

    @include breakpoint(tablet) {
        margin-bottom: 20px;
    }
}

    .sticky-application__content {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: $site-max-width-new;
        padding: 15px $site-gutter-new;

        @include breakpoint(tablet) {
            justify-content: center;
        }

        @include breakpoint(phablet) {
            padding-left: $site-gutter-mobile;
            padding-right: $site-gutter-mobile;

            & > * {
                width: 100%;
            }
        }
    
        p {
            @include apply-map($f-title);
            @include font(30px, 30px);

            color: $c-white;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }
