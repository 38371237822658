@use "sass:math";

.block {
    margin: 0 auto;
    padding: 40px 0;
    position: relative;

    .product__story & {
        padding: 0;
    }
}

.block--with-tall-padding {
    padding: 70px 0;

    @include breakpoint(tablet) {
        padding: 35px 0;
    }
}

.block--with-tall-padding-top {
    padding-top: 70px;

    @include breakpoint(tablet) {
        padding-top: 35px;
    }
}

.block--bottom-responsive {
    @include breakpoint(tablet) {
        padding-bottom: 20px;
    }
}

.block--grey {
    background: $c-background;

    @include breakpoint(phablet) {
        background: $c-white;
    }
}

.block--white {
    background: $c-white;
}

.block--black {
    background: $c-black;
}

.block--grey-white {
    background: linear-gradient(to bottom, $c-background 50%, $c-white 50%);

    @include breakpoint(phablet) {
        background: $c-white;
    }
}
.block--white-grey {
    background: linear-gradient(to bottom, $c-white 50%, $c-background 50%);

    @include breakpoint(phablet) {
        background: $c-white;
    }
}

.block__intro {
    @include introcopy;
    margin-bottom: 30px;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
}

.block__title {
    @include medium-title;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 40px;

    &.block--direction-left {
        text-align: left;
    }

    &.block--direction-right {
        text-align: right;
    }
}

.block--twin-images {
    overflow: hidden;
    padding-bottom: 50px;
}

.block--twin-images,
.subblock--twin-images {
    .block__images {
        margin-top: 15px;
        padding-right: 20px;
        font-size: 0;

        @include breakpoint(mobile) {
            padding-right: 0;
        }
    }

    .block__image {
        margin-bottom: 15px;

        @include breakpoint(mobile) {
            margin-bottom: 20px;
        }

        @include breakpoint(mobile) {
            width: calc(100% + 20px);
            margin-right: -10px;
            margin-left: -10px;
        }

        &:last-child {
            position: relative;
            left: 20px;

            @include breakpoint(mobile) {
                left: 0;
            }
        }
    }

    .block__image--60 {
        width: 60%;
    }

    .block__image--40 {
        width: 40%;
    }
    .block__image--story-40 {
        @include aspect(math.div(464, 406));
    }
    .block__image--story-60 {
        @include aspect(math.div(696, 406));
    }
    .block__image--story-landscape {
        @include aspect(math.div(60, 48));
    }
    .block__image--story-tall {
        @include aspect(math.div(60, 72));
    }
    .block__image--story-square {
        @include aspect(1);
    }
    .wide-staggered-background-container {
        position: relative;
    }
    .wide-staggered-background {
        position: absolute;
        width: 500%;
        bottom: -50px;
        top: -400px;
        left: 25%;
        background: $c-background;
        z-index: -1;
    }
}
.block--wide-then-twin {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 70%;
        left: 30%;
        top: 20%;
        height: 80%;
        background: $c-background;
    }

    .subblock--twin-images {
        position: relative;
        z-index: 5;
    }

    .subblock--wide {
        position: relative;
        margin-bottom: 100px;
        z-index: 5;
        @include breakpoint(phablet) {
            margin-bottom: 20px;
        }
        @include breakpoint(mobile) {
            margin-bottom: 10px;
        }
    }
}

    .block__image {
        display: inline-block;
        vertical-align: middle;

        img {
            width: 100%;
            height: auto;
        }
    }

    .block__image--level {
        margin-bottom: 0;
        @include breakpoint(mobile) {
            margin-top: 5px;
        }
    }
    .block__image--staggered-up {
        margin-bottom: 11.1%;
        @include breakpoint(mobile) {
            margin-bottom: 5px;
        }
    }
    .block__image--staggered-down {
        margin-top: 11.1%;
        @include breakpoint(mobile) {
            margin-top: 5px;
        }
    }

    .block--image .block__image {
        display: block;
        margin: 0 auto;

        img {
            width: 100%;
            height: auto;
        }
    }

.block__gallery {}

    .block__gallery-item a {
        @include aspect(math.div(287, 191));

        display: block;
    }

.block__image-on-image {
    position: relative;

    @include breakpoint(phablet) {
        margin: 0 -10px 30px;
    }
}

    .block__image-on-image__small {
        @include aspect(math.div(280, 309));

        position: relative;
        bottom: 50px;
        left: 33.3333%;
        width: 100%;

        @include breakpoint (tablet) {
            width: 150%;
            left: 0;
        }

        @include breakpoint(phablet) {
            position: absolute;
            bottom: -30px;
            left: 10px;
            width: 180px;
            height: 180px;
        }

        @include breakpoint(mobile) {
            width: 140px;
            height: 140px;
        }
    }

    .block__image-on-image__large {
        @include aspect(math.div(880, 526));
    }

.block--carousel {
    overflow: hidden;
}

.block--request-quote {}

    .block--request-quote__copy {
        padding: 100px 0 70px;

        @include breakpoint(phablet) {
            padding: 50px 0 30px;
        }
    }

        .block--request-quote__title {
            color: $c-white;

            @include breakpoint(phablet) {
                @include rem(40px);

                line-height: calc(48 / 40);
            }
        }

        .block--request-quote__intro {
            @include rem(20px);

            line-height: calc(24 / 20);
            color: $c-request-quote-intro;

            * + & {
                margin: 20px 0 0;

                @include breakpoint(phablet) {
                    margin: 10px 0 0;
                }
            }
        }

    .block--request-quote__form {
        font-size: 0;

        #enquiry_painting_ref {
            width: calc(50% - 15px);

            @include breakpoint(phablet) {
                width: 100%;
            }
        }
    }

        .block--request-quote__form-footer {
            margin: 10px 0 0;
        }

    .block--request-quote__footnote {
        @include rem(13px);

        line-height: calc(18 / 13);
        padding: 40px 0;
        border-top: 1px solid rgba($c-white, 0.2);
        opacity: 0.6;

        @include breakpoint(phablet) {
            padding: 20px 0;
        }

        * + & {
            margin: 70px 0 0;

            @include breakpoint(phablet) {
                margin: 30px 0 0;
            }
        }

        p + p {
            margin: 10px 0 0;
        }
    }

    .block--request-quote__success {
        @include introcopy;
        color: rgba($c-white, .65);
        text-align: center;
        padding-bottom: 100px;
    }

    .block--listing {
        padding: 40px 0;
        margin: 0 auto;
        width: 100%;

        & + .block--listing {
            border-top: 0;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

.block--two-columns .wrap-container {
    padding: 20px;
}

.block--two-columns.block--border-grey .wrap-container {
    border: 1px solid $c-border;
    background-color: $c-white;
}

    .block--two-columns__title {
        margin: 0 0 50px;

        @include breakpoint(phablet) {
            @include rem(40px);

            line-height: calc(48 / 40);
            margin: 0 0 10px;
        }
    }

    .block--two-columns.block--border-grey {
        .block--two-columns__title {
            margin: 29px 0 20px;
            text-align: center;
            @include rem(40px);
        }
    }

    .block--two-columns__column {

        p {
            @include rem(16px);

            line-height: calc(22 / 16);
            color: $c-richcopy;
        }

        * + * {
            margin: 10px 0 0;
        }

        .block--two-columns__column + & {

            @include breakpoint(phablet) {
                margin: 15px 0 0;
            }
        }

        ul {
            list-style: none;

            li {
                margin: 0;
                margin-bottom: 1.5em;
                padding-left: 1.8em;
                position: relative;
                @include rem(16px);

                &:before {
                    content: '';
                    height: 0.8em;
                    width: 0.8em;
                    background: $c-black;
                    display: block;
                    position: absolute;
                    transform: rotate(45deg);
                    top: .25em;
                    left: 0;
                }
            }
        }
    }

    .block--two-columns--with-title .block--two-columns__column--column-1 {

        p {
            @include rem(24px);

            line-height: calc(31 / 24);

            @include breakpoint(phablet) {
                @include rem(19px);

                line-height: calc(24 / 19);
            }
        }
    }

    .block--richtext {
        .post__blocks &,
        .blocks--standard & {
            padding: 30px 0;
        }

        .richtext {
            max-width: 980px;
            margin: 0 auto;
            text-align: center;

            &.block--direction-left {
                text-align: left;
            }

            &.block--direction-right {
                text-align: right;
            }

            .post__blocks &,
            .blocks--standard & {
                max-width: 780px;
                text-align: left;
            }
        }
    }

    .block--inline-image {
        @include breakpoint (phablet) {
            .block__image--staggered-up {
                margin-bottom: 60px;
            }
            .block__image--staggered-down {
                margin-top: 0;
            }
        }
    }

    .block--inline-image {
        &.block--white-grey {
            background: $c-background;
        }

        &.block--grey-white {
            background: $c-background;
        }
    }

.block__introduction {
    padding: 30px 10px 40px;
    background-color: $c-black;
}

.block__introduction--light {
    background-color: transparent;
}

.block__introduction__title {
    @include large-title;
    margin: 0 auto 20px auto;
    color: $c-white;

    .block__introduction--light & {
        color: $c-black;
    }
}

.block__introduction__intro {
    @include rem(24px);
    line-height: calc(31 / 24);
    color: $c-white;

    .block__introduction--light & {
        color: $c-black;
    }
}

.block__cta {
    padding: 30px 10px 40px;
    background-color: $c-black;
}

.block__cta--light {
    background-color: $c-white;
    border: 1px solid $c-border;
}

.block--border-none {
    .block__cta--light {
        border: none;
    }

    .block__cta__body {
        max-width: 980px;
    }
}



    .block__cta__title {
        @include medium-title;
        max-width: 580px;
        margin: 0 auto 20px auto;
    }

    .block__cta__title,
    .block__cta__intro {
        color: $c-white;

        .block__cta--light & {
            color: $c-black;
        }
    }

    .block__cta__body {
        color: $c-white;

        .block__cta--light & {
            color: $c-richcopy;
        }
    }

    .block__cta__button {
        margin: 0 auto;
    }

    .block__cta__intro {
        opacity: 0.3;
        @include smallcaps;
        margin-bottom: 10px;
        max-width: 580px;
        margin: 0 auto 10px auto;
    }

    .block__cta__body {
        @include bodycopy;
        max-width: 580px;
        margin: 0 auto 30px auto;
    }

.block__showroom--information {
    padding: 40px 20px;
    background: $c-white;
    border: 1px solid $c-border;

    @include breakpoint(phablet) {
        padding: 30px;
    }

    svg {
        display: block;
        margin: 15px auto;
        stroke: $c-black;

        @include breakpoint(phablet) {
            margin: 10px auto;
        }
    }
}

    .block__showroom--information__title {
        @include medium-title;
        @include rem(30px);

        line-height: calc(36 / 30);

        @include breakpoint(phablet) {
            @include rem(20px);

            line-height: calc(24 / 20);
        }
    }

    .block__showroom--information__copy {

        p {
            @include rem(16px);

            line-height: calc(22 / 16);
            margin-bottom: 1em;

            &:last-child {
                margin-bottom: 0;
            }

            @include breakpoint(phablet) {
                @include rem(14px);

                line-height: calc(22 / 14);
            }
        }

        a {
            color: $c-brand;
            text-decoration: none;
            line-height: calc(22 / 16);

            &.plain-link {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    .block__showroom--information__times {

        p {
            margin-bottom: 10px;
            font-size: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        span {
            @include rem(16px);

            line-height: calc(22 / 16);
            display: inline-block;
            width: 50%;
            padding: 0 7px;

            @include breakpoint(phablet) {
                @include rem(14px);

                line-height: calc(22 / 14);
            }
        }
    }

.block__custom-listing__image {
    position: relative;
    @include aspect(1);
    background-size: cover;
    background-blend-mode: multiply;
    background-color: $c-background;
    background-position: center;
}

.block__custom-listing__overlay {
    position: absolute;
    color: $c-white;
}

.block__custom-listing__overlay--bottom {
    bottom: 0;
    left: 0;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    @include rem(16px);
    @include lightondark;

    @include breakpoint(phablet) {
        @include rem(14px);
    }

    .block__custom-listing__image--chair & {
        bottom: -10px;
    }

    span {
        @include tiny-title;
        @include rem(32px);
        font-weight: 700;
        font-style: italic;
        color: $c-white;
        display: block;

        @include breakpoint(phablet) {
            @include rem(24px);
        }
    }
}

.block__custom-listing__overlay--bottom-left {
    bottom: 0;
    left: 0;
    text-align: left;

    img {
        height: 70px;
        width: auto;
        margin: 12px;
    }
}

.block__custom-listing--wrap {
    padding: 0 5px;

    position: relative;
}

.block__custom-listing__description {
    padding: 20px 0;
    text-align: left;

    p {
        @include rem(15px);
    }
}

.block__custom-listing__details {
    padding-top: 20px;
    text-align: left;
    border-top: $c-grey 1px solid;
    overflow-x: auto;

    table {
        width: 100%;
    }

    tr {
        height: auto;
    }

    td {
        font-weight: bold;
    }

    p {
        min-height: 100px;

        @include breakpoint(phablet) {
            min-height: 120px;
        }
    }
}

.block__custom-listing--staggered-down {
    margin-top: 4%;
    @include breakpoint(mobile) {
        margin-top: 5px;
    }
}

.block__image-credit {
    span {
        color: #999;
    }

    font-size: 14px;
    padding: 1em 0;
}

.block__image-credit-right {
    text-align: right;
}

.block__image-credit--flush-bottom {
    padding-bottom: 0;
}

.block__paint_story__body {
    margin: 20px 0;
    padding: 30px 0;
    color: $c-richcopy;
}

.block__paint_story__set {
    margin: 20px 0;
    padding-bottom: 60px;
    display: flex;
    align-items: top;

    @include breakpoint(mobile) {
        flex-direction: column;
    }
}

.block__paint_story__item {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;


    @include breakpoint(mobile) {
        flex-direction: row;
    }
}

.block__paint_story__itemtitle {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin: 10px;
    line-height: normal;
}

.block__paint_story__itemtext {
    text-align: center;
}

.block--paint-map {
    padding: 0;
    margin-top: 10px;
}

.block--text-light {
    --block-text-colour: #{$c-white};
    --block-cta-text-colour: #{$c-dark};
}

.block--text-dark {
    --block-text-colour: #{$c-dark};
    --block-cta-text-colour: #{$c-white};
}

@import 'components/blocks/categories';
@import 'components/blocks/collection-inspiration';
@import 'components/blocks/craftsmanship';
@import 'components/blocks/how-it-works';
@import 'components/blocks/image-quote';
@import 'components/blocks/image-text';
@import 'components/blocks/large-image-text';
@import 'components/blocks/overlay-reviews';
@import 'components/blocks/product-carousel';
@import 'components/blocks/quad-callout';
@import 'components/blocks/quote';
@import 'components/blocks/split-feature';
@import 'components/blocks/twin-image-text';
@import 'components/blocks/video';
@import 'components/blocks/walkthrough-steps';
@import 'components/blocks/wide-text-image';
