.testimonial {
    text-align: center;
    margin: 50px 0;

    @include breakpoint(phablet) {
        margin: 30px 0 0;
    }
}
    .testimonial--heading {
        margin: 0;
        padding: 30px 0 20px;

        @include breakpoint(phablet) {
            padding: 0;
        }
    }
.testimonial__quote {
    @include apply-map($f-title);
    @include rem(26px);

    line-height: calc(36 / 26);
    max-width: 750px;
    margin: 0 auto;
    color: $f-title-color;
    text-transform: none;

    &:before {
        content: "‘";
    }
    &:after {
        content: "’";
    }

    @include breakpoint(phablet) {
        @include rem(24px);

        line-height: calc(28 / 24);
    }
}
    .testimonial__quote--heading {
        @include rem(60px);

        line-height: calc(72 / 60);
        max-width: 100%;

        @include breakpoint(phablet) {
            @include rem(24px);

            line-height: calc(28 / 24);
        }

        /*@include breakpoint(tablet) {
            @include rem(44px);
        }

        @include breakpoint(mobile) {
            @include rem(32px);
        }*/
    }

.testimonial__source {
    @include apply-map($f-smallprint);
    @include rem(12px);

    line-height: calc(15 / 12);
    text-transform: uppercase;
    color: rgba($c-black, 0.3);

    * + & {
        margin: 20px 0 0;
    }

    .testimonial__quote--heading + & {
        margin: 6px 0 0;

        @include breakpoint(phablet) {
            margin: 10px 0 0;
        }
    }
}
