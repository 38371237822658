@use "sass:math";

.stockists {}

    .stockists__showroom {}

        .stockists__showroom-link {
            position: relative;
            display: block;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100px;
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 70%);
                z-index: 5;
                opacity: .7;
            }
        }

        .stockists__showroom-img {
            @include aspect(math.div(5, 3));

            img {
                width: 100%;
                transition: transform 3s linear;
                transform: scale(1.01);

                .stockists__showroom:hover & {
                    transform: scale(1.1);
                }
            }
        }

        .stockists__showroom-actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 20px;
            left: 30px;
            right: 30px;
            z-index: 10;

            @include breakpoint(tablet) {

                left: 25px;
                bottom: 20px;
            }
        }

            .stockists__showroom-title {
                @include apply-map($f-title);
                @include rem(38px);

                flex-basis: 100%;
                line-height: 46px;
                color: $c-background;
                margin-bottom: 15px;

                @include breakpoint(tablet) {
                    @include rem(28px);

                    line-height: 34px;
                    margin-bottom: 5px;
                }
            }

            .stockists__showroom-text {
                @include apply-map($f-primary);
                @include rem(18px);

                line-height: 26px;
                color: $c-white;
                letter-spacing: 3px;
                text-transform: uppercase;

                @include breakpoint(tablet) {
                    @include rem(16px);

                    line-height: 26px;
                }
            }

            .stockists__showroom-icon {

                svg {
                    fill: $c-white;
                    transition: fill .3s ease;

                    .stockists__showroom:hover & {
                        fill: $c-brand;
                    }
                }
            }

    .stockists__wrap {
        position: relative;
        margin: 0 50px;

        @include breakpoint(phablet) {
            margin: 0;
        }
    }

    .stockists__status:not(:empty) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 20px;
        color: $c-white;
        background: $c-brand;
        z-index: 1;
    }

    .stockists__map {
        @include aspect(math.div(1027, 616));

        @include breakpoint(phablet) {
            @include aspect(1);
        }
    }

        .stockist__map-info {
            @include apply-map($f-primary);

            padding: 25px 5px 25px 25px;
            color: $c-block-copy;

            @include breakpoint(phablet) {
                padding: 10px;
            }
        }

            .stockist__map-info__name {
                @include apply-map($f-title);
                @include rem(30px);

                line-height: calc(22 / 30);
                color: $c-black;
            }

            .stockist__map-info__type {
                @include rem(14px);

                line-height: calc(18 / 14);

                * + & {
                    margin: 5px 0 0;
                }
            }

            .stockist__map-info__sub-title {
                @include apply-map($f-primary);
                @include rem(14px);

                line-height: calc(17 / 14);
                color: $c-dark-grey;
                font-weight: 500;

                * + & {
                    margin: 18px 0 0;
                }
            }

            .stockist__map-info__link {
                color: $c-brand;
            }

            .stockist__map-info__address {
                @include rem(14px);

                line-height: calc(18 / 14);
            }

            .stockist__map-info__contact {
                @include rem(14px);

                line-height: calc(18 / 14);

                a {
                    color: $c-block-copy;
                    text-decoration: none;
                    line-height: calc(18 / 14);

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

    .stockists__form {
        margin: 0;
        padding: 35px 40px 40px;
        background: $c-grey;

        @include breakpoint(tablet) {
            padding: 35px 15px 40px;
        }
    }

        .stockists__geocode {
            font-size: 0;

            @include breakpoint(tablet) {
                margin-bottom: 15px;
                display: flex;
                flex-wrap: wrap;
            }

            label {
                @include apply-map($f-primary);
                @include rem(14px);

                line-height: 17px;
                font-weight: 500;
                color: $c-dark-grey;
                display: block;
                margin: 0 0 10px;

                @include breakpoint(tablet) {
                    flex-basis: 100%;
                }
            }

            .field {
                display: inline-block;
                width: calc(100% - 155px);
                margin: 0;
                margin-right: 20px;
            }

            .button {
                display: inline-block;
                width: 135px;
            }
        }

        .stockists__option-separator {
            @include apply-map($f-primary);
            @include rem(14px);

            line-height: 44px;
            text-transform: uppercase;
            color: $c-block-copy;
            letter-spacing: 0.5px;
            font-weight: 500;

            @include breakpoint(tablet) {
                text-align: left;
            }
        }

        .js-geolocate {
            span {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

    .stockist__list {
        margin: 10px 0 90px;

        @include breakpoint(tablet) {
            margin-bottom: 30px;
        }
    }

        .stockist__group {

            .stockist__group + & {
                margin: 30px 0 0;
            }
        }

        .stockist__results,
        .stockist__country {
            @include apply-map($f-title);
            @include rem(20px);

            line-height: 24px;
            color: $c-dark-grey;
            margin: 0 0 10px;
        }

        .stockist__item {
            color: $c-block-copy;
            border-bottom: 1px solid $c-border;
        }

            .stockist__title {
                position: relative;
                margin: 0 25px 0 42px;

                &:before {
                    content: '';
                    position: absolute;
                    top: 16px;
                    left: -42px;
                    width: 25px;
                    height: 36px;
                    background-image: url('/images/map/marker-large.png');
                    background-size: cover;

                    .stockist__item--showroom & {
                        background-image: url('/images/map/marker-large-brand.png');
                    }
                }
            }

            .stockists__intro {
                @include apply-map($f-smallprint);
                @include rem(12px);

                line-height: calc(14 / 12);
                text-transform: uppercase;
                opacity: 0.3;

                * + & {

                    @include breakpoint(phablet) {
                        margin-top: 15px;
                    }
                }
            }

            .stockists__promo {
                @include aspect(math.div(16, 9));

                position: relative;

                a {
                    display: block;
                }

                & + .stockists__promo__wrapper {
                    margin: 50px 0 0;

                    @include breakpoint(phablet) {
                        margin: 0 -10px;
                    }
                }

                @include breakpoint(phablet) {
                    margin: 0 -10px;
                }
            }

            .stockists__promo__wrapper {
                display :flex;
            }

            .stockists__promo--subpromo {
                flex: 1;

                .stockists__promo--subpromo + & {
                    margin-left: 20px;
                }
            }

                .stockists__promo__title {
                    @include lightondark;

                    position: absolute;
                    right: 25px;
                    bottom: 25px;
                    left: 25px;
                    color: $c-white;
                    z-index: 1;

                    @include breakpoint(phablet) {
                        @include rem(20px);

                        line-height: calc(22 / 20);
                        right: 10px;
                        bottom: 10px;
                        left: 10px;
                    }
                }

                .stockists__gradient {
                    @include aspect(math.div(16, 9));

                    position: absolute;
                    top: 20%;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(180deg, rgba($c-black, 0) 0%, $c-black 100%);
                    opacity: 0.6;
                    transition: all 300ms;

                    a:hover & {
                        top: 0;
                        opacity: 0.8;
                    }
                }

                .stockist__wrap {
                    margin: 0 0 0 42px;

                    @include breakpoint(phablet) {
                        margin: 0;
                    }
                }

                .stockist__toggle {
                    position: absolute;
                    top: 50%;
                    right: -27px;
                    transform: translateY(-50%);

                    svg {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        margin: -4px 5px 0 0;
                        fill: $c-filter-grey;
                        transform: rotate(90deg);
                        transition: fill .3s ease, transform .2s ease;

                        .accordion--active & {
                            transform: rotate(270deg);
                            fill: $c-brand;
                        }

                        .stockist__item:hover & {
                            fill: $c-brand;
                        }
                    }
                }

                .stockist__sub-title {
                    @include apply-map($f-primary);
                    @include rem(14px);

                    line-height: calc(17 / 14);
                    color: $c-dark-grey;
                    font-weight: 500;

                    * + & {
                        margin: 15px 0 0;
                    }
                }

                .stockist__name {
                    @include apply-map($f-primary);
                    @include rem(16px);

                    line-height: 19px;
                    color: $c-dark-grey;
                    font-weight: 500;
                    display: flex;
                    align-items: center;

                    @include breakpoint(tablet) {
                        @include rem(14px);

                        line-height: 17px;
                    }
                }

                .stockist__distance {
                    @include rem(14px);

                    line-height: 24px;
                    font-weight: normal;
                    color: $c-block-copy;

                    &:before {
                        content: '-';
                        margin: 0 5px;
                    }
                }

                .stockist__paint-icon {
                    height: 25px;

                    svg {
                        margin-left: 20px;
                        fill: $c-black;
                    }
                }

                .stockist__type {
                    @include rem(14px);

                    line-height: 24px;
                }

                .stockist__address {
                    @include rem(14px);
                    color: $c-block-copy;

                    line-height: 18px;

                    @include breakpoint(tablet) {
                        @include rem(12px);
                    }
                }

                .stockist__address--inline {
                    color: $c-block-copy;
                    line-height: 18px;

                    * + & {
                        margin: 5px 0 0;
                    }

                    br {
                        display: none;
                    }
                }

                .stockist__contact {
                    @include rem(14px);
                    color: $c-block-copy;

                    line-height: 18px;

                    a {
                        color: $c-block-copy;
                        text-decoration: none;
                        line-height: 18px;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    @include breakpoint(tablet) {
                        @include rem(12px);
                    }
                }

                .stockist__collections {
                    color: $c-block-copy;

                    @include rem(14px);

                    line-height: 18px;

                    @include breakpoint(phablet) {
                        margin: 0 0 30px;
                    }

                    * {
                        line-height: 18px;
                    }

                    @include breakpoint(tablet) {
                        @include rem(12px);
                    }
                }
                .stockist__openingtimes {

                    @include rem(14px);

                    line-height: 18px;

                    @include breakpoint(phablet) {
                        margin: 0 0 30px;
                    }

                    * {
                        line-height: 18px;
                    }

                    @include breakpoint(tablet) {
                        @include rem(12px);
                    }
                }

                .stockist__location {
                    @include aspect(math.div(590, 388));

                    background: $c-border;
                }

                .stockist__directions {
                    @include rem(14px);

                    line-height: 18px;
                    color: $c-block-copy;
                    margin: 5px 0 0;

                    @include breakpoint(tablet) {
                        @include rem(12px);
                    }
                }
