// Wraps the select in a div.multi-select-replace
  .multi-select-replace {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    &.multi-select-replace--focused::after {
      transform: rotate(-180deg);
    }

    // Use native for functionality but hide visually
    select {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: -1000px;
      width: 0;
      height: 0;
      margin: 0;
      font-size: 1rem;
      line-height: 1;
      background: $c-white;
      border: 0;
      border-radius: 0;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    svg {
        display: block;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
  }

  .multi-select-replace--active {
    background: $c-white;
  }

    .multi-select-replace__icon {
        stroke: $c-black;

        .multi-select-replace--focused & {
          transform: translateY(-50%) rotate(180deg);
        }
    }

  .multi-select-replace__value--disabled {
    color: lighten(#999, 35%);
  }

  .multi-select-replace__search {
    display: none;
    padding: 0;
    margin: 0;
    padding: 10px;
    padding: calc(0.75em - 1px) 1em;
    padding-right: calc(2em + 13px);
    line-height: calc(1 * 45 / 14);
    border: none;
    box-sizing: border-box;
    width: 100%;
  }

  .multi-select-replace__drop {
    position: absolute;
    z-index: 20;
    top: calc(100% + 11px);
    left: 0;
    display: none;
    background: $c-white;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.15);

    @include breakpoint(laptop) {
      min-width: 100%;
    }

    @include breakpoint(phablet) {
      position: relative;
      top: auto;
      max-width: 100%;
      box-shadow: none;
    }

    .customise__filters .filterable__dimension--fabric-colour & {
      top: calc(100% + 1px);
    }

    .filterable__dimension--custom-fabric-type &,
    .filterable__dimension--fabric-colour & {
      @include breakpoint(phablet) {
        position: absolute;
        padding: 0 15px;
        box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.15);
      }
    }
  }

  .multi-select-replace__drop--large {

    @include breakpoint(nav-squeeze) {
      left: -180px;
    }

    @include breakpoint(tablet) {
      left: -160px;
    }

    @include breakpoint(phablet) {
      left: 0;
    }
  }

  .multi-select-replace__drop--right-align {
    left: initial;
    right: 0;
  }

  .multi-select-replace__list {
    overflow: auto;
    columns: 4;
    padding: 15px 25px 25px;

    @include breakpoint(phablet) {
      max-height: none;
      padding: 0;
      padding-top: 10px;
      columns: 2;
    }
  }

  .multi-select-replace__list--small {
    columns: 2;
  }

  .multi-select-replace__list--medium {
    columns: 3;

    @include breakpoint(phablet) {
      columns: 2;
    }
  }

  .multi-select-replace__list--large {

    @include breakpoint(nav-squeeze) {
      columns: 3;
    }

    @include breakpoint(tablet) {
      columns: 2;
    }
  }

  .multi-select-replace__list-item {
    position: relative;
    overflow: auto;
    transform: translateZ(0);
    display: inline-block;
    width: 100%;

    a {
      @include rem(14px);

      display: block;
      padding: 4px 10px 4px 40px;
      line-height: 14px;
      text-decoration: none;
      cursor: pointer;
      color: $c-dark-grey;
      white-space: nowrap;

      @include breakpoint(mobile) {
        white-space: normal;
      }
    }

    &:not(.multi-select-replace__list-item--selected):not(.multi-select-replace__list-item--overview) svg {
      display: none;
    }

    svg {
      width: 12px;
      height: 10px;
    }
  }

  .multi-select-replace__list-item--overview {
    display: flex;

    @include breakpoint(mobile) {
      border: none;
    }
  }

  .multi-select-replace__list-item--overview-1 {
    display: none;

    @include breakpoint(mobile) {
      display: flex;
    }
  }

  .multi-select-replace__list-item--overview-2 {

    @include breakpoint(mobile) {
      display: none;
    }
  }

    .multi-select-replace__status {
        position: absolute;
        left: 0;
        top: 50%;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        border: 1px solid $c-multi-select-border;

        svg {
            right: 50%;
            transform: translate(50%, -50%);
        }

        .multi-select-replace__list-item--selected & {
          border: 1px solid $c-dark-grey;
        }
    }

    .multi-select-replace__swatch {
      position: absolute;
      top: 50%;
      left: 28px;
      width: 18px;
      height: 18px;
      background: $c-white;
      border: 1px solid $c-border;
      border-radius: 100%;
      transform: translateY(-50%);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: currentColor;
        border: 1px solid $c-white;
        border-radius: inherit;
      }

      + a {
        padding-left: 28px + 20px + 7px;
      }
    }

    .multi-select-replace__swatch--multicoloured {

        &:after {
            background: url(/images/multicoloured.png) center no-repeat;
            background-size: 100%;
        }
    }

    .multi-select-replace__overview {
      @include rem(12px);

      line-height: 26px;
      flex: 1 1 auto;
      width: 100px;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-style: italic;

      &[data-is-empty="true"] {
        color: rgba($c-black, 0.3);
      }
    }

    .multi-select-replace__mobile-overview {
      @include rem(14px);

      line-height: calc(40 / 14);
      flex: 1 1 auto;
      padding: 0 15px;
      text-align: center;
      font-weight: 700;

      &[data-is-empty="true"] {
        color: rgba($c-black, 0.3);
      }
    }

    .multi-select-replace__action {
      @include font(12px, 26px);

      display: block;
      padding: 20px 0 0 25px;
      cursor: pointer;
      font-weight: 500;
      text-transform: none;
      text-decoration: underline;
      color: $c-dark-grey;

      @include breakpoint(phablet) {
        margin-top: 10px;
        padding: 0;
      }
    }

    .multi-select-replace__action--clear {
      display: none;

      [data-all="true"] & {
        display: block;
      }
    }

    .multi-select-replace__action--all {

      [data-all="true"] & {
        display: none;
      }
    }

  .multi-select-replace__list-item--image {
    a {
      padding-left: 36px;
    }
  }

  .multi-select-replace__list-item--current {
    a {
      color: $c-white;
      background: #666;
    }
  }

  .multi-select-replace__list-item--disabled {
    a {
      pointer-events: none;
      color: #ccc;
    }
  }

  .multi-select-replace__list-item--hidden {
    display: none;
  }

  .multi-select-replace--multiple {
    .multi-select-replace__list-item--selected {
      background: $c-white;
    }
  }

  .multi-select-replace__list-item {
    &.multi-select-replace__list-item--image {
      img {
        position: absolute;
        top: 50%;
        left: 10px;
        width: 16px;
        height: 16px;
        padding: 1px;
        transform: translateY(-50%);
        border: 1px solid $c-multi-select-image;
      }

      &.multi-select-replace__list-item--selected {
        img {
          border-color: $c-black;
        }
      }
    }
  }

  .multi-select-replace__elevator-button {
    content: "";
    width: 25px;
    height: 25px;
    background-color: $c-white;
    background-position: center center;
    background-repeat: no-repeat;
    display: none !important;
    position: absolute;
    right: 5px;
    border: 1px solid $c-black;
    border-radius: 100%;
    opacity: 0.3;

    .touchevents & {
      display: block;
    }
  }

  .multi-select-replace__elevator-button-click {
    opacity: 1;
    transition: opacity;
  }

  .multi-select-replace__elevator-button--up {
    top: 5px;
  }

  .multi-select-replace__elevator-button--down {
    bottom: 5px;

  }

  // When the native select is focused .multi-select-replace--focused class is appended
  .multi-select-replace--focused {
    &.multi-select-replace--search {
      .multi-select-replace__value {
        display: none;
      }
      .multi-select-replace__search {
        display: block;
      }
    }

    .multi-select-replace__drop {
      display: block;
    }
  }

  // When the native select is disabled .multi-select-replace--disabled class is appended
  .multi-select-replace--disabled {
    background: #f5f5f5;

    .multi-select-replace__value {
      color: lighten(#999, 35%);
    }

    &::after {
      opacity: 0.2;
    }

    .multi-select-replace__value {
      color: #999;
    }
  }

  .select-inline {
    display: inline-block;
    vertical-align: top;

    .multi-select-replace {
      height: auto;
      text-transform: none;
      text-indent: 0;
      font-size: inherit;
      box-shadow: none;
      background: none;
    }

    .multi-select-replace {

      .context-bar__right & {
        border: 1px solid $c-black;
        box-sizing: border-box;
      }

      &:after {
        top: 1px;
        right: 0;
        color: $c-black;

        .context-bar__right & {
          top: 5px;
          right: 5px;
        }
      }
    }

    .multi-select-replace__drop {
      top: 25px;
    }

    .multi-select-replace__value {
      line-height: 1;
      padding: 0 20px 0 0;
      text-transform: none;
      font-size: inherit;
      border: none !important;

      .context-bar__right & {
        @include rem(15px);

        padding-left: 5px;
        color: #505050;
      }
    }

    .multi-select-replace__list-item {
      display: block;
      white-space: nowrap;
      word-break: keep-all;

      a {
        border: none;
        padding: 5px 10px 5px 30px;
      }

      .context-bar__right & {
        margin: 0;
      }
    }
  }

.multi-select-replace__back {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;

  svg {
    stroke: $c-black;
    transform: translateY(-50%) rotate(90deg);
  }
}
