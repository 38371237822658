.callout {
    @include rem(14px);
    color: $c-label;
    line-height: 1.4;
    padding: 20px;
    border: 1px $c-border solid;
    border-left: 3px $c-brand solid;
    width: 100%;
    border-radius: 3px;

    margin: 20px 0 0;

    .callout__text-highlight {
        color: $c-brand;
        font-weight: 700;
    }

    p, a {
        line-height: 1.4;
    }

    a {
        color: $c-secondary;
    }
}
