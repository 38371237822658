.filter-range {
    min-width: 265px;

    &:not(:last-child) {
        margin-right: 45px;
    }

    @include breakpoint(laptop) {
        margin: 0;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 20px
        }  
    }
}

    .filter-range__title {
        @include smallcaps;
        @include rem(14px);

        text-align: left;
        display: block;
        margin-bottom: 10px;
        line-height: 16px;
        color: $c-dark-grey;
        text-transform: capitalize;

        @include breakpoint(laptop) {
            margin-bottom: 5px;
        }
    }

    .filter-range__slider {
        margin: 20px 10px;

        @include breakpoint(phablet) {
            display: none;
        }
    }

    .filter-range__field {
        position: relative;
    }

        .filter-range__label {
            @include rem(12px);

            position: absolute;
            top: 7px;
            left: 16px;
            line-height: 14px;
            color: $c-block-copy;

            @include breakpoint(laptop) {
                left: 6px;
            }
        }

        .filter-range__input {
            @include rem(14px);

            line-height: 16px;
            width: 100%;
            height: 45px;
            padding: 15px 15px 0 15px;
            color: $c-dark-grey;
            border: 1px solid $c-filter-grey;

            @include breakpoint(laptop) {
                padding: 15px 5px 0 5px;
            }
        }

        .filter-range__unit {
            @include rem(12px);

            position: absolute;
            right: 16px;
            bottom: 5px;
            color: $c-block-copy;
            
            @include breakpoint(laptop) {
                right: 5px;
            }
        }
