.contact {}

    .contact__content {
        float: right;
        position: relative;
        left: 16.6666%;
        width: 100%;
        max-width: calc(#{$site-max-width} / 2);
        margin: 100px 0;
        padding: 0 40px 0 0;

        @include breakpoint(phablet) {
            float: none;
            left: auto;
            width: auto;
            max-width: 100%;
            margin: 30px 10px 40px;
            padding: 0;
        }
    }

        .contact__title {

            @include breakpoint(phablet) {
                @include rem(40px);

                line-height: calc(48 / 40);
            }
        }

        .contact__copy {
            @include rem(24px);

            line-height: calc(31 / 24);

            .contact__title + & {
                margin: 28px 0 0;

                @include breakpoint(phablet) {
                    margin: 10px 0 0;
                }
            }

            @include breakpoint(phablet) {
                @include rem(19px);

                line-height: calc(24 / 19);
            }

            p {
                line-height: calc(31 / 24);
            }

            :last-child {
                margin-bottom: 0;
            }
        }

        .contact__footnote {
            @include rem(16px);

            line-height: calc(22 / 16);
            color: $c-footnote;

            .contact__title + &,
            .contact__copy + & {
                margin: 10px 0 0;
            }

            :last-child {
                margin-bottom: 0;
            }
        }

        .contact__form {
            margin: 40px 0 0;

            @include breakpoint(phablet) {
                margin: 20px 0 0;
            }
        }

            .contact__form__footer {
                margin: 30px 0 0;

                @include breakpoint(phablet) {
                    margin: 20px 0 0;
                }
            }

                .contact__privacy-statement {
                    @include rem(14px);

                    line-height: calc(16 / 14);
                    color: $c-footnote;

                    @include breakpoint(tablet) {
                        margin: 0 0 20px;
                    }

                    @include breakpoint(phablet) {
                        margin: 0 0 10px;
                    }
                }

        .is-flex {
            display: flex;
        }

        .contact__map-container {
            margin: 0 0 0 16.6666%;
            padding: 0 0 0 15px;
            display: flex;
            flex-basis: 100%;
        }

            .contact__map {
                flex-basis: 100%;

                .no-js & {
                    @include aspect(1);
                }
            }
