.product-details {}

    .product-details__imgwrap {
        @include breakpoint(tablet) {
            margin-bottom: 25px;
        }
    }

        .product-details__img {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                bottom: 0;
                height: 180px;
                opacity: 0.5;
                background: $c-black;
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, $c-black 100%);

                @include breakpoint(tablet) {
                    height: 100px;
                }
            }
        }

    .product-details__content {
        padding-right: 80px;

        @include breakpoint(tablet) {
            padding: 0;
        }

        p {
            @include apply-map($f-primary);
            @include rem(14px);
    
            line-height: 22px;
            color: $c-block-copy;
            margin-bottom: 15px;
        }
    }

    .product-details__content--reverse {
        padding-right: 0;
        padding-left: 80px;

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

        .product-details__title {
            @include apply-map($f-title);
            @include rem(36px);

            line-height: 43px;
            color: $c-dark-grey;
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                @include rem(32px);

                line-height: 38px;
                margin-bottom: 10px;
            }
        }

// Re-order for mobile. Should this be done here?

.l-two-thirds {

    @include breakpoint(tablet) {
        .product-details & {
            order: -1;
        }
    }
}