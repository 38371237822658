.circular-action {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0 10px;
}

    .circular-action__action {
        display: flex;
        align-items: stretch;
        text-decoration: none;
        cursor: pointer;
    }

    .circular-action__action--m-show {
        display: none;

        @include breakpoint(tablet) {
            display: flex;
        }
    }

    .circular-action__action.js-cylindo-ar-open-desktop {
        display: none;

        .cylindo-ar & {
            display: flex;
        }
    }

    .circular-action__action--m-hide {
        @include breakpoint(tablet) {
            display: none;

            .cylindo-ar & {
                display: none;
            }
        }
    }

        .circular-action__icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $c-brand;
            display: grid;
            place-items: center;
            z-index: 1;
            transition: background-color 150ms ease-in;

            svg {
                fill: $c-white;
            }

            .circular-action__action:hover & {
                background-color: $c-black;
            }
        }

        .circular-action__text {
            @include font(14px, 26px);

            font-weight: 500;
            border-radius: 20px;
            padding-left: 40px;
            padding-right: 25px;
            margin-left: -30px;
            display: flex;
            align-items: center;
            background-color: $c-background;

            @include breakpoint(tablet) {
                @include font(13px, 26px);
            }
        }
