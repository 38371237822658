.block--overlay-reviews {
   position: relative;
   overflow: hidden;
}

    .overlay-reviews__overlay-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: 0.2;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .overlay-reviews__reviews {
        position: relative;
        z-index: 1;

        & .wrap {
            display: flex;
            align-items: center;
            justify-items: center;
            gap: 30px;

            @include breakpoint(tablet) {
                flex-direction: column;
                align-items: unset;
            }
        }   
    }

        .overlay-reviews__main {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 40px;
            flex: 1 1 75%;
            overflow: hidden;

            @include breakpoint(tablet) {
                flex: 1 1 100%;
                gap: 15px;
            }
        }

            .overlay-reviews__header {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                width: 100%;
            }

                .overlay-reviews__title {
                    color: var(--block-text-colour);
                    @include font(60px, 72px);
                    @include blackFont;

                    @include breakpoint(tablet) {
                        margin-bottom: 10px;
                        @include font(30px, 36px);
                    }
                }

                .overlay-reviews__controls {
                    display: flex;
                    justify-items: center;
                    align-items: center;
                    gap: 25px;

                    @include breakpoint(mobile) {
                        display: none;
                    }

                    .slick-arrow {
                        position: static;

                        svg {
                            fill: var(--block-text-colour);
                        }
                    }
                }

                    

            .overlay-reviews__container {
                width: 100%;

                .slick-track {
                    align-items: flex-start;
                }
            }

                .overlay-reviews__container {
                    padding-left: 40px;
                    position: relative;

                    @include breakpoint(tablet) {
                        padding-left: 25px;
                    }

                    &:before {
                        content: '';
                        height: 100%;
                        width: 1px;
                        background-color: var(--block-text-colour);
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0.5;
                    }
                }

                    .overlay-reviews__content {
                        padding: 0px 20px;
                    }

                        .overlay-reviews__stars {
                            display: flex;
                            flex-wrap: nowrap;
                            margin-bottom: 13px;

                            svg {
                                width: 21px;

                                &:not(:first-of-type) {
                                    margin-left: 2px;
                                }
                            }
                        }

                        .overlay-reviews__copy {
                            @include apply-map($f-primary);
                            @include rem(14px);

                            line-height: 22px;
                            color: $c-white;
                            margin-bottom: 10px;
                            overflow: hidden;
                            max-height: calc(22px * 5);
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 5;
                        }

                        .overlay-reviews__author {
                            @include apply-map($f-primary);
                            @include rem(16px);
                            line-height: 16px;
                            font-weight: 500;
                            color: $c-white;
                        }

        .overlay-reviews__side {
            flex: 1 1 25%;
            display: grid;
            justify-items: center;
            align-items: center;

            & > * {
                grid-column: 1;
                grid-row: 1;
            }

            @include breakpoint(tablet) {
                flex: 1 1 100%;
                margin: 25px 0;
            }

            @include breakpoint(mobile) {
                margin: 0;
            }
        }

            .overlay-reviews__side__image {
                filter: brightness(0.5);
                z-index: 1;
                height: 100%;
                width: 100%;
                aspect-ratio: 9/ 10;

                @include breakpoint(tablet) {
                    aspect-ratio: 9 / 4;
                    height: auto;
                }
            }

            .overlay-reviews__side__text {
                z-index: 2;
                padding: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @include breakpoint(tablet) {
                    padding: 0 20%;
                }
            }

                .overlay-reviews__side__string {
                    @include apply-map($f-primary);
                    @include rem(18px);

                    text-transform: uppercase;
                    line-height: 26px;
                    letter-spacing: 3px;
                    font-weight: 600;
                    color: $c-white;
                    margin-bottom: 10px;
                    text-align: center;
                }

                .overlay-reviews__overall {
                    a {
                        display: flex;
                        justify-content: center;
                        gap: 3px;
                        
                        svg {
                            width: 32px;
                            height: 31px;
                        }
                    }
                }

                .overlay-reviews__side__total {
                    @include rem(14px);

                    color: $c-white;
                    line-height: 22px;
                    margin: 10px 0 5px;
                    font-weight: 400;
                    white-space: nowrap;
                    text-align: center;

                    span {
                        font-weight: 600;
                    }
                }

                .overlay-reviews__side__logo {
                    img {
                        max-width: 100px;
                    }
                }
                