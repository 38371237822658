.basket__error {
    margin: 0;
    padding: 0;
}

.basket-error {
    @include rem(14px);
    line-height: 1.4;
    padding: 10px 15px;
    border: 1px $c-border solid;
    border-left: 3px $c-brand solid;
    border-radius: 3px;
    display: block;
    margin: 20px 40px 10px 40px;

    p {
        color: $c-brand;
        font-weight: bold;
    }
}
