.block--image-quote {
    padding: 0;
    margin: 80px 0;

    @include breakpoint(tablet) {
        margin: 40px 0;
    }

    &.block--reverse {
        .block__image {
            order: -1;

            @include breakpoint(tablet) {
                order: 1;
            }
        }

        .block__content {
            padding-right: 80px;
            padding-left: 40px;
            border-right: none;
            border-left: 1px solid var(--block-text-colour);
            margin-right: 0;
            margin-left: 70px;

            @include breakpoint(tablet) {
                margin: 40px auto 15px;
                padding: 15px 0 20px 25px;
            }

            svg.icon-quote {
                left: unset;
                right: 70px;
                transform: scale(1) rotate(180deg);

                @include breakpoint(tablet) {
                    right: 0;
                }
            }
        }
    }

    .block__grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            padding: 0 $site-gutter-mobile;
            max-width: $tab-max-width;
            margin: 0 auto;
        }
    }

        .block__image {
            aspect-ratio: 3 / 2;

            @include breakpoint(tablet) {
                margin-bottom: 25px;
            }
        }

        .block__content {
            margin-right: 70px;
            padding: 50px 40px 30px 80px;
            border-right: 1px solid var(--block-text-colour);
            position: relative;

            @include breakpoint(tablet) {
                margin: 40px 0 15px;
                padding: 15px 0 20px 25px;
                border-right: none;
                border-left: 1px solid var(--block-text-colour);
            }

            blockquote {
                @include font(24px, 33px);

                color: var(--block-text-colour);
                margin-bottom: 25px;

                @include breakpoint(tablet) {
                    @include font(18px, 28px);

                    margin-bottom: 15px;
                    max-width: 85%;
                }
            }

            cite {
                @include semiboldText;
                @include font(18px, 28px);

                color: var(--block-text-colour);

                @include breakpoint(tablet) {
                    @include font(16px, 28px);
                }
            }

            svg.icon-quote {
                position: absolute;
                top: -40px;
                left: 70px;
                transform: scaleX(-1) rotate(180deg);
                fill: var(--block-text-colour);
                opacity: 0.2;
                width: 190px;
                height: auto;

                @include breakpoint(tablet) {
                    width: 130px;
                    left: unset;
                    right: 0;
                    top: -35px;
                }
            }
        }
}
