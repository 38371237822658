
.overlay.overlay--flyout {
    .overlay__bg {
        background-color: rgba(0,0,0, .5);
    }

    .overlay__close {
        top: 30px;

        @include breakpoint(phablet) {
            top: 25px;
        }

        @include breakpoint(phablet) {
            left: -24px;
        }
    }
}
