.product-warning {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: 10;

    @include breakpoint(tablet) {
        top: unset;
        bottom: 30px;
        width: 100%;
        justify-content: center;
    }
}

    .product-warning__icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $c-dark;
        display: grid;
        place-items: center;
        z-index: 1;

        svg {
            fill: $c-white;
        }
    }

    .product-warning__text {
        @include font(14px, 26px);

        font-weight: 500;
        border-radius: 20px;
        padding-left: 45px;
        padding-right: 25px;
        margin-left: -35px;
        display: flex;
        align-items: center;
        background-color: $c-white;
    }
