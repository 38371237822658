.store-location {
    margin-bottom: 120px;

    @include breakpoint(phablet) {
        margin-bottom: 0px;
        position: relative;
        padding: 50px 0;

        &::after {
            content: '';
            position: absolute;
            background: $c-background;
            top: 0;
            bottom: 0;
            right: -$site-gutter-mobile;
            left: -$site-gutter-mobile;
            z-index: -1;
        }
    }
}