.fabrics {
    color: $c-dark;
}

    .fabrics__header {
        padding: 25px;
        border-bottom: 1px solid $c-background;

        @include breakpoint(phablet) {
            padding: 22px 30px;
            border-bottom: none;
        }

        svg {
            display: block;
            margin: 0 auto;
            fill: currentColor;

            @include breakpoint(phablet) {
                width: 182px;
                height: 16px;
            }
        }
    }

    .fabrics__content {
        max-width: 810px + 60px;
        margin: 0 auto;
        padding: 60px 30px 100px;

        @include breakpoint(phablet) {
            padding: 13px 28px 38px;
        }
    }

        .fabrics__title {
            @include apply-map($f-title);
            @include font(32px, 38px);

            margin-bottom: 24px;
            text-align: center;

            @include breakpoint(phablet) {
                @include font(28px, 34px);

                margin-bottom: 40px;
            }
        }

        .fabrics__group {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

            .fabrics__type {
                @include apply-map($f-title);
                @include font(20px, 24px);

                margin-bottom: 15px;
            }

            .fabrics__options {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                margin: -5px;
            }

                .fabrics__fabric {
                    width: 72px;
                    margin: 5px;
                }

                    .fabrics__image {
                        @include aspect(1);

                        margin-bottom: 10px;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .fabrics__name {
                        @include apply-map($f-title);
                        @include font(14px, 16px);

                        text-align: center;
                    }
