$reset-outline: true !default;

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    text-align: inherit;
    vertical-align: baseline;
}
body {
    line-height: 1;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
    display:block;
}
nav ul {
    list-style:none;
}
button {
    font-family: inherit;
    border-radius: 0;
}
a img,
:link img,
:visited img {
    border: 0;
}

@if $reset-outline {
    a:active,
    a:focus,
    input:active {
        outline: 0;
    }
}

a:active,
a:focus,
input:focus {
    -moz-outline-style: none;
}
ins {
    text-decoration: none;
}
del {
    text-decoration: line-through;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
ol, ul {
    list-style: none;
}
strong, b {
    font-weight: bold;
}
em, i {
    font-style: italic;
}
blockquote, q {
    quotes: none;
}
q::before,
q::after,
blockquote::before,
blockquote::after {
    content: "";
    content:none;
}
table {
    border-collapse: collapse;
    border-spacing:0;
}
img {
    vertical-align: bottom;
}
.clearing {
    clear: both;
}
.clearafter:after,
.cf:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
    visibility: hidden;
}
