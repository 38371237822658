.accordion {
    border-bottom: 1px solid $c-filter-grey;
    position: relative;

    .accordion + & {
        margin-top: -1px;
    }

    .richtext :last-child {
        margin-bottom: 0;
    }
}

.accordion--border {
    border-top: 1px solid $c-filter-grey;
}

    .accordion__title {
        display: block;
        position: relative;
        padding: 20px 0;

        .stockist__item & {
            padding: 15px 0 10px;
        }

        .js & {
            cursor: pointer;
        }

        & > svg {
            position: absolute;
            top: 50%;
            right: 0;
            margin: -4px 0 0;
            stroke: $c-black;
        }

        .icon-more {

            .accordion--active & {
                display: none;
            }
        }

        .icon-less {
            display: none;

            .accordion--active & {
                display: block;
            }
        }
    }

    .accordion__wrap {
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease;
    }

    .accordion--active .accordion__wrap {
        max-height: 2000px;
    }

        .accordion__body {
            padding: 0 0 20px;

            @include breakpoint(phablet) {
                padding: 0 0 15px;
            }
        }