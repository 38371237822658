.wishlist-item {
    display: flex;

    & > * {
        flex: 1;
    }
}

.wishlist-item--unavailable {

    .button {
        display: none;
    }
}

    .wishlist-item__image {
        @include aspect(1);

        padding-right: 30px;

        @include breakpoint(tablet) {
            padding-right: 20px;
            flex-grow: 1.2;
        }

        img {
            max-width: 100%;
            height: auto;

            .wishlist-item--unavailable & {
                opacity: 0.5;
            }
        }
    }

        .wishlist-item__link {
            @include font(12px, 22px);

            color: $c-dark;
            display: block;
            text-align: center;
            margin-top: 5px;
            transition: color .2s ease;

            &:hover {
                color: $c-block-copy;
            }
        }

    .wishlist-item__content {
        padding-right: 40px;

        @include breakpoint(tablet) {
            padding: 0;
            flex-grow: 2;
        }

        .wishlist-item--unavailable & {
            & > :not(.wishlist-item__tooltip) {
                opacity: 0.5;
            }
        }
    }

        .wishlist-item__title {
            margin-bottom: 10px;

            a {
                @include apply-map($f-title);
                @include font(26px, 31px);

                color: $c-dark;
                text-decoration: none;

                @include breakpoint(tablet) {
                    @include font(18px, 22px);
                }
            }

            @include breakpoint(tablet) {
                margin-bottom: 5px;
            }
        }

        .wishlist-item__subtitle {
            @include font(14px, 26px);

            color: $c-block-copy;
            margin-bottom: 5px;

            @include breakpoint(tablet) {
                @include font(12px, 22px);
            }
        }

        .wishlist-item__detail {
            width: 100%;
            margin-bottom: 10px;
        }

            .wishlist-item__modular {
                @include font(12px, 14px);

                color: $c-block-copy;

                span {
                    @include apply-map($f-title);
                    @include font(14px, 17px);

                    margin-right: 3px;
                    color: $c-dark;
                }
            }

            .wishlist-item__modular-price {
                @include semiboldText;
                @include font(12px, 26px);

                text-align: right;
                color: $c-block-copy;

            }

        .wishlist-item__tooltip {
            @include font(14px, 20px);

            color: $c-dark;
            display: inline-flex;
            align-items: center;

            svg {
                margin-top: 2px;
                margin-right: 10px;
                fill: $c-dark;
                flex-shrink: 0;
            }

            @include breakpoint(tablet) {
                @include font(12px, 18px);
            }
        }

        .wishlist-item__price {
            @include font(22px, 27px);
            @include semiboldText;

            color: $c-dark;
            letter-spacing: 0.5px;
            margin-bottom: 13px;

            @include breakpoint(tablet) {
                @include font(14px, 17px);
            }
        }
