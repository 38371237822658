.rollover {
    position: absolute;
    opacity: 0;
    transition: opacity .3s ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 1;
        }
    }
}
