.block--large-image-text {
    color: var(--block-text-colour);

    @include breakpoint(mobile) {
        padding: 0;
    }

    .block__text {
        padding-top: 49px;
        padding-bottom: 65px;

        @include breakpoint(phablet) {
            padding-top: 36px;
            padding-bottom: 35px;
        }
    }

        .block__title {
            @include font(120px, 120px);
            @include blackFont;
            color: var(--block-text-colour);
            position: relative;
            z-index: 1;
            margin: 0;

            @include breakpoint(tablet) {
                @include font(90px, 90px);
            }

            @include breakpoint(mobile) {
                @include font(40px, 48px);
            }
        }

        .block__image {
            display: block;
            margin: -35px auto 0;
            max-width: 1060px;

            @include breakpoint(tablet) {
                max-width: 100%;
            }

            @include breakpoint(mobile) {
                margin-top: -10px;
            }
        }

        .block__body {
            position: relative;
            max-width: $site-max-width;
            margin: 45px auto 0;
            padding-left: 55px;
            max-width: 1000px;

            &:before {
                content: '';
                height: 100%;
                width: 1px;
                background-color: var(--block-text-colour);
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.5;
            }

            @include breakpoint(tablet) {
                margin: 20px auto 0;
                max-width: 100%;
            }

            @include breakpoint(phablet) {
                padding-left: 30px;
                padding-right: 10px;
            }
        }

        .block__body p {
            padding: 10px 0;
            display: inline;
            @include font(20px, 30px);

            @include breakpoint(tablet) {
                @include font(14px, 24px);
            }

            @include breakpoint(phablet) {
                display: block;
            }
        }

        .block__body a {
            display: inline-block;
            margin-left: 5px;

            @include breakpoint(phablet) {
                display: block;
                margin-left: 0;
                margin-top: 10px;
            }

            span {
                vertical-align: baseline;
            }
        }

    .block__carousel {
        margin-top: 55px;

        @include breakpoint(phablet) {
            margin-top: 25px;
        }
    }

        .recommended-products {
            display: grid;
            grid-template-areas:
                "heading heading controls"
                "carousel carousel carousel";
            grid-template-columns: 40% 40% 20%;

            @include breakpoint(phablet) {
                grid-template-areas:
                    "heading"
                    "carousel";
                    grid-template-columns: 100%;
            }
        }

        .slick-arrow {
            position: static;
        }

        .product-recommended__title {
            @include apply-map($f-title);
            @include rem(40px);
            line-height: 48px;
            color: $c-brand-black;
            margin-bottom: 15px;
            display: inline-block;
            grid-area: heading;

            @include breakpoint(tablet) {
                @include rem(25px);
                line-height: 30px;
            }
        }

        .product-recommended__controls {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            grid-area: controls;
            place-self: end end;
            margin-bottom: 24px;

            @include breakpoint(tablet) {
                margin-bottom: 20px;
            }

            @include breakpoint(phablet) {
                display: none;
            }
        }

        .product__recommended {
            grid-area: carousel;

            @include breakpoint(phablet) {
                display: flex;
                gap: 20px;
                overflow-x: auto;
                scroll-snap-type: x proximity;
                margin: 0 calc($site-gutter-mobile * -1);
                padding: 0 $site-gutter-mobile;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */

                &::-webkit-scrollbar {
                    display: none;
                }

                & > * {
                    flex-basis: 240px;
                    flex-shrink: 0;
                }
            }
        }

        .product-listing__link:hover {
            color: $c-brand;
        }

        .product-listing__from,
        .product-listing__meta,
        .product-listing__colourways__list .product-listing__meta span {
            color: $c-mid-grey;
            @include rem(12px);
            font-weight: 400;
        }

        .product-listing-price--normal {
            color: $c-brand-black;
            @include font(16px, 24px);
            font-weight: 400;

            @include breakpoint(tablet) {
                @include font(14px, 22px);
            }
        }

        .product-listing__price--normal,
        .product-listing__price--old,
        .product-listing__price--new {
            margin: 0;
            line-height: 1;
        }


        .product-listing__colourways__item {
            display: none;
        }
    }
