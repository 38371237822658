@use "sass:math";

.image-grid__main {
    display: flex;
    gap: 20px;

    @include breakpoint(tablet) {
        flex-direction: column;
    }
}

.image-grid {
    flex: 1 1 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-content: space-between;
    gap: 20px;

    .store-location & {
        display: block;
    }

    @include breakpoint(tablet) {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 20px;
        grid-template-rows: auto;
        padding-inline: $site-gutter-mobile;
        overflow: hidden;

        .store-location & {
            display: block;
        }
    }

    @include breakpoint(phablet) {
        display: flex;
        gap: 20px;
        overflow-x: auto;
        scroll-snap-type: x proximity;
        padding: 0 $site-gutter-mobile;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        & > * {
            flex-basis: 240px;
            flex-shrink: 0;
        }
    }
}

    .image-grid__container {
        position: relative;
        flex: 1 1 50%;
        order: -1;

        @include breakpoint(tablet) {
            flex: 1 1 100%;
        }
    }

    .image-grid__container--text {
        border-right: none;

        @include breakpoint(tablet) {
            margin-top: 0;
        }

        @include breakpoint(phablet) {
            margin-top: 20px;
        }
    }

    .image-grid__container--0 {
        grid-row: 1/3;
        grid-column: 1/3;

        .inspiration &,
        .store-location & {
            grid-column: 3/5;

            @include breakpoint(tablet) {
                grid-column: 1/3;
            }
        }
    }

        .image-grid__link {
            position: relative;
            display: block;
            height: 100%;
            text-decoration: none;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100px;
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 70%);
                z-index: 5;
                opacity: .7;

                .image-grid__container--0 & {
                    height: 200px
                }
            }
        }

        .image-grid__img {
            @include aspect( 1 );

            height: 100%;

            img {
                width: 100%;
                height: 100%;
                transition: transform 3s linear, opacity 500ms cubic-bezier(.4,0,.22,1);

                .image-grid__container:hover & {
                    transform: scale(1.1);
                }
            }
        }

        .image-grid__img--full {
            @include aspect(2);

            @include breakpoint(phablet) {
                display: none;
            }
        }

        .image-grid__img--full ~ .image-grid__img {
            display: none;

            @include breakpoint(phablet) {
                display: block;
            }
        }

        .image-grid__img--0 {
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include breakpoint(phablet) {
                @include aspect(math.div(5, 3));

                img {
                    position: relative;
                    top: unset;
                    left: unset;
                    width: 100%;
                }
            }
        }

        .image-grid__actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 20px;
            left: 30px;
            right: 30px;
            z-index: 10;

            @include breakpoint(phablet) {
                @include rem(16px);

                left: 25px;
                bottom: 15px;
            }
        }

            .image-grid__text {
                @include apply-map($f-primary);
                @include rem(18px);

                line-height: 26px;
                color: $c-white;
                letter-spacing: 3px;
                text-transform: uppercase;
            }

            .image-grid__icon {

                svg {
                    fill: $c-white;
                    transition: fill .3s ease;

                    .image-grid__container:hover & {
                        fill: $c-brand;
                    }
                }
            }

    .image-grid__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 80px;
        background: $c-background;
        height: 100%;

        @include breakpoint(nav) {
            padding: 30px;
        }

        @include breakpoint(tablet) {
            padding: 40px;
        }

        @include breakpoint(phablet) {
            padding: 0
        }
    }

            .image-grid__title {
                @include apply-map($f-title);
                @include rem(36px);

                line-height: 43px;
                margin-bottom: 20px;
                position: relative;

                @include breakpoint(tablet) {
                    @include rem(32px);

                    line-height: 38px;
                }

                @include breakpoint(phablet) {
                    margin-bottom: 25px;
                }
            }

            .image-grid__subtitle {
                @include apply-map($f-primary);
                @include rem(18px);

                line-height: 28px;
                margin-bottom: 20px;
                color: $c-block-copy;

                @include breakpoint(laptop) {
                    margin-bottom: 10px;
                }

                @include breakpoint(tablet) {
                    margin-bottom: 20px;
                }
            }

            .image-grid__copy {
                @include apply-map($f-primary);
                @include rem(14px);

                line-height: 22px;
                margin-bottom: 25px;
                color: $c-block-copy;

                @include breakpoint(laptop) {
                    margin-bottom: 0;
                }

                @include breakpoint(tablet) {
                    margin-bottom: 15px;
                }
            }
