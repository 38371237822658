.tooltip {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 10px;
    cursor: pointer;

    .batches__purchase-options & {
        align-items: start;
    }
}

.tooltip--fake {
    position: relative;
    cursor: pointer;
}

    .tooltip__icon {
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid $c-dark-grey;
        transition: border-color .3s ease;

        &::after {
            content: 'i';
            @include apply-map($f-title);
            @include rem(12px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $c-dark-grey;
            transition: color .3s ease;
        }

        .tooltip:hover & {
            border-color: $c-brand;

            &::after {
                color: $c-brand;
            }
        }
    }

    .tooltip__icon--alert {
        border-color: $c-brand;

        &::after {
            content: '!';
            @include rem(12px);
            position: absolute;
            font-style: normal;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $c-brand;
            transition: color .3s ease;
        }

        .tooltip:hover & {
            border-color: $c-dark-grey;

            &::after {
                color: $c-dark-grey;
            }
        }
    }

    .tooltip__icon--delivery {
        &::after {
            display: none;
        }

        border: 0;
        border-radius: 0;
        color: $c-brand;
        background-image: url('/images/am-delivery.svg');
        height: 14px;
        width: 24px;

        .tooltip:hover & {
            color: $c-dark-grey;
        }
    }

    .tooltip__text {
        @include apply-map($f-primary);
        @include rem(14px);

        position: absolute;
        bottom: calc(100% + 20px);
        margin-top: 4px;
        background-color: $c-background;
        box-shadow: 0 10px 10px 0 rgba(0,0,0,0.11);
        color: $c-dark-grey;
        padding: 20px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 3px;
        line-height: 20px;
        display: none;
        width: 300px;
        text-align: center;
        z-index: 100000;
        font-weight: normal;

        @keyframes fadeIn {
            from { opacity: 0; }
              to { opacity: 1; }
        }

        p {
            color: $c-dark-grey;
            font-weight: 400;
        }

        .tooltip:hover &,
        .tooltip:focus &,
        .tooltip--fake:hover &,
        .tooltip--fake:focus & {
            display: block;
            animation: fadeIn .3s;
        }

        &::after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-top-color: $c-background;
            border-width: 8px;
            margin-left: -8px;
        }

        @include breakpoint(tablet) {

            .product__subtitle & {
                left: 50px;

                &::after {
                    left: calc(50% - 50px);
                }
            }
        }
    }
