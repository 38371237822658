$span-widths: (
    l: 6,
    n: 6,
    xl: 6,
    xxl: 6,
    ml: 5,
    m: 4,
    ph: 3,
    s: 2
);

@each $name in map-keys($sizes) {
    $columns: map-get($span-widths, $name);

    @include breakpoint(map-get($sizes, $name)) {
        .#{$name}-span-1,
        .#{$name}-wide-span-2 {
            padding-left: 0;
        }

        .#{$name}-span-2,
        .#{$name}-wide-span-3 {
            padding-left: calc(100% / $columns);
        }

        .#{$name}-span-3,
        .#{$name}-wide-span-4 {
            padding-left: calc(100% / $columns * 2);
        }

        .#{$name}-span-4,
        .#{$name}-wide-span-5 {
            padding-left: calc(100% / $columns * 3);
        }

        .#{$name}-span-5 {
            padding-left: calc(100% / $columns * 4);
        }

        .#{$name}-break-true {
            clear: left;
        }

        .#{$name}-break-false {
            clear: none;
        }

        .#{$name}-hidden-true {
            display: none;
        }

        .#{$name}-hidden-false {
            display: inline-block;
        }


        .#{$name}-empty-row-true {
            padding-top: 50%;
        }

        .#{$name}-empty-row-false {
            padding-top: 0;
        }
    }
}
