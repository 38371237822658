.info-table {
    overflow: auto;

    @include breakpoint(phablet) {
        margin-right: -10px;
    }

    table {
        width: 780px;
        margin: 0 auto;

        @include breakpoint(phablet) {
            width: 850px;
        }
    }

        tr {}

            th,
            td {
                @include rem(16px);

                line-height: calc(22 / 16);
                padding: 20px;

                &:first-child {
                    padding-left: 30px;
                }

                &:last-child {
                    padding-right: 30px;
                }
            }

            th {
                font-weight: 700;
                text-transform: uppercase;
                border-top: 2px solid $c-brand;
                border-bottom: 1px solid $c-brand;
            }

            td {
                border-bottom: 1px solid $c-label;
            }
}
