button {
    width: auto;
    padding: 0;
    white-space: nowrap;
    vertical-align: top;
    background: transparent;
    border: none;
    outline: none;
}

/* start buttons */
.button {
    @include lightondark;

    width: fit-content;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 19px;
    padding: 13px 34px;
    border-radius: 5px;
    font-weight: 500;

    outline: none;
    cursor: pointer;
    text-decoration: none;

    margin: 0;
    background: $c-brand-black;
    border: none;
    -webkit-font-smoothing: antialiased; /*make it look nice in webkit*/
    text-align: center;
    white-space: nowrap;
    @include remove-white-space;

    &:after {
        content: "";
    }

    span {
        color: $c-white;
    }

    a:hover &,
    a:active &,
    &:hover,
    &--hover,
    &:active {
        background-color: $c-brand;

        span {
            color: $c-white;
        }
    }

    &[disabled],
    &--disabled {
        background-color: $c-border;
        border-color: $c-border;
        cursor: default;
        pointer-events: none;

        a:hover &,
        a:active &,
        &:hover,
        &:active {
            background-color: $c-border;
            border-color: $c-border;
        }
    }

    span {
        @include font(11px, 19px);
        letter-spacing: 1px;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: middle;
        white-space: normal;

        svg {
            vertical-align: middle;
            margin-right: 10px;
            margin-left: -20px;
            position: relative;
            top: -1px;
        }

        strong {
            vertical-align: middle;
        }
    }

    svg {
        transition: fill .3s ease;
    }
}

button {
    width: auto;
    white-space: nowrap;
    vertical-align: top;
}

.button--light {
    background-color: $c-white;

    span {
        color: $c-black;
    }
}

.button--light-alt {
    background-color: $c-white;

    span {
        color: $c-brand-black;
    }

    a:hover &,
    a:active &,
    &:hover,
    &--hover,
    &:active {
        background-color: $c-brand-black;

        span {
            color: $c-white;
        }
    }
}

.button--underline {
    @include font(12px, 20px);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
    border: none;
    border-bottom: 2px solid $c-black;
    background-color: transparent;
    font-weight: 500;
    padding: 0 0 6px 0;
    border-radius: 0;

    span {
        color: $c-black;
        @include font(12px, 12px);
        letter-spacing: 1.5px;
    }

    a:hover &,
    a:active &,
    &:hover,
    &--hover,
    &:active {
        background-color: transparent;
        color: $c-brand;
        border-color: $c-brand;

        span {
            color: $c-brand;
        }
    }
}

.button--underline-red {
    @include font(12px, 20px);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
    border: none;
    border-bottom: 2px solid $c-brand;
    background-color: transparent;
    font-weight: 500;
    padding: 0 0 6px 0;
    border-radius: 0;

    span {
        color: $c-brand;
        @include font(12px, 12px);
        letter-spacing: 1.5px;
    }

    a:hover &,
    a:active &,
    &:hover,
    &--hover,
    &:active {
        background-color: transparent;
        color: $c-brand-black;
        border-color: $c-brand-black;

        span {
            color: $c-brand-black;
        }
    }
}

.button--underline-dark {
    @include font(12px, 20px);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
    border: none;
    border-bottom: 2px solid $c-black;
    background-color: transparent;
    font-weight: 500;
    padding: 0 0 6px 0;
    border-radius: 0;

    span {
        color: $c-black;
        @include font(12px, 12px);
        letter-spacing: 1.5px;
    }

    a:hover &,
    a:active &,
    &:hover,
    &--hover,
    &:active {
        background-color: transparent;
        color: $c-white;
        border-color: $c-white;

        span {
            color: $c-white;
        }
    }
}

.button--underline-light {
    @include font(12px, 20px);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
    border: none;
    border-bottom: 2px solid $c-white;
    background-color: transparent;
    font-weight: 500;
    padding: 0 0 6px 0;
    border-radius: 0;

    span {
        color: $c-white;
        @include font(12px, 12px);
        letter-spacing: 1.5px;
    }

    a:hover &,
    a:active &,
    &:hover,
    &--hover,
    &:active {
        background-color: transparent;
        color: $c-black;
        border-color: $c-black;

        span {
            color: $c-black;
        }
    }
}

.button--no-underline {
    @include font(12px, 20px);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
    border: none;
    background-color: transparent;
    color: $c-black;
    font-weight: 500;
    padding: 0;
    border-radius: 0;

    a:hover &,
    a:active &,
    &:hover,
    &--hover,
    &:active {
        color: $c-brand;
        background-color: transparent;
    }
}

.button

.button--text {
    background: transparent;
    border: none;
    color: $c-black;
    line-height: 1.6;

    &:after {
        display: none;
    }

    a:hover &,
    a:active &,
    &:hover,
    &--hover,
    &:active {
        color: $c-brand;
        background-color: transparent;
    }

    span {
        padding: 0;
        line-height: inherit;
    }
}

.button--text-small {
    @extend .button--text;
    span {
        @include rem(13px);
    }
}

.button--small-text {

    span {
        @include rem(14px);

        line-height: calc(17 / 14);
    }
}

.button--download {
    @extend .button--text;
    @extend .button--has-icon;

    span {
        @include rem(14px);
        text-transform: capitalize;
        line-height: calc(17 / 14);
        letter-spacing: normal;

        svg {
            animation: test 1s infinite;
        }
    }
}


.button--noborder {
    @include remove-white-space;

    background: transparent;
    border: none;
    text-align: center;
    padding: 0;
    display: inline-block;
    padding: 0;
    height: auto;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    margin: 2px 40px 0;
    min-width: 150px;
    -webkit-font-smoothing: antialiased; /*make it look nice in webkit*/
    transition: fill .3s ease;

    &:hover {
        background: transparent;
        border: none;

        span {
            color: $c-white;

            .filterable__button & {
                color: $c-dark-grey;
            }
        }
    }

    &:after {
        height: auto;
    }

    span {
        @include rem(12px);

        line-height: 24px;
        padding: 0;
        color: $c-brand;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        font-weight: 400;
        white-space: nowrap;
        transition: color 0.3s ease;

        .filterable__button & {
            color: $c-dark-grey;
            font-weight: 500;
        }
    }

    &.button--has-icon {

        svg {
            fill: $c-brand;
            margin-left: 10px;
            margin-right: 0;
        }

        &:hover {

            svg {
                fill: $c-white;
            }
        }
    }

    .filterable__button & {
        width: 100%;
        margin: 0;
    }
}

.button--one-border {
    border: none;
    border-bottom: 1px solid $c-brand-black;
    padding: 10px 0;
    margin: 0;
    position: relative;
    background: none;

    .image-grid__content & {
        width: max-content;
    }

    span {
        @include font(12px, 20px);
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1.5px;
        padding: 0;
        color: $c-brand-black;
        white-space: nowrap;
        transition: color 0.3s ease;
        text-align: left;

        @include breakpoint(tiny) {
            white-space: normal;
        }

        .corner-cta__content & {
            white-space: normal;
        }
    }

    &:hover,
    &:active,
    .header-nav__categories:hover & {
        background: none;
        border: none;
        border-bottom: 1px solid $c-dark;
    }

    &:hover::after {
        width: 100%;
        background: $c-brand;
    }

    &.button--one-border-callout {
        border-bottom: 1px solid $c-border-footer;

        span {
            @include rem(18px);

            line-height:26px;
            letter-spacing: 3;
            color: $c-white;

            svg {
                fill: $c-white;
            }
        }

        &:hover {
            border-bottom: 1px solid $c-border-footer;
        }
    }

    &.button--has-icon {

        svg {
            margin: 0 0 0 15px;
            fill: $c-brand;
        }

    }

    &.button-one-border-white {
        span {
            color: $c-white;
        }
    }
}

.button--one-border-white {
    @extend .button--one-border;

    &,
    &:hover,
    &:active {
        border-bottom-color: $c-white;
    }

    span {
        color: $c-white;
    }
}

// Refresh dark change once styleguide implemented
.button--one-border-brand {
    @extend .button--one-border;

    padding: 4px 0;

    &,
    &:hover,
    &:active {
        border-bottom-color: $c-brand;
    }

    span {
        color: $c-brand;
    }
}

.button--one-border-all-white {
    @extend .button--one-border;

    border-bottom-color: $c-white;

    a:hover &,
    a:active & {
        background-color: transparent;
    }

    span {
        color: $c-white;
    }

    &.button--has-icon {
        svg {
            fill: $c-white;
        }
    }
}

.button--clear {
    color: $c-brand;
    background: transparent;
    border-color: $c-brand;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-color: transparent;
        color: $c-brand;
        border-color: $c-brand;
    }

    &.button--has-icon svg {
        fill: $c-brand;
    }
}

.button--white {
    color: $c-brand;
    background: $c-white;
    border-color: $c-brand;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-color: $c-white;
        color: $c-brand;
        border-color: $c-brand;
    }

    &.button--has-icon svg {
        fill: $c-brand;
    }
}

.button--brand-white {
    color: $c-white;
    background-color: $c-brand;
    border-color: $c-brand;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-color: $c-white;
        color: $c-brand;
        border-color: $c-brand;

        span {
            color: $c-brand;
        }
    }

    &.button--has-icon svg {
        fill: $c-brand;
    }

    span {
        color: $c-white;
    }
}

.button--white-new {
    border: none;
    background: linear-gradient(to right, $c-dark 50%, $c-white 50%);
    background-size: 210% 100%;
    background-position: right bottom;
    transition: all .2s cubic-bezier(0.61, 1, 0.88, 1);

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-position: left bottom;
        color: $c-white;
        border: none;
    }

    span {
        color: $c-dark-grey;
        transition: color .5s ease;

        &:hover {
            color: $c-white;
        }
    }

    &.button--has-icon {
        svg {
            fill: $c-brand;
            margin: 0 0 0 15px;
            transition: fill 0.5s ease;
        }

        &:hover {
            svg {
                fill: $c-brand;
            }
        }
    }
}

.button--black {
    @extend .button--white-new;
    background: $c-black;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background: $c-brand;
    }

    span {
        justify-content: center;
        position: relative;
        color: $c-white;

        &:hover {
            color: $c-white;
        }

        svg {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .filterable__button & {
        width: 100%
    }

    .filterable__button-count {
        width: auto;
        padding: 0;
        margin: 0 5px;
    }
}

.button--border {
    @extend .button--white-new;

    width: 100%;
    border: 1px solid $c-dark;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        border: 1px solid $c-dark;
    }

    &.button--has-icon {

        &:hover,
        &:active {

            span {
                color: $c-white !important;

                svg {
                    fill: $c-white !important;
                }
            }
        }
    }
}

.button--responsive {
    background-color: var(--block-text-colour, $c-white);
    border-color: var(--block-text-colour, $c-white);
    border-radius: 5px;
    color: var(--block-cta-text-colour, $c-dark);

    a:hover &,
    a:active &,
    &:hover,
    &--hover,
    &:active {
        background-color: var(--block-cta-text-colour, $c-dark);
        border-color: var(--block-cta-text-colour, $c-dark);
        color: var(--block-text-colour, $c-white);
    }

    span {
        @include apply-map($f-primary);
        @include font(11px, 19px);
        font-weight: 500;

        display: inline-block;
        letter-spacing: 1px;
        vertical-align: middle;
        white-space: normal;
    }
}

.button--mobile-responsive {
    background-color: $c-white;
    border-color: $c-white;
    border-radius: 5px;
    color: $c-dark;

    @include breakpoint(tablet) {
        background-color: $c-brand;
        border-color: $c-brand;
        color: $c-white;
    }

    a:hover &,
    a:active &,
    &:hover,
    &--hover,
    &:active {
        background-color: $c-dark;
        border-color: $c-dark;
        color: $c-white;
    }

    span {
        @include semiboldText;
        @include apply-map($f-primary);
        @include font(11px, 19px);

        display: inline-block;
        letter-spacing: 1px;
        padding: 8px 20px;
        vertical-align: middle;
        white-space: normal;
        color: $c-dark;

        @include breakpoint(tablet) {
            color: $c-white;
        }
    }
}

.button--has-icon {
    span {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        svg {
            flex: 0 0 auto;
            margin-left: 10px;
            fill: currentColor;
        }
    }

    &.button--large-grey {
        span {
            transition: color .3s ease;

            &:hover {
                color: $c-brand;
            }
        }

        svg {
            fill: $c-brand;

            .customise & {
                margin: 0
            }
        }
    }

    .button__text {
        padding: 0;
    }

    .button__text--centre {
        justify-content: center;
    }
}

.button--border-white {
    @extend .button--white-new;

    background: linear-gradient(to right, $c-white 50%, $c-dark 50%);
    background-size: 210% 100%;
    background-position: right bottom;
    transition: all .2s cubic-bezier(0.61, 1, 0.88, 1);
    border: 0.5px solid $c-border-footer;
    margin: 0;

    &:hover {
        background-position: left bottom;
        border: 0.5px solid $c-white;

        span {
            color: $c-dark;
        }
    }

    span {
        color: $c-white;
        transition: color 0.5s ease;
    }
}

.button--clear-white {
    color: $c-white;
    background: transparent;
    border-color: $c-border;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-color: transparent;
        color: $c-white;
        border-color: $c-border;
    }

    span {
        svg {
            fill: $c-white;
        }
    }

    &.signup-form__button {
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 26px;
        text-align: center;
        font-weight: 400;
    }
}

.button--clear {
    background: transparent;
    border-color: $c-border;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-color: transparent;
        border-color: $c-border;
    }

    span {
        @include rem(12px);

        line-height: 26px;
        padding-top: 9px;
        padding-bottom: 9px;
        color: $c-dark;
        letter-spacing: 2.5px;
        font-weight: 500;
    }
}

.button--footer {
    color: $c-white;
    background: transparent;
    border-color: $c-border-footer;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-color: transparent;
        color: $c-white;
        border-color: $c-border;
    }

    span {
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 26px;
        text-align: center;
        font-weight: 400;
        white-space: nowrap;
    }
}

.button--clear-black {
    color: $c-black;
    background: transparent;
    border-color: $c-border;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-color: $c-black;
        color: $c-white;
        border-color: $c-black;
    }

    &.button--has-icon svg {
        fill: $c-black;
    }
}

.button--clear-border {
    color: $c-black;
    border: 1px solid $c-border;
    background: transparent;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        color: $c-black;
        border-color: $c-black;

        span {
            color: $c-black;
        }
    }

    &.button--has-icon svg {
        fill: $c-black;
    }
}

.button--alt {
    background: $c-black;
    color: $c-white;
    border-color: $c-black;
    @include lightondark;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-color: $c-brand;
        color: $c-white;
        border-color: $c-brand;
    }
    &:after {
        height: 42px;
    }

    span {
        @include rem(12px);
        @include apply-map($f-primary);

        letter-spacing: 2.5px;
        font-weight: 500;
    }

    &.button--has-icon svg {
        fill: $c-white;
    }
}

.button--greyscale {
    color: $c-black;
    background: $c-white;
    border: 1px solid $c-border;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        color: $c-white;
        background-color: $c-black;
        border-color: $c-black;

        &.button--has-icon svg {
            fill: $c-white;
        }

        span {
            color: $c-white;
        }
    }

    span {
        @include rem(12px);
        @include apply-map($f-primary);
        color: $c-black;

        letter-spacing: 2.5px;
        font-weight: 500;
    }

    &.button--has-icon svg {
        fill: $c-black;
    }
}

.button--image {
    background: transparent;
    color: $c-white;
    border: 1px solid $c-white;
    @include lightondark;

    a:hover &,
    a:active &,
    &:hover,
    &.button--hover,
    &:active {
        background-color: $c-white;
        color: $c-black;

        &.button--has-icon svg {
            fill: $c-black;
        }
    }
    &:after {
        height: 38px;
    }

    &.button--has-icon svg {
        fill: $c-white;
    }

    span {
        @include rem(14px);
    }
}

#ie6 button,
#ie7 button {
    margin: -2px 0;
}

.button--large {
    span {
        line-height: calc(22 / 18);
        padding-left: 40px;
        padding-right: 40px;
    }
    &:after {
        height: 50px;
    }
}

.button--large-grey {
    background: $c-background;
    border: none;

    &::after {
        height: 56px
    }

    span {
        @include apply-map($f-primary);
        @include rem(12px);

        letter-spacing: 2.5px;
        font-weight: 500;
        color: $c-dark-grey;
        text-align: left;

        .customise__panel-meta & {
            padding: 0;
        }
    }

    &:hover {
        background: $c-background;
        border: none;
    }
}


button::-moz-focus-inner{padding:0;border:none;} /* FF Fix */

.button--full {
    width: 100%;

    span {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.button--full-mobile {
    @include breakpoint(mobile) {
        width: 100%;
    }
}

.button--ph-full {
    @include breakpoint(phablet) {
        width: 100%;
    }
}

.buttons--tall {
    span {
        padding: 18px;
    }
}

.button--on-black {
    &:hover {
        background-color: $c-white;
        span {
            color: $c-black;
        }
    }
}

.button--back {
    margin: 0;
    min-width: 0;

    span {
        font-weight: 500;
        color: $c-dark;

        svg {
            margin-left: 0;
            margin-right: 15px;
            fill: $c-brand;
        }
    }

    &:hover {

        span {
            color: $c-brand;
        }
    }
}

.button--hide {
    display: none;
}
