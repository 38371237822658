.block--wide-text-image {
    margin: 80px 0;
    padding: 0;
    display: grid;
    grid-template-columns: minmax(0, 60%) minmax(0, 40%);
    align-items: center;
    column-gap: 70px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        gap: 20px;
        margin: 50px 0;
    }

    &.block--reverse {
        grid-template-columns: minmax(0, 40%) minmax(0, 60%);

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
        }

        .block__image {
            order: -1;
        }
    }

    .block__container {}

        .block__content {
            max-width: 700px;
        }

            .block__title {
                @include font(40px, 40px);

                margin: 0;
                margin-bottom: 25px;
                text-align: left;

                @include breakpoint(tablet) {
                    @include font(25px, 25px);

                    margin-bottom: 15px;
                }
            }

            .block__intro {
                @include font(24px, 32px);

                color: $c-dark;
                margin: 0;

                @include breakpoint(tablet) {
                    @include font(18px, 28px);
                }
            }

            .block__intro--desktop-only {
                margin-top: 30px;

                @include breakpoint(tablet) {
                    display: none;
                }
            }

            .block__intro--mobile-only {
                display: none;

                @include breakpoint(tablet) {
                    display: block;
                }
            }
    
        .block__image {
            aspect-ratio: 7 / 4;
            align-self: stretch;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
}
