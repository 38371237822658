// Grouped content transitions

[data-aos="content__image"],
[data-aos="content__subtitle"],
[data-aos="content__title"],
[data-aos="content__copy"],
[data-aos="content__cta"] {
    opacity: 0;
    transition-property: opacity;

    &,
    body[data-aos-easing] & {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }

    &,
    body[data-aos-duration] & {
        transition-duration: 1.125s;
    }

    &.aos-animate {
        opacity: 1;
    }
}

[data-aos="content__title"] {
    transition-delay: .3s;
}

[data-aos="content__subtitle"] {
    transition-delay: .4s;
}

[data-aos="content__copy"] {
    transition-delay: .5s;
}


[data-aos="content__cta"] {
    transition-delay: .7s;
}

[data-aos="content__image"] {
    transition-delay: .9s;
}

// Basic stack transitions

[data-aos^="stack"] {
    opacity: 0;
    transition-property: opacity;
    transition-delay: 150ms;

    &,
    body[data-aos-easing] & {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }

    &,
    body[data-aos-duration] & {
        transition-duration: 1s;
    }

    &.aos-animate {
        opacity: 1;
        transform: none;
    }
}

@for $i from 1 through 15 {

    [data-aos*="stack--xxl-#{$i}"] {

        @include breakpoint(desktop-extra-wide) {
            transition-delay: $i * 150ms;
        }
    }
}

@for $i from 1 through 15 {

    [data-aos*="stack--xl-#{$i}"] {

        @include breakpoint(desktop-wide) {
            transition-delay: $i * 150ms;
        }
    }
}

@for $i from 1 through 15 {

        [data-aos*="stack--#{$i}"] {
        
        @include breakpoint(wrap) {
            transition-delay: $i * 150ms;
        }
    }
}

@for $i from 1 through 15 {

    [data-aos*="stack--sml-#{$i}"] {

        @include breakpoint(small-laptop) {
            transition-delay: $i * 150ms;
        }
    }
}

@for $i from 1 through 15 {

    [data-aos*="stack--m-#{$i}"] {

        @include breakpoint(tablet) {
            transition-delay: $i * 150ms;
        }
    }
}

@for $i from 1 through 15 {

    [data-aos*="stack--ph-#{$i}"] {

        @include breakpoint(phablet) {
            transition-delay: $i * 150ms;
        }
    }
}

@for $i from 1 through 15 {

    [data-aos*="stack--s-#{$i}"] {

        @include breakpoint(mobile) {
            transition-delay: $i * 150ms;
        }
    }
}

@for $i from 1 through 50 {

    [data-aos*="stack-fast--#{$i}"] {
    
    @include breakpoint(wrap) {
        transition-delay: $i * 50ms;
    }
}
}

// Fade transitions

[data-aos^="fade"] {
    opacity: 0;

    &,
    body[data-aos-easing] & {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }

    &,
    body[data-aos-duration] & {
        transition-duration: 400ms;
    }

    &.aos-animate {
        opacity: 1;
    }
}

@for $i from 1 through 15 {

    [data-aos*="fade--#{$i}"] {
        transition-delay: $i * 80ms;
    }
}

@for $i from 1 through 15 {

    [data-aos*="fade--m-#{$i}"] {

        @include breakpoint(tablet) {
            transition-delay: $i * 0.1s;
        }
    }
}

@for $i from 0 through 15 {

    [data-aos*="fade--s-#{$i}"] {

        @include breakpoint(mobile) {
            transition-delay: $i * 0.1s;
        }
    }
}

// Module transitions

[data-aos="module"],
[data-aos="module-reverse"] {
    opacity: 0;
    transition-property: transform, opacity;

    &,
    body[data-aos-easing] & {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }

    &,
    body[data-aos-duration] & {
        transition-duration: 0.5s;
    }

    &.aos-animate {
        opacity: 1;
        transform: none;
    }
}

[data-aos="module"] {
    transform: translateX(10%);
}

[data-aos="module-reverse"] {
    transform: translateX(-10%);
}

[data-aos="module-highlight"] {
    opacity: 0;

    &,
    body[data-aos-easing] & {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }

    &,
    body[data-aos-duration] & {
        transition-duration: 625ms;
    }

    &.aos-animate {
        opacity: 1;
    }
}

[data-aos="module__image"],
[data-aos="module__content"] {
    opacity: 0;
    transition-property: opacity;

    &,
    body[data-aos-easing] & {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }

    &,
    body[data-aos-duration] & {
        transition-duration: 1.125s;
    }

    &.aos-animate {
        opacity: 1;
    }
}

[data-aos="module__image"] {
    transition-delay: .125s;
}

[data-aos="module__content"] {
    transition-delay: .25s;
}
