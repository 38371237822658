@use "sass:math";

.home-callout {
    margin: 40px 10px 40px 0;

    @include breakpoint(tablet) {
        margin: 0 0 35px 0;
    }
}

.home-callout--right {
    margin: 40px 0 40px 10px;

    @include breakpoint(tablet) {
        margin: 0;
    }
}

    .home-callout__image-link {}

        .home-callout__image {
            @include aspect(math.div(5, 3));

            margin-bottom: 15px;

            @include breakpoint(phablet) {
                margin-bottom: 10px
            }
        }

    .home-callout__title {
        @include apply-map($f-title);
        @include rem(40px);

        line-height: 36px;
        margin-bottom: 10px;
        color: $c-brand-black;

        @include breakpoint(tablet) {
            @include rem(30px);

            line-height: 36px;
        }
    }

    .home-callout__copy {
        @include apply-map($f-primary);
        @include rem(16px);

        line-height: 24px;
        color: var(--c-subtle);
        width: 85%;
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            @include rem(14px);

            line-height: 24px;
            width: 100%;
            margin-bottom: 10px;
        }
    }
