.overlay-two {
    position: relative;
    
    &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
}
    .overlay-two__img {
        position: absolute
    }

    .overlay-two__img--small {
        right: 0;
        bottom: 0;
        width: 47%;
        z-index: 5;
    }

    .overlay-two__img--large {
        left: 0;
        top: 0;
        width: 75%
    }