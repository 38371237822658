.blog-feed {
    max-width: 1920px;
    margin: 0 auto;
}

    .blog-feed__title {
        @include large-title;
        color: $c-white;
        text-align: center;
        padding: 60px 0;

        @include breakpoint (tablet) {
            padding: 50px 0;
        }

        @include breakpoint (mobile) {
            padding: 40px 0;
        }
    }

    .blog-feed__posts {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba($c-black, 0) 0%, $c-black 100%);
        }
    }

        .blog-feed__posts__badge {
            position: absolute;
            bottom: 0;
            background: $c-black;
            left: 50%;
            margin-left: -95px;
            z-index: 99;
            padding: 15px 20px 30px;
            width: 190px;
            height: auto;
            display: block;

            svg {
                fill: $c-white;
                vertical-align: middle;
            }

            span {
                color: $c-white;
                vertical-align: middle;
                margin-left: 8px;
            }
        }
