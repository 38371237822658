@use 'sass:math';

.listing-callout {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 75%;
        opacity: 0.85;
        z-index: 1;
        background: linear-gradient(0deg, rgba($c-black, 1) 0%, rgba($c-black, 0) 100%);

        @include breakpoint(phablet) {
            display: none;
        }
    }
}

    .listing-callout__image {
        @include aspect(math.div(3, 2));

        .product-listing--1x1 & {
            @include aspect(math.div(3, 4));

            @include breakpoint(phablet) {
                @include aspect(math.div(7, 6));
            }
        }
    }

    .listing-callout__actions {
        position: absolute;
        left: 30px;
        bottom: 30px;
        right: 30px;
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr auto;
        align-items: flex-end;
        z-index: 2;

        .product-listing--1x1 & {
            display: block;
        }

        @include breakpoint(phablet) {
            grid-template-columns: 1fr;
            background-color: $c-dark;
            gap: 0;
            position: relative;
            left: unset;
            bottom: unset;
            right: unset;
            padding: 20px;
        }
    }

        .listing-callout__title {
            @include apply-map($f-title);
            @include font(28px, 34px);

            color: $c-white;
            grid-column: 1 / 3;

            .product-listing--1x1 & {
                margin-bottom: 12px;
            }

            @include breakpoint(tablet) {
                @include font(24px, 29px);
            }

            @include breakpoint(phablet) {
                grid-column: 1 / 2;
                margin-bottom: 15px;
            }
        }

        .listing-callout__text {
            @include font(16px, 24px);

            color: $c-white;
        }
