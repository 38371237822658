.filter-ranges {
    position: relative;
    z-index: 3;
    display: flex;
    flex-wrap: nowrap;

    @include breakpoint(phablet) {
        width: 100%;

        svg {
            fill: $c-dark-grey;
        }
    }

    &.filter-ranges--active {
        @include breakpoint(phablet) {
            flex-direction: column;
        }
    }
}

    .filter-ranges__top {
        @include rem(14px);

        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 1px;

        @include breakpoint(phablet) {
            display: none;
        }

        .filter-ranges--selected & {
            background: $c-white;
        }

        svg {
            fill: $c-dark;
            transform: rotate(90deg);
            transition: transform .1s ease;
            margin: 3px 0 0 10px;

            .filter-ranges--active & {
                transform: rotate(270deg);
                fill: $c-brand;
            }
        }
    }

    .filter-ranges__bottom {
        display: none;

        .filter-ranges--active & {
            margin-top: 1px;
            display: flex;
            position: absolute;
            top: calc(100% + 15px);
            left: 0;
            padding: 25px;
            background: $c-white;
            box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.15);

            @include breakpoint(laptop) {
                min-width: 100%;
            }

            @include breakpoint(tablet) {
                flex-direction: column;
            }

            @include breakpoint(phablet) {
                box-shadow: none;
                position: relative;
                top: auto;
                max-width: 100%;
                border: none;
                padding: 20px 0px;
            }
        }
    }

        .filter-ranges__status {
            display: none;

            @include breakpoint(phablet) {
                display: flex;
                margin-top: -15px;
                margin-right: -15px;
                margin-bottom: 15px;
                margin-left: -15px;
                // padding: 15px;
                border-bottom: 1px solid $c-border;
            }

            .filter-ranges--selected & {
                display: flex;
                margin-top: -15px;
                margin-right: -15px;
                margin-bottom: 15px;
                margin-left: -15px;
                // padding: 15px;
                border-bottom: 1px solid $c-border;
            }
        }

            .filter-ranges__back {
                display: none;

                @include breakpoint(phablet) {
                    position: relative;
                    display: block;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                }

                svg {
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    width: 12px;
                    height: 10px;
                    stroke: $c-black;
                    transform: translateY(-50%) rotate(90deg);
                    pointer-events: none;
                }
            }

            .filter-ranges__selection {
                @include rem(12px);

                line-height: calc(40 / 12);
                flex: 1 1 auto;
                width: 100px;
                padding: 0 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-style: italic;

                @include breakpoint(phablet) {
                    display: none;
                }
            }

            .filter-ranges__selection-mobile {
                display: none;

                @include breakpoint(phablet) {
                    @include rem(14px);

                    line-height: calc(40 / 14);
                    display: block;
                    flex: 1 1 auto;
                    padding: 0 15px;
                    text-align: center;
                    font-weight: 700;
                }
            }

            .filter-ranges__clear {
                @include rem(12px);

                position: relative;
                display: block;
                line-height: calc(40 / 12);
                padding: 0 35px 0 10px;
                text-transform: uppercase;
                font-weight: 700;
                color: rgba($c-black, 0.3);
                cursor: pointer;

                svg {
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    width: 12px;
                    height: 10px;
                    fill: rgba($c-black, 0.3);
                    transform: translateY(-50%);
                    pointer-events: none;
                }
            }
