.pswp__bg {
    opacity: 1 !important;
}

.pswp__button--arrow--prev,
.pswp__button--arrow--next {
    width: 45px !important;
    height: 45px !important;
    background-color: $c-white !important;
    opacity: 1 !important;
    margin-top: -25px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    visibility: visible !important;

    svg {
        height: 20px;
        width: 10px;
        fill: $c-black;
    }
}

.pswp__button--arrow--prev svg {
    margin-right: 5px;
}

.pswp__button--arrow--next svg {
    margin-left: 5px;
}
