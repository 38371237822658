.load-more {
    @include apply-map($f-smallcaps);
    @include rem(14px);

    display: table;
    margin: 28px auto 0;
    padding: 15px;
    line-height: calc(17 / 14);
    font-weight: 700;
    text-decoration: none;
    border: 1px solid $c-load-more-border;
    position: relative;
    width: 115px;
    text-align: center;
}

.load-more--design-directory {
    margin: 50px auto 0;
}

.load-more--loading {
    font-size: 0;
    max-width: 100%;
    height: 49px;
    padding: 0;
    border: none;
}

.load-more--message {
    margin-top: 50px;
    border: 0;
}

.load-more__indicator {
    opacity: 0;
    position: absolute;
    transition: opacity .5s ease;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;

    svg {
        width: 50px;
        height: 50px;
    }

    .load-more--loading & {
        opacity: 1;
        animation: spin 2s linear infinite;
    }
}
