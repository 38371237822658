@use "sass:math";

.page-callouts {
    padding: 80px 0;
}

.page-callouts--has-border {
    border-top: 1px solid $c-border;
}

.page-callouts--compact {
    padding: 50px 0;
}

.page-callouts--large {
    padding: 50px 0;
}

    .page-callout {

        a {
            display: block;
            text-decoration: none;
        }
    }

    .page-callout--large {

        a {
            display: inline-block;
        }
    }

        .page-callout__image {
            @include aspect(math.div(580, 386));
        }

        .page-callout--compact .page-callout__image {
            @include aspect(math.div(180, 120));
        }

        .page-callout__title {
            @include rem(22px);

            line-height: calc(22 / 22);

            * + & {
                margin: 30px 0 0;

                @include breakpoint(phablet) {
                    margin: 20px 0 0;
                }
            }
        }

        .page-callout__description {
            @include rem(16px);

            line-height: calc(22 / 16);
            color: $c-callout-description;

            * + & {
                margin: 10px 0 0;
            }
        }

        a.page-callout__link {
            @include apply-map($f-smallprint);
            @include rem(14px);

            line-height: calc(17 / 14);
            text-decoration: underline;

            * + & {
                margin: 10px 0 0;
            }
        }
