.block--quote {
    .block__content {
        position: relative;
        max-width: 780px;
        margin: 0 auto;
        text-align: center;

        @include breakpoint(tablet) {
            padding: 0 $site-gutter-mobile;
        }
    }

        .block__quote {
            @include font(22px, 36px);

            font-style: italic;

            @include breakpoint(tablet) {
                @include font(18px, 32px);
            }
        }

        .block__author {
            @include font(14px, 22px);

            margin-top: 10px;
            color: $c-richcopy;
            display: block;

            @include breakpoint(tablet) {
                margin-top: 5px;
            }
        }

        .block__icon {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -50px;
            opacity: 0.05;

            @include breakpoint(tablet) {
                top: -40px;
            }

            svg {
                width: 116px;
                height: 98px;

                @include breakpoint(tablet) {
                    width: 94px;
                    height: 80px;
                }
            }
        }
}
