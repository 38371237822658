.post__title {
    @include large-title;
    @include breakpoint(phablet) {
        @include rem(40px);
        line-height: calc(33 / 40);
    }
}
.post__subtitle {
    @include apply-map($f-smallprint);
    @include rem(12px);

    line-height: calc(15 / 12);
    text-transform: uppercase;
    color: $c-label;
    font-weight: 400;
    letter-spacing: 1px;

    * + & {
        margin: 10px 0 0;
    }
}
.post__intro {
    @include introcopy;
}
.post-social {
    text-align: center;
    .post-social__title {
        @include smallcaps;
        color: $c-label;
        letter-spacing: 1px;
    }
    .post-social__link {
        display: inline-block;
        vertical-align: middle;
        font-size: 0;
        padding: 10px;
        svg {
            display: block;
        }
    }
}

.blog__filters {
    .filterable__dimension {
        width: 250px;
    }

    .custom-select-replace {
        .select-replace {
            max-width: 100%;
        }
    }
}
