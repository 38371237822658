@mixin gallery-fade($width: 80px) {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: $width;
        background: linear-gradient(270deg, rgba($c-white, 1) 0%, rgba($c-white, 0) 100%);
        z-index: 1;
    }
}

