////
/// Default mixins for hospitality system
///
/// @author Dom Morgan <dom@d3r.com>
////

@import "core/resources/sass/mixins";
@import "mixins/allow-for-bars";
@import "mixins/margin";
@import "mixins/semi-bold-text";
@import "mixins/swatch-active";
@import "mixins/swatch-hover";
@import "mixins/gallery-fade";

/// Backwards compatible opacity
/// @param {Float} $opacity
/// @author Dom Morgan <dom@d3r.com>
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}

/// Better antialiasing for light text on a dark background
/// @author Dom Morgan <dom@d3r.com>
@mixin lightondark {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/// Dashed or dotted borders with bigger gaps/dots
/// Note that this uses background so borders will be int eh padding box,
/// not the border box.
/// @param {Color} $colour
/// @param {Number | List} $spacing between dots
/// @author Dom Morgan <dom@d3r.com>
@mixin dashed-border($colour, $spacing: 2px, $width: 1px, $padding: 0) {
    @if $padding != 0 {
        $paddingMap: explode-shorthand($padding);
        @each $side, $value in $paddingMap {
            padding-#{$side}: $value + 1px;
        }
    }
    border: none;
    $repeat: $spacing + $width;
    background-image: linear-gradient(
        to right,
        $colour 0px,
        $colour $width,
        transparent $width
    ),
    linear-gradient(
        to bottom,
        $colour 0px,
        $colour $width,
        transparent $width
    ),
    linear-gradient(
        to left,
        $colour 0px,
        $colour $width,
        transparent $width
    ),
    linear-gradient(
        to top,
        $colour 0px,
        $colour $width,
        transparent $width
    );
    background-size: $repeat 1px, 1px $repeat, $repeat 1px, 1px $repeat;
    background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
}

/// Grid gutters
///
/// @param {Number} $gutter-x horizontal spacing
/// @param {Number} $gutter-y vertical spacing
/// @author Brendan Patterson <brendan@d3r.com>
@mixin grid-gutter($gutter-x:$grid-gutter-h, $gutter-y:$grid-gutter-v) {
    margin-right: -($gutter-x);
    > * {
        padding-right: $gutter-x;
        margin-bottom: $gutter-y;
    }
    // Use the correct negative margin for flush bottom
    &.grid--flush-bottom {
        margin-bottom: -($gutter-y);
    }
}

/// Remove white space from space charecters in html etc
/// @author Brendan Patterson <brendan@d3r.com>
@mixin remove-white-space {
    font-size: 0 !important;
    letter-spacing: normal;
    word-spacing: normal;
    & > * {
        display: inline-block;
        @include rem(15px);
    }
}

/// Remove margin-bottom from last child
/// @author Brendan Patterson <brendan@d3r.com>
@mixin flush-last-child {
    & > :last-child {
        margin-bottom: 0;
    }
}

/// Hide only visually, but have it available for screenreaders: h5bp.com/v
/// @author Brendan Patterson <brendan@d3r.com>
@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.visually-hidden {
    @include visually-hidden;
}

/// Restore visually hidden to UA defaults
/// @author Brendan Patterson <brendan@d3r.com>
@mixin remove-visually-hidden {
    clip: auto;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    padding: 0;
    position: static;
}



$default-content-type: general !default;
/// A max width wrapper
///
/// @param {String} $content-type
/// @author Brendan Patterson <brendan@d3r.com>
@mixin max-width-content($content-type: $default-content-type) {
    margin-left: auto;
    margin-right: auto;
    // By default, for intro's etc
    @if $content-type == general {
        max-width: $max-content-width;
    }
    // Large areas of bodycopy, eg richtext
    @else if $content-type == bodycopy {
        max-width: $max-content-width;
    }
    // Or a number
    @else if type-of($content-type) == number {
        max-width: $content-type;
    }
    @else {
        @warn "Unexpected value in max-width-content mixin";
    }
}


$default-bullet-behaviour: nowrap !default;

/// Faux list item
/// @param {String} $bullet-behaviour nowrap or wrap
/// @author Brendan Patterson <brendan@d3r.com>
@mixin list-item($bullet-behaviour: $default-bullet-behaviour) {

    $bullet-size: 0.333em;
    $bullet-margin: 0.8em;

    // Parent 'list' item
    position: relative;
    display: block;

    // Disc styling
    &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: $bullet-size;
        height: $bullet-size;
        background: $c-bullet;
        border-radius: 999px;
    }

    // Text cannot wrap under the disc
    @if $bullet-behaviour == nowrap {
        // Make space for the disc
        padding-left: $bullet-margin + $bullet-size;
        // Position the disc
        &::before {
            position: absolute;
            top: $f-lineheight * 0.5em;
            margin-top: -$bullet-size/2;
            left: 0;
        }
    }

    // Text can wrap under the disc
    @if $bullet-behaviour == wrap {
        &::before {
            margin-right: $bullet-margin;
        }
    }
}

/// Absolute cover
/// @author Brendan Patterson <brendan@d3r.com>
@mixin absolute-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


$default-fallback-value: 0.5em !default;
/// Absolute cover
/// A bit magic numbery but kinda maybe should work, failing that pass in a var
/// @param {Number} $fallback-value
/// @author Brendan Patterson <brendan@d3r.com>
@mixin absolute-center($fallback-value: $default-fallback-value) {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: auto;
    transform: translateY(-50%);
}

/// Fill image
/// @author Brendan Patterson <brendan@d3r.com>
@mixin fill-image {
    width: 100%;
}

/// Photoshop tracking to letter spacing
/// @param {String | Number} $photoshop-value
/// @author Brendan Patterson <brendan@d3r.com>
@mixin tracking($photoshop-value) {

    // Check if value is a number
    @if type-of($photoshop-value) == "number" {
        // If its 0 change to 'normal' which is UA default
        @if $photoshop-value == "0" {
            letter-spacing: normal;
        }
        // Otherwise divide by 1000 which is approx calculation
        @else {
            letter-spacing: #{calc($photoshop-value / 1000)}em;
        }
    }

    // Allow for keywords
    @else if $photoshop-value == ("normal", "default", "none") {
        letter-spacing: normal;
    }

    // Warn if anything else and apply UA default
    @else {
        @warn "expected numerical value in tracking mixin";
        letter-spacing: normal;
    }
}

/// Apply a map of keys to an element
///
/// @param {map} $props
/// @author Neil Brayfield <neil@d3r.com>
@mixin apply-map($props) {
    @each $name in map-keys($props) {
        @if $name == "tracking" {
            @include tracking(map-get($props, $name));
        }
        @else {
            #{$name}: map-get($props, $name)
        }
    }
}

/// Apply a map of keys to an element
///
/// @param {map} $props
/// @author Neil Brayfield <neil@d3r.com>
@mixin vertical-align {
    vertical-align: middle;
    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0px;
        height: 100%;
    }
}

/// Set the value of a numeric property at each breakpoint, and consider the prescence of admin/debug bars
///
/// @param {Array} $value Array of numeric values, one per breakpoint
/// @param {String} $property CSS property
/// @author Joe Adcock <joe@d3r.com>

@mixin allowForBars($value: 0, $property: top) {
    $value--default: 0;
    $value--tablet: 0;
    $value--phablet: 0;

    @if type-of($value) == map {
        @if type-of(map-get($value, default)) == number {
            $value--default: map-get($value, default);
        } @else if map-get($value, default) == false {
            $value--default: false;
        }
        @if type-of(map-get($value, tablet)) == number {
            $value--tablet: map-get($value, tablet);
        } @else if map-get($value, tablet) == false {
            $value--tablet: false;
        } @else {
            $value--tablet: $value--default;
        }
        @if type-of(map-get($value, phablet)) == number {
            $value--phablet: map-get($value, phablet);
        } @else if map-get($value, phablet) == false {
            $value--phablet: false;
        } @else {
            $value--phablet: $value--tablet;
        }
    } @else if type-of($value) == number {
        $value--default: $value;
        $value--tablet: $value;
        $value--phablet: $value;
    }

    @if $value--default {
        #{$property}: $value--default;
    }

    @if $value--tablet and $value--tablet != $value--default {
        @include breakpoint(nav) {
            #{$property}: $value--tablet;
        }
    }

    @if $value--phablet and $value--phablet != $value--tablet {
        @include breakpoint(phablet) {
            #{$property}: $value--phablet;
        }
    }

    .body--debug & {
        @if $value--default {
            #{$property}: $value--default + $debug-height;
        }

        @if $value--tablet {
            @include breakpoint(nav) {
                #{$property}: $value--tablet;
            }
        }
    }

    .body--admin & {
        @if $value--default {
            #{$property}: $value--default + $admin-height;
        }

        @if $value--tablet {
            @include breakpoint(nav) {
                #{$property}: $value--tablet + $admin-height;
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;
            }
        }
    }

    .has-notice-bar & {
        @if $value--default {
            #{$property}: $value--default + $notice-height;
        }

        @if $value--tablet {
            @include breakpoint(nav) {
                #{$property}: $value--tablet + $notice-height;
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet + $notice-height-mobile;
            }
        }
    }

    .has-notice-bar.body--admin & {
        @if $value--default {
            #{$property}: $value--default + $notice-height + $admin-height;
        }

        @if $value--tablet {
            @include breakpoint(nav) {
                #{$property}: $value--tablet + $admin-height + $notice-height;
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;
            }
        }
    }

    .body--debug.body--admin & {
        @if $value--default {
            #{$property}: $value--default + $debug-height + $admin-height;
        }

        @if $value--tablet {
            @include breakpoint(nav) {
                #{$property}: $value--tablet + $admin-height;
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;
            }
        }
    }

    .has-notice-bar.body--debug.body--admin & {
        @if $value--default {
            #{$property}: $value--default + $notice-height + $debug-height + $admin-height;
        }

        @if $value--tablet {
            @include breakpoint(nav) {
                #{$property}: $value--tablet + $notice-height + $admin-height;
            }
        }

        @if $value--phablet {
            @include breakpoint(phablet) {
                #{$property}: $value--phablet;
            }
        }
    }
}

/// Hide an element safely, i.e. the element is not visible to the user, but is visible to a screen reader
///
/// @author Joe Adcock <joe@d3r.com>

@mixin hideSafely {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

@mixin placeholder($color) {
    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: $color;
        @include lightondark;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $color;
        opacity: 1;
        @include lightondark;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $color;
        opacity: 1;
        @include lightondark;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $color;
        @include lightondark;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: $color;
        @include lightondark;
    }
}

// Stretch an image to cover its container
///
/// @author Joe Adcock <joe@d3r.com>

@mixin coverImage {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

/// Allow an image to be displayed to fit its container
///
/// @author Joe Adcock <joe@d3r.com>

@mixin objectFitImage {
    @include coverImage;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
}

@mixin unsetAbsolute {
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    transform: translateY(0%);
    transform: translateX(0%);
}


/// Set a relative font sizse and a unitless line height, based on the required line height and a font size
///
/// @param {Number} $fontSize Font size
/// @param {Number} $lineHeight Line height
/// @param {Bool} $em Use rem
/// @author Joe Adcock <joe@d3r.com>

@mixin font($fontSize, $lineHeight, $em: false) {
    @if $em {
        font-size: ($fontSize / $base-px) * 1em;
    } @else {
        @include rem($fontSize);
    }

    line-height: calc($lineHeight / $fontSize);
}
