@use "sass:math";

.overlay-three {
    position: relative;
    max-width: 100%;
    margin-left: 60px;

    &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    @include breakpoint(tablet) {
        margin-left: 0;
    }

    &.overlay-three--cascade {
        margin-left: 0;
    }
}

.overlay-three--cascade {
    height: 412px;
    width: calc((100% * (7/12)) - 80px);

    @include breakpoint(tablet) {
        width: 60%;
    }

    @include breakpoint(phablet) {
        width: 100%;
        height: auto;
    }
}

    .overlay-three__img {
        position: absolute;

        .overlay-three--cascade & {
            width: 37.11%;

            @include breakpoint(laptop) {
                width: 43%;
            }

            @include breakpoint(phablet) {
                width: calc(50% - 14px);
            }
        }
    }

    .overlay-three__img--small {
        @include aspect(1);

        left: 8.33%;
        top: 0;
        padding-top: 8.33%;
        width: 25.9%;

        .overlay-three--cascade & {
            @include unsetAbsolute;

            left: 0;
            top: 0;
            padding-top: 0;

            @include breakpoint(phablet) {
                margin-left: 15px;
            }
        }
    }

    .overlay-three__img--medium {
        @include aspect(1);

        left: 0;
        bottom: 0;
        width: 57.4%;
        z-index: 5;

        .overlay-three--cascade & {
            @include unsetAbsolute;

            top:50%;
            left:50%;
            transform:translate(-50%, -50%);

            @include breakpoint(phablet) {
                @include unsetAbsolute;

                right: 0;
                top: 40px;
                margin-right: 15px;
            }
        }
    }

    .overlay-three__img--large {
        @include aspect(math.div(3, 4));

        right: 0;
        top: 0;
        width: 57.4%;

        .overlay-three--cascade & {
            @include unsetAbsolute;
            @include aspect(1);

            right: 0;
            bottom: 0;
            z-index: 10;

            @include breakpoint(phablet) {
                display: none;
            }
        }
    }
