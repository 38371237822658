.discover {

    @include breakpoint(nav) {
        display: none;
    }
}

    .discover__link {
        display: flex;
        text-decoration: none;

        svg {
            display: inline-block;
            fill: $c-dark-grey;
            transition: fill .3s ease;
            margin-top: 3px;

            .header--clear:not(:hover):not(.header__nav-item--active) & {
                fill: $c-white;
            }
        }

        &:hover {

            svg {
                fill: $c-brand;
            }
        }
    }

        .discover__text {
            @include lightondark;
            @include apply-map($f-primary);
            @include rem(13px);
            font-weight: 500;

            margin-left: 5px;
            color: $c-dark-grey;
            padding: 5px 0;
            text-decoration: none;
            line-height: 19px;
            border-bottom: 1px solid transparent;
            transition: color .3s ease, border-bottom .3s ease;

            .discover__link:hover & {
                border-bottom: 1px solid $c-dark-grey;
            }

            .header--clear:not(:hover):not(.header__nav-item--active) & {
                color: $c-white;
            }

            .header__nav-item--active {
                background: $c-dark-grey;
            }
        }

        .discover__text--active {
            border-bottom: 1px solid $c-dark-grey;
        }
