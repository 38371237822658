.search-form {
    display: none;
    position: absolute;
    top: 139px;
    left: 0;
    width: 100%;
    height: calc(100vh - 50px);
    background: rgba($c-search-overlay, 0.75);
    z-index: 1;

    @include breakpoint(tablet) {
        top: 50px;
    }
}

.search-form--active {
    display: block;
}

    .input.search-form__field {
        background: $c-background;
        border: none;
        @include apply-map($f-primary);
        @include rem(36px);
        padding-left: 65px;
        padding-right: 50px;
        height: 90px;

        &::placeholder {
            opacity: .5;
        }

        &:focus {
            background-color: transparent;
            outline: none;
            box-shadow: none;
        }

        @include breakpoint(phablet) {
            height: 70px;
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .search-form__field-wrapper {
        position: relative;
        svg {
            position: absolute;
            top: 24px;
            left: 0;
            fill: $c-border;
            height: 40px;
            width: 40px;

            @include breakpoint(phablet) {
                width: 25px;
                height: 25px;
            }
        }


        .search-form__close {
            svg {
                right: 0;
                left: auto;
                stroke: $c-label;
            }
        }
    }
