
    .sizing-calculator {
        position: relative;
        max-width: 480px;
        margin: 20px auto;
        padding: 20px 80px;
        color: $c-white;
        background: $c-black;
        text-align: center;

        @include breakpoint(mobile) {
            max-width: none;
            margin: 0 -6px;
            padding: 20px;
        }

        p,
        label {
            @include rem(14px);
            line-height: calc(19 / 16);
        }

        p.error {
            @include rem(16px);
            text-align: left;
        }
    }

        .sizing-calculator__title {
            @include rem(24px);
            @include lightondark;
            margin: 13px 0 0;
            line-height: calc(29 / 24);
            color: inherit;
        }

        .sizing-calculator__copy {
            margin: 30px 0 22px;
            @include lightondark;

            @include breakpoint(mobile) {
                margin: 20px 0 22px;
            }
        }

        .field .sizing-calculator__label {
            display: block;
            text-align: left;
            margin-bottom: 8px;
            font-weight: 700;
            color: $c-white;
            @include lightondark;

            .field--error & {
                color: $c-error;
            }

            @include breakpoint(mobile) {
                text-align: center;
            }
        }

        input.sizing-calculator__input {

            @include breakpoint(mobile) {
                width: calc(100% - 32px);
                margin: 0 auto;
                display: block;
            }
        }

        .sizing-calculator__content {
            margin: 20px 0 0;

            @include breakpoint(mobile) {
                margin: 20px 0 0;
            }
        }

        .sizing-calculator__content--default,
        .sizing-calculator--has-results .sizing-calculator__content--has-results {
            display: block;
        }

        .sizing-calculator--has-results .sizing-calculator__content--default,
        .sizing-calculator__content--has-results {
            display: none;
        }

            .sizing-calculator__button {
                margin: 20px 0 22px;

                @include breakpoint(mobile) {
                    width: calc(100% - 32px);
                    margin: 20px 16px 30px;
                }
            }

            .sizing-calculator__total {
                display: block;
                @include smallcaps;
                font-weight: 700;
                margin-top: 16px;
                opacity: .3;
            }

            .sizing-calculator__price {
                @include rem(24px);
                @include lightondark;
                display: block;
                line-height: calc(29 / 24);
                color: inherit;
            }

        .sizing-calculator__close {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 20px;
            height: 20px;
            cursor: pointer;

            svg {
                fill: $c-white;
            }

            @include breakpoint(mobile) {
                top: 10px;
                right: 10px;
            }
        }
