@use 'sass:math';

.block--video {
    .block__video {
        @include aspect(math.div(16, 9));

        position: relative;

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &[data-video-status="playing"]:not(:hover) {
            .block__video-controls {
                opacity: 0;
            }
        }

        &:hover,
        &:focus-within {
            .block__video-controls {
                opacity: 1;
            }
        }

        &[data-video-status="playing"] {
            [data-pause] {
                display: grid;
            }
    
            [data-play] {
                display: none;
            }
        }
    }

    .block__video-controls {
        opacity: 0.5;
        position: absolute;
        transition: opacity 150ms ease-in;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;

        button {
            width: 100px;
            height: 100px;
            display: grid;
            place-items: center;
            background-color: $c-dark;
    
            svg {
                fill: $c-white;
            }
        }

        [data-pause] {
            display: none;
        }
    }
}
