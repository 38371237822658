/* ======================= */
/* = Richtext stylesheet = */
/* ======================= */

/* This should only apply to the wysiwyg */
body.richtext {
    font-size: 100%;
    background: #FFF;
}

.richtext {
    &:not(.page-intro-new__intro) {
        @include apply-map($f-pagecopy);
        @include flush-last-child();
        color: $c-richcopy;

        h1 {
            @include large-title;

            .product__group--description & {
                @include tiny-subtitle;
            }
        }

        h2 {
            @include medium-title;

            .product__group--description & {
                @include tiny-subtitle;
            }
        }

        h3 {
            @include small-title;
        }

        h4 {
            @include large-subtitle;
        }

        h5 {
            @include medium-subtitle;
        }

        h6 {
            @include introcopy;
        }

        h1, h2, h3, h4,  h5, h6 {
            margin-bottom: 0.5em;
        }

        p {
            @include rem(16px);
            margin-bottom: 1em;
            line-height: 24px;

            @include breakpoint(mobile) {
                @include rem(14px);
                line-height: 24px;
            }
        }

        strong {
            font-weight: 500;
        }

        blockquote {
            border: 1px solid #BBB;
            background-color: #EEE;
            padding: 1em 2em;
            margin: 0.5em 0 1em 0;
            font-style: italic;
            font-family: Georgia, Times, serif;
            line-height: 1.4;
        }

        ul,
        ol {
            padding: .5em 0;
            margin: 0 0 .5em 0;
        }

        ul ul,
        ol ol {
            margin-top: .5em;
        }

        ul {
            list-style: none;
            padding-left: 20px;
            position: relative;

            li:before {
                content: "\2022";
                position: absolute;
                left: 0;
                color: $c-brand;
            }
        }

        ol {
            list-style: decimal outside;
            padding-left: 20px;
        }

        ol ol {
            list-style-type: lower-alpha;
        }

        ol ol ol {
            list-style-type: lower-roman;
        }

        li {
            margin: 0 0 1em;
            line-height: calc(22 / 16);
        }

        ul li ,
        ol li {
            padding-left: 3px;
        }

        dl {
            margin: 0 2em 1em 2.5em;
        }

        dt {
            font-weight: bold;
            margin: 1em 0 0.4em 0;

        }

        dd {
            line-height: 1.3em;
            margin-bottom: 0.5em;
        }

        a {
            color: $c-brand;
            text-decoration: none;
            line-height: calc(22 / 16);
            font-weight: 500;
            &:hover {
                text-decoration: underline;

            }
        }

        hr {
            height: 1px;
            border-width: 0;
            background-color: $c-border;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .button {
            color: initial;
        }


    }

    &__small-caps {
        @include smallcaps;
        @include apply-map($f-label);
        @include rem(16px);
        display: block;
        color: $c-black;
        margin-bottom: 10px;
        line-height: calc(19 / 16);
    }
}

.richtext--flush {
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.richtext--dark {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $c-white;
    }

    p {
        color: rgba($c-white, .65);
    }
}


.richtext--center {
    text-align: center;
}

.richtext--m-left-align {
    @include breakpoint(tablet) {
        text-align: left;
    }
}

.richtext--fancy-list {

    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            position: relative;
            width: calc(50% - 50px);
            margin-right: 50px;

            @include breakpoint(phablet) {
                width: 100%;
                margin-right: 0;
            }

            &:before {
                content: '';
                top: 10px;
                left: -20px;
                width: 8px;
                height: 8px;
                background: $c-black;
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }
}

.richtext--grey-list {
    @include font(14px, 22px);

    color: $c-block-copy;

    ul {

        li:before {
            color: $c-block-copy;
        }
    }
}
