@use "sass:math";

@each $name in map-keys($sizes) {
    @include breakpoint(map-get($sizes, $name)) {
        .#{$name}-aspect-square {
            @include aspect(1);
            padding-top: 0;
            padding-left: 0;
        }

        .#{$name}-aspect-wide {
            @include aspect(math.div(520, 260));
            padding-left: 10px;
            padding-top: 0;
        }

        .#{$name}-aspect-tall {
            @include aspect(math.div(260, 520));
            padding-top: 10px;
            padding-left: 0;
        }
    }
}
