html {
    font-size: 100%;
    height: 100%;
    height: -webkit-fill-available;
    text-size-adjust: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: $c-white;
    @include rem(15px);
    @include apply-map($f-primary);
    color: $f-primary-color;
    min-width: 320px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    max-height: 100vh;

    &.no-scroll {
        overflow: hidden;
    }
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}  

// Removes unwanted page jumping on flowbox interaction

body.flbx-body-lock {
    min-height: auto;
    max-height: none;
    height: auto;
}

html #debug-bar {
    position: fixed;
    width: 100%;
    z-index: 30;
    height: 20px;
    top: 0 !important;
    @include breakpoint(tablet) {
        display: none !important;
    }
}

html #debug-holder {
    display: none !important;
}

#outer-wrapper {
    // Set relative to allow header to stick and hero to position absolutely behind header
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .card-authentication & {
        padding-top: 0 !important;
    }

    .body--admin & {
        padding-top: $admin-height;
    }

    .body--debug & {
        padding-top: $debug-height;
    }

    .body--debug.body--admin & {
        padding-top: $debug-height + $admin-height;
    }

    .body--enclosed & {
        padding-top: 88px;
        overflow: hidden;
    }

    .body--enclosed.body--admin & {
        padding-top: 88px + $admin-height;
    }

    .body--enclosed.body--debug & {
        padding-top: 88px + $debug-height;
    }

    .body--enclosed.body--debug.body--admin & {
        padding-top: 88px + $debug-height + $admin-height;
    }


    .body--cookie-bar & {
        padding-bottom: 50px;
    }

    @include breakpoint(tablet) {
        padding-top: 0;

        .body--debug & {
            padding-top: 0;
        }

        .body--admin & {
            padding-top: $admin-height;
        }

        .body--debug.body--admin &,
        .body--enclosed.body--debug.body--admin & {
            padding-top: $admin-height;
        }

        .body--enclosed &,
        .body--enclosed.body--debug & {
            padding-top: 0;
        }
    }
}

#inner-wrapper {
    flex: 1 1 auto;
}

html #admin-bar {
    position: fixed;
    top: 0;
    z-index: 999;
}

.body--debug.body--admin {
    #debug-bar {
        top: $admin-height !important;
    }
}

#admin-bar a {
    line-height: 1;
}

strong {
    font-weight: 500;
}

body.has-sticky-bar {
    #chat-button {
        bottom: 80px !important;
    }
}

body.has-sticky-bar-mobile {
    @include breakpoint(tablet) {
        #chat-button {
            bottom: 70px !important;
        }   
    }
}
