.search {
    display: none;
}

.search--activating {
    top: $nav-height;
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    background: rgba($c-black, 0.75);

    @include breakpoint(nav) {
        top: $nav-height-mobile;
    }

    &[search-toggle="1"] {
        top: $nav-height--fixed;

        @include breakpoint(nav) {
            top: $nav-height-mobile;
        }
    }
}

.search--activating {
    opacity: 0;
    transition: opacity .3s;
}

.search--active {
    opacity: 1;
}

    .search__form {
        padding: 40px $site-gutter-new 50px;
        background: $c-white;

        @include breakpoint(tablet) {
            padding: 20px 25px 20px;
        }
    }

    .search__form--flush {
        padding-top: 0;
        padding-bottom: 0;
    }

        .search__fieldset {
            display: flex;
            align-items: center;
            max-width: 725px;
            margin: 0 auto;
            padding-bottom: 20px;
            border-bottom: 1px solid $c-dark;

            @include breakpoint(tablet) {
                padding-bottom: 15px;
            }
        }

            .search__label {
                @include hideSafely;
            }

            input.search__input {
                @include apply-map($f-primary);
                @include rem(36px);
                @include placeholder($c-label);

                min-width: 0;
                line-height: 41px;
                height: 41px;
                margin-right: 20px;
                padding: 0;
                color: $c-nav-grey;
                border: none;

                @include breakpoint(tablet) {
                    @include rem(18px);

                    height: 25px;
                    line-height: 21px;
                }
            }

            .search__submit {
                margin: -15px;
                padding: 15px;

                span {
                    @include hideSafely;
                }

                svg {
                    display: block;
                    transition: fill .3s ease;
                }

                &:hover,
                &:active {

                    svg {
                        fill: $c-brand;
                    }
                }
            }
