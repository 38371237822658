.paint-quantity-calculator {
    @include lightondark;

    position: relative;
    max-width: 894px;
    margin: 20px auto;
    padding: 80px 70px;
    color: $c-white;
    background: $c-black;
    text-align: center;

    @include breakpoint(phablet) {
        min-height: 100%;
        margin: 0;
        padding: 30px 20px;
    }
}

    .paint-quantity-calculator__title {
        @include rem(36px);

        line-height: calc(31 / 36);
        margin-bottom: 25px;

        @include breakpoint(phablet) {
            @include rem(24px);

            line-height: calc(27 / 24);
            margin-bottom: 10px;
        }
    }

    .paint-quantity-calculator__text {
        @include rem(16px);

        line-height: calc(22 / 16);
        max-width: 492px;
        margin: 0 auto 25px;

        @include breakpoint(phablet) {
            @include rem(14px);

            line-height: calc(20 / 14);
            margin-bottom: 10px;
        }
    }

    .paint-quantity-calculator__note {
        @include rem(14px);

        line-height: calc(20 / 14);
        max-width: 492px;
        margin: 0 auto 50px;
        font-style: italic;

        @include breakpoint(phablet) {
            @include rem(12px);

            line-height: calc(15 / 12);
            margin-bottom: 20px;
        }
    }

    .paint-quantity-calculator__note--small {
        margin-bottom: 30px;

        @include breakpoint(phablet) {
            margin-bottom: 20px;
        }
    }

    .paint-quantity-calculator__error {
        display: none;
        color: $c-white;
        background: $c-error;

        .paint-quantity-calculator--has-error & {
            display: block;
        }
    }

    .paint-quantity-calculator__dimensions {
        @include rem(14px);

        line-height: 1;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
            margin-bottom: 10px;
        }
    }

    .paint-quantity-calculator__subtitle {
        @include rem(13px);

        line-height: 1;
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $c-paint-quantity-calculator__subtitle;
        font-weight: 700;

        @include breakpoint(phablet) {
            margin-bottom: 10px;
        }
    }

    .paint-quantity-calculator__quantity {
        @include rem(24px);

        line-height: 1;
        margin-bottom: 30px;

        @include breakpoint(phablet) {
            margin-bottom: 20px;
        }
    }

    .paint-quantity-calculator__link {
        @include rem(14px);

        line-height: calc(11 / 14);
        color: $c-brand;
        text-decoration: underline;
    }

    .paint-quantity-calculator__form {

        label {
            color: inherit;
            text-align: left;

            @include breakpoint(phablet) {
                @include rem(14px);

                line-height: calc(17 / 14);
            }
        }

        input {
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            .paint-quantity-calculator--has-result & {
                pointer-events: none;
            }
        }

        .button {
            margin-bottom: 35px;

            @include breakpoint(phablet) {
                margin-bottom: 20px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

        .paint-quantity-calculator__fieldset {
            margin-bottom: 40px;

            @include breakpoint(phablet) {
                margin-bottom: 20px;
            }

            .paint-quantity-calculator--has-result & {
                margin-bottom: 10px;
            }
        }

        .paint-quantity-calculator__step-1 {

            .paint-quantity-calculator--has-result & {
                display: none;
            }
        }

        .paint-quantity-calculator__step-2 {
            display: none;

            .paint-quantity-calculator--has-result & {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

    button.paint-quantity-calculator__close {
        top: 40px;
        right: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;

        @include breakpoint(phablet) {
            top: 0;
            right: 0;
            width: 30px;
            height: 30px;
        }

        &:active {
            top: 40px;

            @include breakpoint(phablet) {
                top: 0;
            }
        }

        svg {
            display: block;
            fill: $c-white;
            pointer-events: none;
        }
    }
