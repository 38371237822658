.home-intro {
    margin: 20px 0;

    @include breakpoint(tablet) {
        margin: 40px 0 60px 0;
    }
}

.home-intro__content {
    margin-top: 25px;
}

.home-intro__header {}

.home-intro__title {
    @include apply-map($f-title);
    @include rem(44px);

    line-height: 53px;
    color: $c-dark-grey;

    @include breakpoint(phablet) {
        @include rem(32px);

        line-height: 38px;
        letter-spacing: -0.5;
    }
}

.home-intro__body {
    @include rem(16px);
    @include apply-map($f-primary);

    line-height: 24px;
    color: $c-block-copy;

    @include breakpoint(tablet) {
        @include rem(14px);

        line-height: 22px;
    }
}
