.attraction {
    position: relative;
    overflow: hidden;
}

    .attraction__container {
        @include aspect(1);

        position: relative;
        background: $c-border;

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

        .attraction__image {
            position: relative;
            padding-bottom: 100%;
            background: linear-gradient(180deg, rgba($c-black, 0) 0%, rgba($c-black, 0) 50%, $c-black 100%);

            .attraction:hover & {
                background: linear-gradient(180deg, rgba($c-black, 0.5) 0%, $c-black 100%);

                @include breakpoint(phablet) {
                    background: linear-gradient(180deg, rgba($c-black, 0) 0%, rgba($c-black, 0) 50%, $c-black 100%);
                }
            }
        }

        .attraction__title {
            @include lightondark;
            @include medium-title;
            @include rem(30px);

            line-height: calc(33 / 30);
            position: absolute;
            right: 30px;
            bottom: 30px;
            left: 30px;
            color: $c-white;

            .attraction:hover & {
                bottom: 100%;

                @include breakpoint(phablet) {
                    bottom: 30px;
                }
            }
        }

    .attraction__copy {
        @include rem(18px);

        line-height: calc(24 / 18);
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 40px;
        color: $c-white;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(phablet) {
            position: relative;
            top: auto;
            left: auto;
            color: $c-black;
        }

        .attraction:hover & {
            top: 0;

            @include breakpoint(phablet) {
                top: auto;
            }
        }
    }
