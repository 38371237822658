.notice {
    position: fixed;
    min-height: 45px;
    padding: 9px 40px;
    background: $c-brand-black;
    z-index: 501;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;

    @include breakpoint(nav) {
        padding-inline: 15px;
    }

    @include breakpoint(phablet) {
        padding: 9px 15px 4px;
        height: 50px;
    }

    .body--admin & {
        top: $admin-height;
    }

    .body--debug & {
        top: $debug-height;
    }

    .body--debug.body--admin & {
        top: $admin-height + $debug-height;
    }
}

.notice__call,
.notice__find {
    display: none;
}

.notice__call--show {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $c-white;
    @include rem(13px);
    line-height: normal;
    font-weight: 500;

    @include breakpoint(tablet) {
        display: none;
    }

    p {
        border-bottom: none;
        padding: 0;
        padding-top: 5px;
    }
}

.notice__find--show {
    display: block;

    .discover__link svg {
        fill: $c-brand;
    }

    a {
        display: flex;
        align-items: center;

        p {
            color: $c-white;
            @include rem(13px);
            line-height: normal;
            font-weight: 500;
            padding: 0;
            padding-top: 5px;
        }
    }
}

.notice__content {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin: 0 auto;
    transition: all 0.5s ease-in;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    span {
        color: $c-white;
        @include rem(13px);
        font-weight: 500;
        display: flex;
        align-items: center;

        &:hover {
            color: $c-brand;
        }
    }

    .button {
        margin: 2px 0 0 20px;
    }

    .button--has-icon span {
        justify-content: space-evenly;
    }

    .button--noborder.button--has-icon svg {
        fill: $c-white;

        &:hover {
            fill: $c-brand;
        }
    }

    @include breakpoint(phablet) {
        text-align: center;
        width: 100%;
    }
}

.notice__content--hidden {
    visibility: hidden;
    opacity: 0;
}

.notice__icon {
    line-height: 16px;

    @include breakpoint(phablet) {
        line-height: auto;
    }

    svg {
        margin-right: 10px;
        height: 21px;
        fill: $c-white;
        line-height: 18px;

        @include breakpoint(phablet) {
            line-height: auto;
        }

        &.icon-delivery-box {
            fill: $c-white;
        }
    }
}

    .notice__text {
        @include lightondark;

        font-size: 13px;
        color: $c-white;
        line-height: 24px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.25px;
        position: relative;

        @include breakpoint(phablet) {
            font-size: 12px;
            line-height: 16px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background: $c-dark-grey;
            right: -20px;

            @include breakpoint(phablet) {
                display: none;
            }
        }
    }
