.overlay--flyout {
    .overlay__bg {
        background-color: rgba($c-black, .8);
    }
}

.overlay--sidebar.overlay--sofa-fit {
    .overlay__bg {
        background-color: rgba($c-black, 0.8);
    }

    .overlay__container {
        @include breakpoint(phablet) {
            max-width: calc(100% - 40px);
        }
    }
}
