.filterable-container {
    z-index: 5;
}

.filterable-container--overflow {
    overflow: visible;
}

.filterable-container--flush {
    margin-bottom: 0;
}

.filterable--absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

    .has-notice-bar &,
    .has-notice-bar.body--admin & {
        margin-top: 0;
    }
}

    .filterable--inactive {

        .filterable__dimension:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)) {
            display: none;
        }

        @include breakpoint(tablet) {

            .filterable__dimension:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
                display: none;
            }
        }

        @include breakpoint(phablet) {
            .filterable__dimension:not(:nth-child(1)):not(:nth-child(2)) {
                display: none;
            }
        }

        @include breakpoint(phablet) {
            .filterable__dimension,
            .filterable__content {
                display: none;
            }

            .filterable__clear--all,
            .filterable__clear--filter {
                display: none;
            }

            &.filterable--custom.custom-select-replace--filters,
            &.filterable--sticky.custom-select-replace--filters {
                padding-top: 0;
            }
        }
    }

    .filterable--custom,
    .filterable--sticky {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $c-filter-grey;
        padding: 0 0 10px 0;

        @include breakpoint(laptop) {
            flex-wrap: wrap;
        }

        @include breakpoint(phablet) {
            margin: 0;
            padding: 7px 0;

            &.filterable--active {
                padding: 0;
                border: none;
            }
        }
    }

    .filterable--custom {
        padding: 10px 0;
        background-color: $c-background;
        border-bottom: none;
        border-top: 1px solid $c-dark-grey;
    }

    .filterable--fabrics {
        @include lightondark;

        padding: 90px 0;
        color: $c-white;
        background: $c-dark url(/images/fabric.jpg) center no-repeat;
        background-size: cover;
    }

    .filterable--active {

        @include breakpoint(phablet) {
            flex-wrap: nowrap;
            overflow: auto;
            height: calc(100vh - 120px);
            flex-direction: column;
            justify-content: flex-start
        }
    }

    .filterable__wrap {
        @extend .wrap;

        padding: 40px 0;

        @include breakpoint(phablet) {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    .filterable__wrap--narrow {
        @extend .wrap--narrow;
    }

    .filterable__dimension {
        display: flex;
        align-items: center;
        position: relative;

        &:not(:first-child) {
            margin-left: 25px;

            @include breakpoint(tablet) {
                margin-left: 15px;
            }

            @include breakpoint(phablet) {
                margin-left: 0;
            }
        }

        &.filterable__dimension--frame_type {
            margin-left: 0;

            @include breakpoint(tablet) {
                align-items: stretch;
                justify-content: space-between;
            }
        }

        &.filterable__dimension--show {
            margin-left: auto;
            margin-right: 10px;

            @include breakpoint(tablet) {
                margin-left: 0;
            }
        }

        select[multiple] {
            @include hideSafely;
        }
    }

    .filterable__dimension--box {
        background: $c-white;
        border: 1px solid $c-multi-select-border;
        padding: 5px 15px;

        @include breakpoint(phablet) {
            flex-basis: 100%;
            margin: 15px  0;

            > div {
                width: 100%;
            }
        }
    }

        #fabric_type_selectreplace {
            display: none;
        }

    .filterable__dimension--show {
        border-bottom: 1px solid $c-dark-grey;
        height: 45px;

        @include breakpoint(phablet) {
            margin-bottom: 5px;
        }
    }

        .filterable__unslick {

            span {
                @include apply-map($f-primary);
                @include rem(12px);

                line-height: 26px;
                color: $c-dark-grey;
                letter-spacing: 2.5px;
                padding: 10px 0;
                text-transform: uppercase;
                font-weight: 500;
                margin-right: 20px;
            }

            svg {
                fill: $c-brand;
            }

            .icon-minus {
                display: none;
            }
        }

        .filterable__unslick--active {

            .icon-minus {
                display: inline-block;
            }

            .icon-plus {
                display: none;
            }
        }

    .filterable__dimension--active {
        position: relative;

        @include breakpoint(phablet) {
            &::after {
                display: none;
            }
       }

        &::after {
            content: '';
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            right: 0;
            height: 1px;
            background: $c-dark-grey;
        }
    }

    .filterable__content {
        position: relative;
        display: flex;

        .filterable--custom & {
            flex-wrap: wrap;
            padding: 0 40px;
            overflow: hidden;

            @include breakpoint(phablet) {
                padding: 0 30px;
            }
        }

        .filterable--fabrics & {
            display: block;
            width: 100%;
            text-align: center;

            @include breakpoint(phablet) {
                padding: 0 20px;
            }
        }

        @include breakpoint(phablet) {
            display: none;

            .filterable--custom & {
                display: flex;
            }

            .filterable--fabrics & {
                display: block;
            }
        }

        .filterable--active & {
            @include breakpoint(phablet) {
                display: block;
                padding-bottom: 30px;
                overflow-x: hidden;
            }
        }
    }

        .filterable__text {
            @include apply-map($f-title);
            @include font(28px, 34px);

            margin-bottom: 15px;
        }

        .filterable__button-container {
            @include breakpoint(phablet) {
                position: fixed;
                right: $site-gutter-mobile;
                left: $site-gutter-mobile;
                bottom: 0;
                background: $c-white;
                padding-top: 15px;
                border-top: 1px solid $c-filter-grey;
            }
        }

            .filterable__button--clear {
                @include breakpoint(phablet) {
                    margin: 10px 0;

                    a {
                        display: block;
                    }
                }
            }

    .filterable--block .filterable__dimension {
        width: 100%;
        padding: 0 30px;
        flex-wrap: wrap;

        @include breakpoint(phablet) {
            padding: 0 15px;
        }
    }

    .filterable--flush .filterable__dimension {
        margin: 0;
    }

        .filterable__dimension__label {
            cursor: pointer;

            svg {
                margin: 3px 0 0 10px;
                fill: $c-black;
                transform: rotate(90deg);
                transition: transform .1s ease, fill .3s ease;

                .filterable__dimension:not(.filterable__dimension--active):hover & {
                    fill: $c-dark-grey;
                }

                @include breakpoint(phablet) {
                    margin-left: auto;
                }

                .filterable__dimension--active & {
                    transform: rotate(270deg);
                    fill: $c-brand;
                }
            }
        }

            .filterable__dimension__count {
                margin-left: 5px;

                &:empty {
                    display: none;
                }
            }

        .filterable__dimension__label,
        .filterable__option__label,
        .filterable__dimension--checkbox__label {
            @include font(14px, 26px);

            text-align: left;
            font-weight: 500;
            display: flex;
            align-items: center;
            text-transform: initial;
            white-space: nowrap;

            @include breakpoint(phablet) {
                margin-right: 3px;
                width: 100%;

                .filterable__dimension--active & {
                    padding-bottom: 10px;
                    padding-right: 3px;
                    border-bottom: 1px solid $c-dark-grey;
                }
            }
        }

        .filterable--block .filterable__dimension__label {
            display: inline-block;
            margin: 0;
        }

        .filterable__dimension--checkbox__label {
            padding: 8px;
            height: 42px;
            margin: 0;
            line-height: 26px;
            background-color: $c-filter-highlight;
        }


        .check-replace {
            background-color: $c-white;
            border: 1px solid $c-filter-border;

            svg {
                padding: 2px;
                margin-top: 2px;
            }
        }

    .filterable__option {

        .select-replace {
            display: inline-block;
        }

        .input {
            display: inline-block;
            width: 180px;
        }
    }

        .filterable__option__label {
            display: inline-block;
            margin-right: 10px;
            width: min-content;
        }

        .filterable__option--checkbox {
            .filterable__option__label {
                line-height: 26px;
                margin: 0;

                .check-replace.check-replace {
                    @include breakpoint(phablet) {
                        margin-left: 10px;
                    }
                }
            }

            .check-replace {
                margin-left: 10px;
            }
        }

        .filterable__option--bool-checkbox {
            .filterable__option__label {
                line-height: 26px;
                margin: 0;
            }

            .check-replace {
                margin-left: 10px;
            }
        }

        .filterable__option--sort {
            display: flex;
            align-items: center;
        }

        .filterable__option--sort {
            .filterable__option__label {
                font-weight: 400;
                color: $c-block-copy;
            }
        }


.custom-select-replace {
    .select-replace {
        background-color: transparent;
        max-width: 85px;

        svg {
            right: 0;
        }
    }

    .select-replace--focused {
        box-shadow: none;
    }

        select {
            height: 100%;
        }

        .select-replace__value {
            @include apply-map($f-primary);
            @include font(14px, 26px);

            display: inline-block;
            align-items: center;
            font-weight: 500;
            color: $c-dark-grey;
            text-transform: capitalize;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            left: unset;
            right: 0;
            padding: 0;

            .customise__band & {
                justify-content: start;
            }
        }

        .select-replace__value {
            background-color: transparent;
            border: none;
        }
}


.custom-select-replace--sorting {
    @include rem(14px);

    line-height: 26px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.custom-select-replace--selection {
    margin: 0 -5px;
    line-height: calc(18 / 14);
    @include rem(0px);
    text-align: left;

    .selection__dimension {
        @include rem(14px);

        line-height: calc(14 / 14);
        position: relative;
        display: inline-block;
        width: 70%;
        padding: 5px;

        @include breakpoint(phablet) {
            display: block;
            width: auto;
        }

        .select-replace {
            border: 1px solid $c-filter-border;
            height: 44px;

            svg {
                margin-right: 15px;
            }
        }

        .select-replace__value {
            padding-left: 15px;
        }
    }
}

    .filterable__opener--5,
    .filterable__opener--4,
    .filterable__opener--3,
    .filterable__opener--2,
    .filterable__opener--1,
    .filterable__opener--x {
        display: none;
    }

    @include breakpoint(phablet) {
        .filterable__opener {
            display: block;
        }
    }

    @include breakpoint(phablet) {
        .filterable__opener--5,
        .filterable__opener--4,
        .filterable__opener--3 {
            display: block;
        }
    }

    @include breakpoint(tablet) {
        .filterable__opener--5 {
            display: block;
        }
    }

    .filterable__clear-container {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 25px;

        @include breakpoint(laptop) {
            display: none;
            flex-basis: 100%;
            margin: 0;
        }

        .filterable--sticky & {

            &:not(.filterable__clear-container--5) {

                &.filterable__clear-container--4 {

                    @include breakpoint(tablet) {
                        position: relative;
                        right: auto;
                        bottom: auto;
                    }
                }

                @include breakpoint(phablet) {
                    position: relative;
                    right: auto;
                    bottom: auto;
                }
            }
        }
    }

    .filterable--custom .filterable__clear-container {
        top: 0;
        border-top: 1px solid $c-border;
        min-height: 32px;
    }

        .filterable__clear {
            display: inline-block;
            text-decoration: underline;

            span {
                @include rem(12px);

                line-height: 26px;
                color: $c-dark-grey;
            }
        }

        .filterable__clear--single {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        .filterable__clear--all {
            margin: 10px 5px 0 0;
            text-decoration-color: $c-dark-grey;
            transition: text-decoration-color .2s ease;
            position: relative;

            span {
                color: $c-dark-grey;
                transition: color .2s ease;
            }

            &:hover {
                text-decoration-color: $c-block-copy;

                span {
                    color: $c-block-copy;
                }
            }

            &::before {
                content: '';
                position: absolute;
                left: -25px;
                top: 4px;
                bottom: 2px;
                width: 1px;
                background-color: $c-filter-grey;

                @include breakpoint(tablet) {
                    display: none;
                }
            }

            @include breakpoint(phablet) {
                margin-right: 0;
            }

            .filterable--sticky & {
                margin-top: 0;
            }
        }

        .filterable--custom .filterable__clear--all,
        .filterable--sticky .filterable__clear--all {
            @include breakpoint(phablet) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                padding: 10px;
            }
        }

        .filterable__clear--filter {
            margin-right: 30px;
            color: $c-black;

            svg {
                fill: $c-black;
            }
        }

        .filterable--block .filterable__dimension--size {
            margin-bottom: 32px;
        }

        .filterable--flush .filterable__dimension--size {
            margin-bottom: 0;
        }

        .filterable__dimension--customsofadims {

            .filterable--block & {
                margin-top: 0;
                padding: 75px 40px;

                @include breakpoint(laptop) {
                    justify-content: center;
                    padding: 15px;
                }
            }

            .input {
                max-width: 65px;
                margin-left: 5px;
                margin-right: 5px;
            }

            span {
                @include smallcaps;
                @include rem(14px);
            }
        }

        .filterable__dimension__note {
            color: $c-label;
            @include rem(16px);
        }

.hide-from-filters {
    @include breakpoint(phablet) {
        display: none;
    }
}

.filterable--extended {
    .filterable__dimension {
        @include breakpoint(phablet) {
            padding: 10px 0;
            margin: 0;
            border-bottom: 1px solid $c-filter-grey;

            &:first-child {
                border-top: 1px solid $c-filter-grey;
            }

            .multi-select-replace {
                display: none;
            }
        }
    }

    .filterable__dimension--active {
        display: flex;
        position: relative;

        @include breakpoint(phablet) {
            border-bottom: none;
            flex-wrap: wrap;

            .multi-select-replace {
                display: block;
            }
        }
    }

    .filterable__option--checkbox {
        padding-right: 15px;

        @include breakpoint(phablet) {
            padding: 0;
            width: 100%;
        }

        .filterable__option__label {
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin: 0;
            line-height: calc(21 / 14);
            text-transform: none;
        }

        .check-replace {
            width: 21px;
            height: 21px;
            margin: 1px 15px 0 15px;
            cursor: pointer;
            position: relative;

            @include breakpoint(phablet) {
                margin: 0;
            }

            svg {
                margin-top: -1px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                padding: 0;
            }
        }

        .check-replace--active {
            border: 1px solid $c-dark-grey;
        }
    }

    .filterable__option--bool-checkbox {

        @include breakpoint(phablet) {
            padding: 0;
            width: 100%;
        }

        .filterable__option__label {
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin: 0;
            line-height: calc(21 / 14);
            text-transform: none;
        }

        .check-replace {
            width: 21px;
            height: 21px;
            margin: 1px 15px 0 15px;
            cursor: pointer;
            position: relative;

            @include breakpoint(phablet) {
                margin: 0;
            }

            svg {
                margin-top: -1px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                padding: 0;
            }
        }

        .check-replace--active {
            border: 1px solid $c-dark-grey;
        }
    }
}

.filterable__dropdown--mobile {
    margin-left: auto;
}

    .filterable__extra-content {
        display: flex;

        @include breakpoint(laptop) {
            flex-direction: column;
        }
    }

    .filterable__extra-content--padded {
        padding: 15px;
    }

    .filterable__title {
        @include small-title;
        @include rem(18px);

        line-height: calc(22 / 18);
        margin: 0 0 25px;
    }

    .filterable__count {
        @include font(12px, 26px);

        margin: 10px 0 11px;
        color: $c-block-copy;
        text-align: left;
    }

    .filterable__results {
        display: flex;
        justify-content: space-between;
        margin: 0;

        @include breakpoint(tablet) {
            flex-wrap: wrap;
        }
    }

    .filterable__product-count {
        display: flex;
        align-items: center;
    }

        .filterable__product-count--number {
            margin-right: 5px;
        }
