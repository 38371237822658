.band {
    margin: 50px 0;
    overflow: hidden;

    @include breakpoint(phablet) {
        margin: 30px 0;
    }
}
    .band--with-padding {
        padding: 50px 0;

        @include breakpoint(phablet) {
            padding: 30px 0;
        }
    }

    .band--with-tall-padding {
        padding: 70px 0;

        @include breakpoint(phablet) {
            padding: 30px 0;
        }
    }

    .band--with-padding-top {
        padding-top: 50px;

        @include breakpoint(phablet) {
            padding-top: 30px;
        }
    }

    .band--with-tall-padding-top {
        padding-top: 70px;

        @include breakpoint(phablet) {
            padding-top: 30px;
        }
    }

    .band--with-super-tall-padding-top {
        padding-top: 100px;

        @include breakpoint(phablet) {
            padding-top: 30px;
        }
    }

    .band--with-padding-bottom {
        padding-bottom: 50px;

        @include breakpoint(phablet) {
            padding-bottom: 30px;
        }
    }

    .band--with-tall-padding-bottom {
        padding-bottom: 70px;

        @include breakpoint(phablet) {
            padding-bottom: 30px;
        }
    }

    .band--tall {
        margin: 100px 0;

        @include breakpoint(phablet) {
            margin: 30px 0;
        }
    }

    .band--tall-mobile {
        @include breakpoint(phablet) {
            margin: 60px 0;
        }
    }

    .band--flush {
        margin: 0;
    }

    .band--flush-top {
        margin-top: 0;
    }

    .band--short-top {
        margin-top: 15px;
    }

    .band--med-top {
        margin-top: 30px;
    }

    .band--short-bottom {
        margin-top: 15px;
    }

    .band--short {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .band--medium {
        margin-top: 40px;
        margin-bottom: 40px;

        @include breakpoint(phablet) {
            margin: 30px 0;
        }
    }

    .band--flush-bottom {
        margin-bottom: 0;
    }

    .band--flush-bottom-mobile {

        @include breakpoint(phablet) {
            margin-bottom: 0;
        }
    }

    .band--flush-top-mobile {

        @include breakpoint(phablet) {
            margin-top: 0;
        }
    }

    .band--border-top {
        border-top: 1px solid $c-background;
    }

    .band--border-bottom {
        border-bottom: 1px solid $c-background;
    }

    .band--nav {
        box-shadow: inset 0 -1px 0 0 $c-border;

        @include breakpoint(tablet) {
            display: none;
        }

        .viewing-nav & {
            display: block;
        }

        .header---enclosed & {
            display: block;
        }
    }

.band--alt {
    background: $c-background;
}

.band--blocks {
    background: $c-background;

    @include breakpoint(phablet) {
        background-color: $c-white;
    }
}

    .header--enclosed + .band {

        @include breakpoint(phablet) {
            margin-top: 60px;
        }
    }

.band--white {
    background: $c-white;
}

.band--opaque {
    background: $c-white;
}

.band--grey-white {
    background: linear-gradient(to bottom, $c-background 75%, $c-white 25%);
}

.band--white-grey {
    background: linear-gradient(to bottom, $c-white 75%, $c-background 25%);
}

.band--equal-grey-white {
    background: linear-gradient(to bottom, $c-background 50%, $c-white 50%);
}

.band--equal-white-grey {
    background: linear-gradient(to bottom, $c-white 50%, $c-background 50%);
}

.band--black {
    @include lightondark();

    color: $c-white;
    background: $c-black;
}

.band--dark {
    @include lightondark();

    color: $c-white;
    background: $c-dark;
}

.band--overflow {
    overflow: visible;
}

.band--overflow-desktop {
    overflow: visible;

    @include breakpoint(tablet) {
        overflow: hidden;
    }
}

.band--after-sticky {
    z-index: 0;
    position: relative;
}

.band--negative {
    margin-top: -80px;
    margin-bottom: -110px;

    @include breakpoint(tablet) {
        margin: 0 0 50px 0;
    }
}

.band--scroll-margin-top {
    scroll-margin-top: $nav-height--fixed;
}
