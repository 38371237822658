    .js .d3r-show,
    .js .d3r-hide {
        cursor: pointer;
    }
    .js .d3r-hidden {
        display: none;
    }
    .js .d3r-shown {
        display: block;
    }
    .d3r-chooser {

    }
        .d3r-chooser-option,
        .d3r-multichooser-option {
            display: none;
        }
