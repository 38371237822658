.image-overlay-wrapper {
    position: relative;
}
.image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
    .image-overlay--dark-bottom {
        background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5));
    }
