.frame-type {}

    .frame-type__option {
        position: relative;
        flex-basis: calc(25% - 20px);
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 10px;
        background: $c-white;
        border: 1px solid $c-white;
        transition: border .2s ease;

        input {
            display: none;
        }

        @include breakpoint(tablet) {
            flex-basis: calc(50% - 10px);

            &:nth-child(odd) {
                margin: 0 5px 10px 0;
            }

            &:nth-child(even) {
                margin: 0 0 10px 5px;
            }
        }
    }

    .frame-type__option--active {
        border: 1px solid $c-dark-grey;
    }

        .frame-type__tick {
            display: none;

            .frame-type__option--active & {
                display: block;
                position: absolute;
                top: 15px;
                right: 15px;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background: $c-white;
                border: 1px solid $c-dark-grey;

                @include breakpoint(phablet) {
                    top: 5px;
                    right: 5px;
                }

                svg {
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    fill: $c-dark-grey;
                    width: 7px;
                    height: 7px;
                }
            }
        }

        .frame-type__image {
            @include aspect(1);
        }

        .frame-type__label {
            @include rem(18px);
            @include apply-map($f-title);

            margin: 0 5px 0 20px;
            line-height: 22px;
            text-transform: capitalize;
            color: $c-block-copy;
            transition: color .2s ease;

            @include breakpoint(phablet) {
                @include rem(14px);

                line-height: 18px;
            }

            .frame-type__option:hover & {
                color: $c-dark-grey;
            }

            .frame-type__option--active & {
                color: $c-dark-grey;
            }
        }