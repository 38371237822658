// Better border boxing
// http://www.paulirish.com/2012/box-sizing-border-box-ftw/

html {
	box-sizing: border-box;
}

* {
    &,
    &:before,
    &:after {
        box-sizing: inherit;
    }
}

html #debug-bar {
    width: 100%;
}
