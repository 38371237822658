.block--categories {
    overflow: hidden;

    .slick-list {
        overflow: visible;
    }

        .slick-track {
            align-items: flex-start;
        }

            .slick-slide {
                margin-right: 15px;

                @include breakpoint(tablet) {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .slick-arrow {
                position: static;
            }
}

    .categories__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px;
        margin-bottom: 30px;

        @include breakpoint(tablet) {
            margin-bottom: 15px;
        }
    }

        .categories__title {
            @include font(50px, 55px);
            color: $f-subtitle-color;
            @include apply-map($f-title);

            @include breakpoint(mobile) {
                @include font(25px, 30px);
            }
        }

        .categories-carousel {
            @include breakpoint(phablet) {
                display: flex;
                gap: 20px;
                overflow-x: auto;
                scroll-snap-type: x proximity;
                margin: 0 calc($site-gutter-mobile * -1);
                padding: 0 $site-gutter-mobile;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */

                &::-webkit-scrollbar {
                    display: none;
                }

                & > * {
                    flex-basis: 240px;
                    flex-shrink: 0;
                }
            }
        }

        .categories-carousel__controls {
            display: flex;
            justify-items: center;
            align-items: center;
            gap: 25px;

            @include breakpoint(mobile) {
                display: none;
            }
        }

        .categories-carousel__image {
            aspect-ratio: 3.5 / 4;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .categories-carousel__information {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;

            @include breakpoint(tablet) {
                margin-top: 20px;
                gap: 15px;
            }
        }

            .categories-carousel__title {
                @include font(30px, 36px);
                @include apply-map($f-title);

                @include breakpoint(tablet) {
                    @include font(20px, 24px);
                }
            }

            .categories-carousel__body,
            .categories-carousel__body p {
                @include font(14px, 20px);

                @include breakpoint(tablet) {
                    display: none;
                }
            }
