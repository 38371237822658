.custom-radio {
    @include rem(14px);

    display: flex;
    align-items: center;
    color: $c-dark-grey;
    line-height: 16px;
    margin-right: 30px;

    &:hover {
        cursor: pointer;
    }
}

    .custom-radio__input {
        display: flex;
        align-self: flex-start;

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .custom-radio__control {
                background: radial-gradient($c-dark-grey 50%, rgba(255, 0, 0, 0) 51%);
            }
        }
    }

    .custom-radio__control {
        background: $c-white;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $c-dark-grey
    }

    .custom-radio__label {
        @include font(14px, 20px);
        
        margin-left: 20px;
    }

.custom-radio__icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: relative;
    flex-shrink: 0;

    svg {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 20px;
    }
}

.custom-radio__icon--tick {
    border: 1px solid $c-tick-green;

    .icon-tick {
        display: block;
        fill: $c-tick-green;
    }
}

.custom-radio__icon--cross {
    border: 1px solid $c-cross-red;

    .icon-close {
        display: block;
        fill: $c-cross-red;
    }
}
