.video-preview {
    position: relative;
    overflow: hidden;
}

    .video-preview__link {
        @include smallcaps;
        @include rem(14px);

        line-height: calc(17 / 14);
        position: relative;
        padding: 20px 0;
        color: $c-label;
        background: $c-background;

        span {
            cursor: pointer;
        }

        svg {
            margin-left: 10px;
            stroke: $c-label;
        }
    }

.video-preview__link__white-bg {
    background: $c-white;
}
