////
/// Simple tools for hiding and showing elements
///
/// @author Dom Morgan <dom@d3r.com>

.d3r-show,
.d3r-hide {
    cursor: pointer;
}
.d3r-hidden {
    display: none;

    // .no-js & {
    //     display: block;
    // }
}
.d3r-chooser {

}
    .d3r-chooser-option,
    .d3r-multichooser-option {
        display: none;
    }

.d3r-shown {
    display: block;
}

.vh,
.screen-reader-text {
    @include visually-hidden();
}
